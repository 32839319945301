import React, { useState } from "react"
import { useAuth } from "../../provider/AuthProvider"
import { Navigate, useLocation } from "react-router-dom"
import { Button } from "antd"

import '../../styles/loginForm.scss'

const Login = () => {
	const location = useLocation()
	const { user, tryLogin } = useAuth()

	const [ authData, setAuthData ] = useState({
		login: '',
		password: ''
	})

	if (user.token) {
		return <Navigate to="/" state={{ path: location.pathname }} />
	}

	const onLoginPassChange = (event, fieldType) => {
		authData[fieldType] = event.target.value.trim()

		setAuthData(authData)
	}

	const login = async () => {
		const { login, password } = authData

		if (password && login) {
			tryLogin(authData)
		}
	}

	return (
		<div className="loginPage">
			<div id="loginForm">
				<label htmlFor="loginFormLogin">Логин</label>
				<input id="loginFormLogin" type="text" className="login" autoComplete="on" onChange={ e => onLoginPassChange(e, 'login') } />
				<label htmlFor="loginFormPassword">Пароль</label>
				<input id="loginFormPassword" type="password" className="password" onKeyUp={ e => e.code === 'Enter' && login() } onChange={ e => onLoginPassChange(e, 'password') } />

				<Button type="primary" className="loginBtn" onClick={login}>Войти</Button>

				<div className="sherlock"></div>
			</div>
		</div>
	)
}

export default Login
