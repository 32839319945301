import { Navbar } from "../navbar/Navbar"
import { Fragment, createElement } from "react"
import { useParams, useLocation } from "react-router-dom"
import pagesConfig from "../config/pagesConfig"
import { useAuth } from "../../provider/AuthProvider"
import * as _ from 'lodash-es'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/locale/ru_RU'

import ReviewsPage from "./Reviews"
// import NotFoundPage from "./NotFoundPage"
import CopyPage from "./Copy"
// import RefundPage from "./Refund"
import FeaturesPoints from "./FeaturesPoints"
import CoinsStats from "./CoinsStats"
import Payments from "./Payments"
import Home from "./Home"
import LinksGenerator from "./linksGenerator/LinksGenerator"
import SetGift from "./setGift/SetGift"
import ReviewsErrors from "./ReviewsErrors"
import SetCrystals from "./SetCrystals"
import InfoPopups from "./InfoPopups"
import Dictionary from "./dictionary/Dictionary"
import DevTools from "./DevTools"
import AdditionPage from "./Addition"

export default function Pages() {
	const { user } = useAuth()
	let { pageID } = useParams()
	const { pathname } = useLocation()

	const pageData = pagesConfig[pageID]

	if (!pageID && pathname === '/') {
		pageID = 'home'
	}

	const pagesClasses = {
		home: Home,
		reviews: ReviewsPage,
		copy: CopyPage,
		// refund: RefundPage,
		featuresPoints: FeaturesPoints,
		coinsStats: CoinsStats,
		payments: Payments,
		linksGenerator: LinksGenerator,
		setGift: SetGift,
		reviewsErrors: ReviewsErrors,
		setCrystals: SetCrystals,
		infoPopups: InfoPopups,
		dictionary: Dictionary,
		addition: AdditionPage,
		devtools: DevTools
	}

	// Есть разрешение
	const hasPagePermission = !_.has(pageData, 'permission') || user.hasPermission(pageData.permission)
	const pageClass = hasPagePermission && pagesClasses[pageID] || Home
	const page = createElement(pageClass, { data: pageData })

	return (
		<ConfigProvider locale={ruRU}>
			<Fragment>
				<Navbar currentPage={pageID}/>

				<div className={ `pageContainer page-${ pageID }` }>
					<div className="top">
						<div className="container-fluid">
							<div className="row">
								<div className="col-12">

									{ page }

								</div>
							</div>
						</div>
					</div>

					<div className="bottom">
						<footer>Zebomba Games 2023-{ new Date().getFullYear() }</footer>
					</div>
				</div>

			</Fragment>
		</ConfigProvider>
	)
}