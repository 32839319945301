export default {
	1: {
		'name': 'angry',
		'rarity': 'common',
		'default': 1,
		'type': 'angry'
	},
	2: {
		'name': 'sad',
		'rarity': 'common',
		'default': 1,
		'type': 'sad'
	},
	3: {
		'name': 'boring',
		'rarity': 'common',
		'default': 1,
		'type': 'yawn'
	},
	4: {
		'name': 'laugh',
		'rarity': 'common',
		'default': 1,
		'type': 'joy'
	},
	5: {
		'name': 'bpass_question',
		'rarity': 'rare',
		'type': 'surprise'
	},
	6: {
		'name': 'bpass_sorrow',
		'rarity': 'common',
		'type': 'sad'
	},
	7: {
		'name': 'bpass_cookie',
		'rarity': 'epic',
		'type': 'smile-m'
	},
	8: {
		'name': 'bpass_waiting',
		'rarity': 'rare',
		'type': 'surprise'
	},
	9: {
		'name': 'bpass_scared',
		'rarity': 'epic',
		'type': 'surprise'
	},
	10: {
		'name': 'bpass_love',
		'rarity': 'common',
		'type': 'angry'
	},
	11: {
		'name': 'bpass_wink',
		'rarity': 'legendary',
		'type': 'smile-f'
	},
	12: {
		'name': 'bpass_cool',
		'rarity': 'rare',
		'type': 'smile-m'
	},
	13: {
		'name': 'bpass_cute',
		'rarity': 'common',
		'type': 'joy'
	},
	14: {
		'name': 'bpass_amazing',
		'rarity': 'epic',
		'type': 'joy'
	}
}