import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import { Menu } from 'antd'
import * as _ from 'lodash-es'
import { useAppData } from '../pages/Project'

export default function AppLinks({ data }) {
	const app = useAppData()

	const { versionName, serverData } = data
	const appHref = `${ data.linkHref }_${ versionName }`

	const menuItems = []

	let ids = data.socials.ids

	// костыль
	if (data.linkHref.includes('ios')) {
		ids = serverData.ids
	}

	// mobile || ios
	if (ids) {
		const idsLits = ids.split(',')

		return _.map(idsLits, id => (
			<div key={ id } className="link">
				<Link to={ `/apps/${ appHref }/${ id }` }>
					<div>{ id }</div>
				</Link>
			</div>
		))
	}
	// web
	else {
		_.map(data.socials, (link, socialName) => {
			const isDisabled = !_.has(serverData, socialName)
			let ids = serverData && serverData[socialName] && serverData[socialName].ids.split(',')

			const getLinkItem = () => {
				let linkItem = <div className="titleName">
					<div className="socialTitle">{ socialName.toUpperCase() }</div>
					<div className="socialName">{ socialName.toUpperCase() }</div>
				</div>

				if (!isDisabled) {
					let toPath = `/apps/${ appHref }_${ socialName }`

					if (ids) {
						if (ids.length === 1)  {
							const socialNameLabel = <div className='appLink'>
								<Link to={`${ toPath }/${ ids[0] }`}>{ socialName.toUpperCase() }</Link>

								<a href={ link } target="_blank" rel="noreferrer" className="socialLink">
									<div className="label">Открыть</div>
									<FeatherIcon size="20" icon="link"/>
								</a>
							</div>

							menuItems.push({
								key: socialName,
								label: socialNameLabel
							})
						}
						else if (ids.length > 1) {
							const socialNameLabel = <div className='appLink'>
								{ linkItem }

								<a href={ link } target="_blank" rel="noreferrer" className="socialLink">
									<div className="label">Открыть</div>
									<FeatherIcon size="20" icon="link"/>
								</a>
							</div>

							const socialIDs = _.map(ids, id => ({
								key: `${ id }${ socialName }`,
								label: <Link to={`${ toPath }/${ id }`}>{ id }</Link>
							}))

							menuItems.push({
								key: socialName,
								label: socialNameLabel,
								children: socialIDs
							})
						}
					}

					linkItem = <Link className="appLink" to={ toPath }>{ linkItem }</Link>
				}

				return linkItem
			}

			getLinkItem()

			// return <div key={ socialName } className={ `link ${ isDisabled ? 'disabled' : '' }` }>
			// 	{ getLinkItem() }

			// 	<a href={ link } target="_blank" className="socialLink">
			// 		<div className="label">Открыть</div>
			// 		<FeatherIcon size="20" icon="link"/>
			// 	</a>
			// </div>
		})
	}

	return <Menu
			key={app?.config.appID}
			className="appsLinksMenu"
			mode="inline"
			style={{
				border: 'none',
			}}
			items={menuItems}
		/>
}