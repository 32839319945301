import { useState } from "react"
import { useAuth } from "../../provider/AuthProvider"
import AppsMenu from "./AppsMenu"
import PagesLinks from "./PagesLinks"
import NavbarPopup from "./navbarPopup/NavbarPopup"
import SocialIDs from "./SocialIDs"
import FeatherIcon from "feather-icons-react/build/FeatherIcon"
import { Link } from "react-router-dom"

export const Navbar = ({ currentPage }) => {
  	const { user, logout } = useAuth()
	const [ popupData, setPopupData ] = useState()
	const [ isAppsMenuShowed, setIsAppsMenuShowed ] = useState(false)

	const userName = user.name
		.split(' ')
		.map((item, index) => !index ? item : `${ item[0] }.`)
		.join(' ')

	const appsLink = (
		<div className={`link apps ${ isAppsMenuShowed ? 'active' : '' }`} onClick={() => setIsAppsMenuShowed(!isAppsMenuShowed)}>
			Проекты
			<FeatherIcon size="19" icon="chevron-down"/>
		</div>
	)

	return (
		<header id="header">
			<div className="topSection">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 section">
							<PagesLinks currentPage={currentPage} appsLink={appsLink}/>

							<div className="mobileLinks">
								<div className="link" onClick={() => setPopupData({ type: 'apps' })}>Проекты</div>
								<div className="link" onClick={() => setPopupData({ type: 'pages' })}>Страницы</div>
							</div>

							<div className="profilePanel">
								<div className="nameID">
									<div className="name">{ userName }</div>
									<div className="idInfo">ID: <span className="userID">{ user.id }</span></div>
									<SocialIDs/>
								</div>

								<div className="exitBtn" onClick={logout}>Выход</div>
							</div>
						</div>
					</div>
				</div>

				<div className={`appsSection ${ isAppsMenuShowed ? 'show' : '' }`}>
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="appsMenu">
									<Link className="item all" to="/">Все</Link>

									<AppsMenu/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{ popupData && <NavbarPopup type={popupData.type} setPopupData={setPopupData}/>}
		</header>
  )
}