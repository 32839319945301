import { Form, Input, InputNumber, Select } from 'antd'
import * as _ from 'lodash-es'
import PodarokRewards from './PodarokRewards'
import { useRef } from 'react'
import podarokConfigs from './config/config'
import { useAppData } from '../../pages/Project'

export default function PodarokForm({ selectedApp, initialData, onChange }) {
	const app = useAppData()

	const fields = useRef({
		popupID: initialData[0] || 0,
		popupImage: initialData[1] || '',
		popupTitle: initialData[2] || '',
		popupText: initialData[3] || '',
		refPopup: initialData[5] || 0,
		popupDecor: initialData[6] || 0,
		buttonText: initialData[7] || 'OK',
		rewards: {}
	})

	initialData = initialData || []

	const onFormChange = changed => {
		const changedField = _.keys(changed)[0]

		fields.current[changedField] = changed[changedField]
		
		apply()
	}

	const apply = () => {
		const data = fields.current

		const podarokFieldValue = [
			data.popupID,
			data.popupImage,
			data.popupTitle,
			data.popupText,
			data.rewards,
			data.refPopup,
			data.popupDecor,
			data.buttonText 
		]

		onChange(podarokFieldValue)
	}

	const podarokConfig = app && podarokConfigs[app.config.name] || podarokConfigs[selectedApp]

	const popupsOptions = _.map(podarokConfig.popups, (popup, index) => <Select.Option key={index} value={Number(index)}>{ popup.label } <span style={{ opacity: .6 }}>{ popup.name }</span></Select.Option>)

	return (
		<div className="content">
			<Form
				onValuesChange={onFormChange}
				className='mainForm'
				layout='vertical'
				initialValues={{
					popupID: fields.current.popupID,
					popupTitle: fields.current.popupTitle,
					popupText: fields.current.popupText,
					refPopup: fields.current.refPopup,
					popupDecor: fields.current.popupDecor,
					buttonText: fields.current.buttonText,
					popupImage: fields.current.popupImage
				}}
				>

				<Form.Item
					name="popupID"
					label="ID окна"
					rules={[
						{
						required: true,
						message: 'Обязательное поле',
						},
					]}
					>
					<InputNumber controls={false} value={fields.current.popupID}/>
				</Form.Item>

				<Form.Item
					name="popupTitle"
					label="Заголовок"
					rules={[
						{
						required: true,
						message: 'Обязательное поле',
						},
					]}
					>
					<Input/>
				</Form.Item>

				<Form.Item
					name="popupText"
					label="Основной текст"
					rules={[
						{
						required: true,
						message: 'Обязательное поле',
						},
					]}
					>
					<Input.TextArea autoSize/>
				</Form.Item>

				<Form.Item
					name="refPopup"
					label="Окно перехода"
					rules={[
						{
						required: true,
						message: 'Обязательное поле',
						},
					]}
					>
					<Select>{popupsOptions}</Select>
				</Form.Item>

				<Form.Item
					name="popupDecor"
					label="Тип оформления"
					rules={[
						{
						required: true,
						message: 'Обязательное поле',
						},
					]}>
					<Select>
						<Select.Option key={0} value={0}>Обычное</Select.Option>
						<Select.Option key={1} value={1}>Праздничное</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item
					name="buttonText"
					label="Текст кнопки"
					rules={[
						{
						required: true,
						message: 'Обязательное поле',
						},
					]}>
					<Select>
						<Select.Option key={0} value="Забрать">Забрать</Select.Option>
						<Select.Option key={1} value="Перейти">Перейти</Select.Option>
						<Select.Option key={2} value="OK">OK</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item
					name="popupImage"
					label="Картинка окна"
					rules={[
						{
						required: true,
						message: 'Обязательное поле',
						},
					]}>

					<Select>
						{
							_.map(podarokConfig.form.popupImage.options, (option, index) => <Select.Option key={index} value={option}>{ option }</Select.Option>)
						}
					</Select>
				</Form.Item>
			</Form>

			<PodarokRewards selectedApp={selectedApp} rewards={initialData[4]} onChange={value => { fields.current.rewards = value; apply() }}/>
		</div>
	)
}