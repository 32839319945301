import { List, Button } from "antd"
import * as _ from 'lodash-es'
import salesConfig from "./salesConfig"
import dayjs from "dayjs"
import gameFridays from "./gameFridays"

export default function ActiveSales({ promotions, disablePromotion }) {
	const data = _.filter(_.map(promotions, promotion => {
		const { end_date } = promotion
		const type = Number(promotion.type)

		let promoName = salesConfig[type]

		if (type === 0) {
			const gameFridayName = gameFridays[promotion.promo_id]

			if (gameFridayName) {
				promoName += ` (${ gameFridayName })`
			}
		}

		if (new Date(end_date) > new Date()) {
			return <>
				{ `${ promoName } до ${ dayjs(end_date).format('DD.MM.YYYY HH:mm') }` }
				
				<Button type="default" danger onClick={() => disablePromotion(type)} style={{ marginLeft: 20 }}>Отключить</Button>
			</>
		}
	}))

	if (data.length) {
		return (
			<List
				style={{
					flexGrow: 1,
					marginLeft: 20
				}}
				size="small"
				dataSource={data}
				header={<div>Активные акции</div>}
				bordered
				renderItem={(item) => (
				<List.Item>
					{item}
				</List.Item>
			)}
		/>
		)
	}
}