import { createElement } from 'react'

import EditListPopup from './editList/EditListPopup'
import EditRawFieldPopup from './editList/EditRawFieldPopup'
import CinemaTasksPopup from '../appForm/cinema/CinemaTasksPopup'
import FeaturesPointsPopup from './FeaturesPointsPopup'
import DailyBonusRewardsPopup from '../appForm/dailyBonus/DailyBonusRewardsPopup'

import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

export default function PopupFactory({ data, setPopupData, setFormField }) {
	const closePopup = () => {
		enableBodyScroll(document)

		setPopupData(null)
	}

	disableBodyScroll(document)

	const popupsClasses = {
		editList: EditListPopup,
		rawField: EditRawFieldPopup,
		cinemaTasks: CinemaTasksPopup,
		featuresPoints: FeaturesPointsPopup,
		dailyBonusRewards: DailyBonusRewardsPopup
	}

	const popup = createElement(popupsClasses[data.type], {
		data,
		setFormField,
		closePopup
	})

	return (
		<div className={`popup popup_${ data.type }`}>
			<div className="overlay" onClick={closePopup}></div>

			<div className="content">
				{ popup }

				<div className="closeBtn" onClick={closePopup}>+</div>
			</div>
		</div>
	)
}