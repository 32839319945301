import { Select, Form } from "antd"
import emojisConfig from "./configs/config"
import * as _ from 'lodash-es'
import zebomba from "../../../tools/zebomba"
import { useAppData } from "../../pages/Project"

export default function EmojiSelect({ setSmiles, smiles }) {
	const app = useAppData()
	const emojiConfig = emojisConfig[app.config.name]

	const smilesOptions = _.sortBy(_.filter(_.map(smiles, smileID => {
		const smile = emojiConfig[smileID]

		if (!smile) {
			zebomba.message.info(`Нет конфига для смайла: ${ smileID }`)
		}

		return smileID
	})))

	return (
		<Form.Item
			label="Смайлы игрока (можно вводить вручную)"
		>
			<Select
				onChange={values => {
					const processedValues = _.filter(_.map(values, value => +value))

					setSmiles(processedValues)
				}}
				value={smilesOptions}
				popupMatchSelectWidth={false}
				tokenSeparators={[',']}
				mode="tags"
				notFoundContent={false}
				showSearch={false}
				dropdownStyle={{
					display: 'none'
				}}
			>{ smilesOptions }</Select>
		</Form.Item>
	)
}