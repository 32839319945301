import * as _ from 'lodash-es'
import { Table, Button, Popover, Flex, Spin } from 'antd'
import { useImmer } from 'use-immer'
import EditWordModal from './EditWordModal'
import AppConfig from '../../config/AppConfig'
import { useAuth } from '../../../provider/AuthProvider'
import zebomba from '../../../tools/zebomba'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import TableTop from './TableTop'
import { useState } from 'react'

const alphabet = 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ'.split('')

export default function Dictionary({ data }) {
	const { user } = useAuth()
	const [ tableData, setTableData ] = useImmer(null)
	const [ showEmptyDefs, setShowEmptyDefs ] = useImmer(false)
	const [ showNoStatus, setShowNoStatus ] = useImmer(false)
	const [ searchInput, setSearchInput ] = useImmer('')
	const [ editWordData, setEditWordData ] = useImmer(null)
	const [ selectedLetters, setSelectedLetters ] = useImmer(alphabet)

	document.title = data.title

	if (!tableData) {
		getAllWords(user, setTableData)

		return (
			<Spin size="large" tip="get_game_dictionary">
				<div className="content"/>
			</Spin>
		)
	}

	const deleteWord = word => {
		setTableData(data => _.filter(data, item => item.word !== word))
	}

	const updateWord = word => {
		setTableData(tableData => {
			const wordData = _.find(tableData, item => item.id === word.id)
			
			wordData.word = word.word
			wordData.description = word.description

			return tableData
		})
	}

	const onExportBtnClick = async () => {

		const requestData = _.assign({
			id: user.id,
			access_token: user.token,
		})

		try {
			const response = await fetch(`${ AppConfig.api.dictionaryCsv }?${ new URLSearchParams(requestData) }`)

			const data = await response.text()

			const file = new Blob(["\uFEFF" + data], {type: 'text/csv; charset=utf-8'});

			const anchorElem = document.createElement('a')
			anchorElem.setAttribute("href", URL.createObjectURL(file))
			anchorElem.setAttribute("download", "dictionary.csv")
			anchorElem.click()
		}
		catch(e) {
			zebomba.message.error(e)
		}
	}

	const onFilesChange = async (files) => {
			const file = files && files[0]

			var data = new FormData()
			data.append('file', file)
			data.append('id', user.id)
			data.append('access_token', user.token)

			console.log(data)
			
			try {
				const response = await fetch(AppConfig.api.dictionaryCsv, {
					method: 'POST',
					body: data
				})

				window.location.reload()
			}
			catch(e) {
				zebomba.message.error(e)
			}
		}

	const switchWordStatus = updateWordStatus.bind(null, user, setTableData)

	const tableColumns = getColumns(deleteWord, setEditWordData, switchWordStatus)
	const dataSource = getTableDataSource(tableData, searchInput, showEmptyDefs, showNoStatus, selectedLetters)

	return <>
		<div style={{ width: '75%', margin: '0 auto' }} className="dictionaryTable">
			<TableTop
				tableData={tableData}
				dataSource={dataSource}
				setSearchInput={setSearchInput}
				showEmptyDefs={showEmptyDefs}
				setShowEmptyDefs={setShowEmptyDefs}
				showNoStatus={showNoStatus}
				setShowNoStatus={setShowNoStatus}
				selectedLetters={selectedLetters}
				setSelectedLetters={setSelectedLetters}
				onExportBtnClick={onExportBtnClick}
				onFilesChange={onFilesChange}
			/>

			<Table
				size="large"
				bordered
				columns={tableColumns}
				dataSource={dataSource}
			/>
		</div>

		{ editWordData && (
			<EditWordModal
				wordData={editWordData}
				onSave={updateWord}
				onCancel={() => setEditWordData(null)}
			/>
		)}
	</>
}

async function updateWordStatus(user, setTableData, word) {
	const newWordStatus = Number(!Number(word.status))

	try {
		const requestData = _.assign({
			id: user.id,
			access_token: user.token,
			word_id: Number(word.id),
			status: newWordStatus
		})

		const response = await fetch(AppConfig.api.updateWordStatus, {
			method: 'POST',
			body: new URLSearchParams(requestData)
		})

		const responseData = await response.json()
		
		if (responseData.success) {
			setTableData(tableData => {
				const wordData = _.find(tableData, item => item.id === word.id)
		
				wordData.status = newWordStatus
		
				return tableData
			})

			zebomba.message.success(`Статус слова "${ word.word }" успешно изменён`)
		}
		else {
			zebomba.message.error(responseData)
		}
	}
	catch(e) {
		zebomba.message.error(e)
	}
}

async function getAllWords(user, setTableData) {
	const requestData = _.assign({
		id: user.id,
		access_token: user.token,
	})

	try {
		const response = await fetch(`${ AppConfig.api.getGameDictionary }?${ new URLSearchParams(requestData) }`)
		const allWords = await response.json()
		
		_.forEach(allWords.words, word => word.key = word.id)

		setTableData(allWords.words)
	}
	catch(e) {
		zebomba.message.error(e)
	}
}

function getTableDataSource(tableData, searchInput, showEmptyDefs, showNoStatus, selectedLetters) {
	let dataSource = tableData

	if (searchInput) {
		dataSource = _.filter(dataSource, item => _.startsWith(item.word.toLowerCase(), searchInput))
	}

	if (showEmptyDefs) {
		dataSource = _.filter(dataSource, item => !item.description || !item.description.trim())
	}

	if (showNoStatus) {
		dataSource = _.filter(dataSource, item => Number(item.status) === 0)
	}

	dataSource = _.filter(dataSource, item => _.includes(selectedLetters, item.word[0].toUpperCase()))

	return dataSource
}

function getColumns(deleteWordHandler, editWordHandler, switchWordStatusHandler) {
	const columnProps = {
		style: {
			padding: '10px 16px',
			fontSize: 16,
			lineHeight: '22px'
		}
	}

	const columns = [
		// {
		// 	title: 'ID',
		// 	dataIndex: 'id',
		// 	key: 'id',
		// 	width: 50,
		// 	render: text => ({
		// 		props: columnProps,
		// 		children: text
		// 	})
		// },
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			width: 50,
			render: (text, wordData) => {
				const wordStatus = Number(wordData.status)

				return {
					props: columnProps,
					children: <div>
							<Flex align justify>
								<Popover content="Просмотрено" placement="left">
									<div
										onClick={() => switchWordStatusHandler(wordData)}
										className="checkbox"
										style={{
											border: `1px solid ${ wordStatus ? 'transparent' : '#ccc' }`,
											borderRadius: '50%',
											width: 22,
											height: 22,
											marginRight: 10,
											backgroundColor: `${ wordStatus ? '#52c41a' : 'transparent' }`,
											boxSizing: 'content-box',
											cursor: 'pointer',
											margin: '0 auto'
										}}
									>
											<FeatherIcon icon="check" size="18" style={{
												position: 'relative',
												left: 2,
												top: 2,
												opacity: wordStatus ? 1 : .4,
												color: wordStatus ? '#fff' : '#000'
											}}/>
									</div>
								</Popover>
							</Flex>
					</div>
				}
			}
		},
		{
			title: 'Слово',
			dataIndex: 'word',
			key: 'word',
			sorter: (a, b) => a.word[0].localeCompare(b.word[0]),
			render: text => {
				return {
					props: columnProps,
					children: <b>{ text }</b>
				}
			}
		},
		{
			title: 'Определение',
			dataIndex: 'description',
			key: 'description',
			render: text => ({
				props: columnProps,
				children: text
			})
		},
		{
			title: 'Последнее изменение',
			dataIndex: 'updated_at',
			key: 'updated_at',
			sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
			render: (text, record) => {
				let content

				if (record.admin_id && record.admin_id !== "0") {
					const [ userName, userSurname ] = record.admin_name.split(/\s/)

					content = <div style={{ whiteSpace: 'nowrap' }}>
						<div>{ record.updated_at }</div>
						<div>{ `от ${ userName } ${ userSurname[0] }. (ID: ${ record.admin_id })` }</div>
					</div>
				}
				else {
					content = '—'
				}

				return {
					props: columnProps,
					children: <div style={{ textAlign: 'center' }}>{ content }</div>
				}
			}
		},
		{
			title: 'Действие',
			key: 'action',
			align: 'center',
			width: 100,
			render: (text, record) => ({
				props: columnProps,
				children: (
					<div style={{
						whiteSpace: 'nowrap',
						display: 'flex'
					}}>
						<Button type="link" onClick={() => editWordHandler(record)}>Изменить</Button>

						{/* <Popconfirm
							title="Уверены?"
							okText="Удалить"
							onConfirm={() => deleteWordHandler(record.word)}
						>
							<Button danger type="text">Удалить</Button>
						</Popconfirm> */}
					</div>
				)
			})
		}
	]

	const tableColumns = columns.map(item => _.assign({}, item))

	return tableColumns
}