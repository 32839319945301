import * as _ from 'lodash-es'
import CheckboxField from "../fields/CheckboxField"
import { useEffect, useRef } from 'react'
import { useAuth } from '../../../provider/AuthProvider'
import { Popover } from 'antd'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'

export default function FillPlayerStars({ fields, setFields }) {
	const { storage } = useAuth()
	const isMapPointSet = useRef(false)
	let isChecked = _.has(storage.data.userSettings, 'fillPlayerStars') ? Number(storage.data.userSettings.fillPlayerStars) : 1

	const { map, point } = fields

	const onMapPointChange = () => {
		// без флага isMapPointSet будет первый лишний вызов
		if (isChecked && isMapPointSet.current) {
			let stars = fields.stars,
				locaitonStars = fields.location_stars
	
			if (stars && _.isString(stars)) {
				stars = JSON.parse(stars)
			}

			if (locaitonStars && _.isString(locaitonStars)) {
				locaitonStars = JSON.parse(locaitonStars)
			}
	
			const pointsStars = _.mapValues(new Array(point).fill(3))
	
			delete pointsStars[0]
	
			stars = {
				[map]: pointsStars
			}

			locaitonStars = {
				[map]: _.sum(_.values(pointsStars))
			}
	
			setFields(prevFields => {
				const newFields = { ...prevFields }
	
				newFields.stars = stars
				newFields.location_stars = locaitonStars
	
				return newFields
			})
		}

		isMapPointSet.current = true
	}

	// eslint-disable-next-line
	useEffect(onMapPointChange,[ map, point ])

	const changeIsChecked = value => {
		isChecked = storage.data.userSettings.fillPlayerStars = value

		storage.save()

		onMapPointChange()
	}

	return (
		<div className='group fillPlayerStars'>
			<div className="tooltipBtn">
				<Popover content={<div>При изменении map или point обновится поле <b>stars</b> и <b>location_stars</b>.<br/>Звёзды заполнятся до точки <b>point</b>, не включая её.</div>}>
					<FeatherIcon icon="info" size={23} style={{ opacity: .6, marginRight: 5 }}/>
				</Popover>
			</div>

			<CheckboxField
				data={{ name: 'fillPlayerStars', value: Number(isChecked), label: 'Заполнять звёзды автоматически' }}
				defaultValue={Number(isChecked)}
				onValueChange={changeIsChecked}/>
		</div>
	)
}

FillPlayerStars.needShow = fields => _.has(fields, 'stars')