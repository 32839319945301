import cat from './cat'
import find from './find'
import sweet from './sweet'
import trip from './trip'
import villa from './villa'
import words from './words'
import wg from './wg'
import house from './house'

export default {
	find,
	cat,
    sweet,
    trip,
    villa,
    alice: villa,
    words,
    wg,
    house,
	indi: villa,
	pirate: villa
}