import zebomba from '../../../tools/zebomba'
import * as _ from 'lodash-es'
import { Button, Popover } from 'antd'

export default function DoneTutorials({ setFields }) {
	const onDoneTutorialsClick = () => {
		setFields(prevFields => {
			const newFields = { ...prevFields }

			if (_.has(prevFields, 'tutorial_settings')) {
				if (_.isEmpty(newFields.tutorial_settings)) {
					newFields.tutorial_settings = new Array(27).fill(1)
				}
				else if (_.isArray(prevFields.tutorial_settings)) {
					newFields.tutorial_settings = [ ...newFields.tutorial_settings ].fill(1)
				}
			}

			if (_.has(prevFields, 'cinema')) {
				if (_.isEmpty(prevFields.cinema)) {
					newFields.cinema = new Array(4).fill(0)
				}
				else {
					newFields.cinema = [ ...newFields.cinema ]
				}

				newFields.cinema[3] = 1
			}

			if (_.has(prevFields, 'magic_sphere')) {
				// if (_.isEmpty(prevFields.magic_sphere)) {
				// 	newFields.magic_sphere = new Array(4).fill(0)
				// }
				if (!_.isEmpty(prevFields.magic_sphere)) {
					newFields.magic_sphere = [ ...newFields.magic_sphere ]

					newFields.magic_sphere[1] = 1
				}
			}

			if (_.has(prevFields, 'battle_pass')) {
				if (_.isEmpty(prevFields.battle_pass)) {
					newFields.battle_pass = new Array(7).fill(0)
				}
				else {
					newFields.battle_pass = [ ...newFields.battle_pass ]
				}

				newFields.battle_pass[6] = 1
			}

			if (_.has(prevFields, 'friends_bonus') && !_.isEmpty(prevFields.friends_bonus)) {
				newFields.friends_bonus = [ ...newFields.friends_bonus ]
				newFields.friends_bonus[4] = 1
			}

			if (_.has(prevFields, 'portal')) {
				if (_.isEmpty(prevFields.portal)) {
					newFields.portal = new Array(5).fill(0)
				}
				else {
					newFields.portal = [ ...newFields.portal ]
				}

				newFields.portal[4] = 4
			}

			if (_.has(prevFields, 'missions')) {
				const mysteryReward = _.get(newFields.missions, '0.19')
				
				if (mysteryReward) {
					mysteryReward[5] = 3
				}
			}

			return newFields
		})

		zebomba.message.success('Поля обновлены')
	}

	const popoverContent = (
		<>
			<div>После сброса будут затронуты не все поля, где есть флаг обучения.</div>
			<div>Некоторые поля должны оставаться пустыми,<br/>чтобы сервер установил верные первоначальные данные.</div>
		</>
	)

	return (
		<Popover content={popoverContent}>
			<Button type="primary" className="button doneTutorials" onClick={onDoneTutorialsClick}>Пропустить обучения</Button>
		</Popover>
	)
}

DoneTutorials.needShow = fields => {
	const usedFields = [
		'tutorial_settings',
		'cinema',
		'magic_sphere',
		'battle_pass',
		'friends_bonus',
		'portal'
	]

	return _.some(usedFields, field => _.has(fields, field))
}