import { Table, Checkbox } from "antd"
import * as _ from 'lodash-es'
import { useAppData } from "../../../pages/Project"
import textConfig from '../textConfig.json'
import { useState } from "react"

export default function ActionsTable({ actionsData, actionsField }) {
	const app = useAppData()
	const { actions } = actionsData
	const areAllChecked = getAreAllChecked(actionsData, actionsField)
	const [ allChecked, setAllChecked ] = useState(areAllChecked)

	const tableColumns = [
		{
			title: 'ID',
			key: 'id',
			dataIndex: 'id',
			align: 'center'
		},
		{
			title: 'Название',
			key: 'name',
			dataIndex: 'name'
		},
		{
			title: 'Описание',
			key: 'descr',
			dataIndex: 'descr',
			render: text => (
				<div style={{ wordWrap: 'break-word', wordBreak: 'break-word', width: 200, whiteSpace: 'break-spaces' }}>
					{text}
				</div>
			)
		},
		{
			title: <Checkbox checked={allChecked} onChange={e => toggleAllCheckbox(e.target.checked)}>Выполнено</Checkbox>,
			key: 'done',
			dataIndex: 'done',
			align: 'center',
			width: 150
		}
	]

	const toggleAllCheckbox = checked => {
		_.forEach(actions, (data, actionID) => {
			onCheckboxChange(checked, actionID)
		})
		
		setAllChecked(checked)
	}

	const onCheckboxChange = (checked, actionID) => {
		actionsField[actionID] = Number(checked)

		// Значение 0 не нужно хранить, удаляем ключ
		if (!actionsField[actionID]) {
			delete actionsField[actionID]
		}
	}

	const actionsTexts = textConfig[app.config.name][actionsData.locationName][actionsData.name]

	const tableData = _.map(actions, (action, actionID) => ({
		id: actionID,
		key: actionID,
		name: action.name,
		descr: actionsTexts[action.name],
		done: <Checkbox
			key={allChecked}
			defaultChecked={!!actionsField[actionID]}
			onChange={e => onCheckboxChange(e.target.checked, actionID)}
		/>
	}))

	return (
		<div style={{ marginTop: 25 }}>
			<Table
				scroll={{
					y: 500
				}}
				style={{ whiteSpace: 'pre' }}
				tableLayout="auto"
				size="small"
				pagination={false}
				dataSource={tableData}
				columns={tableColumns}/>
		</div>
	)
}

const getAreAllChecked = (actionsData, actionsField) => {
	return _.size(actionsData.actions) === _.size(actionsField) && !_.some(actionsData.actions, (action, actionID) => !actionsField[actionID])
}