import * as _ from 'lodash-es'
import DoneTutorials from "./DoneTutorials"
import FillPlayerStars from './FillPlayerStars'
import ExportFields from './ExportFIelds'
import ImportFields from './ImportFields'
import ResetFields from './ResetFields'
import Sales from './Sales/Sales'
import InterstitialAd from './interstitialAd/InterstitialAd'
import Snapshots from './snapshots/Snapshots'
import { createElement } from 'react'
import { useAuth } from '../../../provider/AuthProvider'

import { useAppData } from '../../pages/Project'

export default function HelpButtons({ fields, setFields, resetAppHandler }) {
	const { config: appConfig, appWorldData } = useAppData()
	const { user } = useAuth()

	const props = {
		setFields,
		fields,
		resetAppHandler
	}

	const buttons = [
		ResetFields,
		ExportFields,
		ImportFields
	]

	if (DoneTutorials.needShow(fields)) {
		buttons.push(DoneTutorials)
	}

	if (appWorldData.interstitials) {
		props.interstitials = appWorldData.interstitials
		props.appID = appConfig.appID

		buttons.push(InterstitialAd)
	}

	buttons.push(Sales)
	buttons.push(Snapshots)
	
	if (FillPlayerStars.needShow(fields)) {
		buttons.push(FillPlayerStars)
	}

	if (buttons.length) {
		return (
			<div className="helpButtons">
				{ _.map(buttons, (button, index) => {
					props.key = index

					return createElement(button, props)
				}) }
			</div>
		)
	}
}