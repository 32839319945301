import { Modal, Form, Table, Typography, Input, InputNumber, Flex } from "antd"
import { useState } from "react"
import * as _ from 'lodash-es'
import zebomba from "../../../../tools/zebomba"

function BotsModal({ bots, isModalOpen, onChange, handleCancel }) {
	const originData = _.map(bots, (rating, id) => ({
		key: id,
		id,
		rating
	}))

	const [form] = Form.useForm()
	const [data, setData] = useState(originData)
	const [editingKey, setEditingKey] = useState('')

	const EditableCell = ({
	  editing,
	  dataIndex,
	  title,
	  inputType,
	  record,
	  index,
	  children,
	  ...restProps
	}) => {
	  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />

	  return (
		<td {...restProps}>
		  {editing ? (
			<Form.Item
			  name={dataIndex}
			  style={{
				margin: 0,
			  }}
			  rules={[
				{
				  required: true,
				  message: `Please Input ${title}!`,
				},
			  ]}
			>
			  {inputNode}
			</Form.Item>
		  ) : (
			children
		  )}
		</td>
	  )
	}

	const isEditing = (record) => record.key === editingKey

	const edit = (record) => {
		form.setFieldsValue({
			name: '',
			...record,
		})

		setEditingKey(record.key)
	}

	const cancel = () => { setEditingKey('') }

	const save = async (key) => {
		try {
			const row = await form.validateFields()
			const newData = [...data]
			const index = newData.findIndex((item) => key === item.key)

			if (index > -1) {
				const item = newData[index]
				newData.splice(index, 1, {
					...item,
					...row,
				})
				setData(newData)
				setEditingKey('')
			} else {
				newData.push(row)
				setData(newData)
				setEditingKey('')
			}

			onChange(newData)

			zebomba.message.success('Данные успешно обновлены')
		} catch (errInfo) {
			zebomba.message.success('Что-то не так с данными...')
		}
	}
	const columns = [
	{
		title: 'ID игрока',
		dataIndex: 'id',
		width: '25%',
		editable: true
	},
	{
		title: 'Рейтинг',
		dataIndex: 'rating',
		width: '25%',
		editable: true,
		sorter: (a, b) => a.rating - b.rating,
		defaultSortOrder: 'descend'
	},
	{
		title: 'Действие',
		dataIndex: 'operation',
		width: '25%',
		align: 'center',
		render: (_, record) => {
			const editable = isEditing(record)

			return editable ? (
				<Flex justify="center">
					<Typography.Link
						onClick={() => save(record.key)}
						style={{
						marginRight: 8,
						}}
					>
						Сохранить
					</Typography.Link>
						<div onClick={cancel}>Отменить</div>
				</Flex>
			) : (
				<Flex justify="center">
					<Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
						Изменить
					</Typography.Link>
				</Flex>
			)
		},
	},
	]
	const mergedColumns = columns.map((col) => {
	if (!col.editable) {
		return col
	}
	return {
			...col,
			onCell: (record) => ({
			record,
			inputType: col.dataIndex === 'rating' ? 'number' : 'text',
			dataIndex: col.dataIndex,
			title: col.title,
			editing: isEditing(record),
			}),
		}
	})

	return (
		<Modal
			width={680}
			className="emojiFieldModal"
			destroyOnClose={true}
			transitionName=""
			title="Положение игроков в текущей лиге"
			open={true}
			footer=""
			onCancel={handleCancel}
		>
			<Form
				form={form}
				component={false}
			>
				<Table
					size="small"
					components={{
						body: {
							cell: EditableCell,
						}
					}}
					bordered
					dataSource={data}
					columns={mergedColumns}
					rowClassName="editable-row"
					pagination={{
						onChange: cancel
					}}
				/>
			</Form>
		</Modal>
	)
}

export default BotsModal