import Apps from "./Apps"
import * as _ from 'lodash-es'

class User {
	#id
	#name
	#token
	#apps
	#permissions
	#ip

	constructor(userData) {
		this.#id = Number(userData?.id)
		this.#name = userData?.name
		this.#token = userData?.token
		this.#permissions = userData?.permissions || []
		this.#ip = userData?.ip || null

		this.#permissions = _.reduce(this.#permissions, (permissions, item, permission) => {
			if (item === true) {
				permissions.push(permission)
			}

			return permissions
		}, [])

		this.#apps = new Apps(userData.availableProjects)
	}

	hasPermission( permission ) {
		if (permission === 'devtools') {
			if (this.#id === 24 || this.#id === 18) {
				return true
			}
		}

		if (this.#id === 8 || this.#id === 19) {
			return true
		}

		return _.isString(permission) ? _.includes(this.#permissions, permission) : true
	}

	get id() {
		return this.#id
	}

	get name() {
		return this.#name
	}

	get token() {
		return this.#token
	}

	get apps() {
		return this.#apps
	}

	get ip() {
		return this.#ip
	}
}

export default User