import { Fragment, useRef } from 'react'
import zebomba from '../../../tools/zebomba'

export default function EditRawFieldPopup({ data, setFormField, closePopup }) {
	const rawField = useRef('')

	const onSaveBtnClick = value => {
		try {
			const rawFieldValue = rawField.current.value.trim()
			const fieldValue = rawFieldValue ? JSON.parse(rawField.current.value) : rawFieldValue

			setFormField({
				name: data.fieldName,
				config: data,
				value: fieldValue
			})

			zebomba.message.success('Поле обновлено')

			closePopup()
		}
		catch(e) {
			zebomba.message.error('Не удалось сохранить')

			console.error(e)
		}
	}

	return (
		<Fragment>
			<div className="popupTitle">{ data.fieldName }</div>

			<textarea ref={rawField} className="rawField" defaultValue={data.fieldData ? JSON.stringify(data.fieldData, null, 2) : ''}></textarea>

			<div className="button blue saveBtn" onClick={onSaveBtnClick}>Применить изменения</div>
		</Fragment>
	)
}