import { Modal, Button } from 'antd'
import { useState } from 'react'
import AceEditor from 'react-ace'
import 'ace-builds/src-min-noconflict/mode-json'
import 'ace-builds/webpack-resolver'

import * as _ from 'lodash-es'

export default function RawEditorModal({ title, isModalOpen, handleCancel, object, onSave }) {
	const [ editorValue, setEditorValue ] = useState(object)
	const [ isCodeValid, setIsCodeValid ] = useState(false)

	const save = () => {
		if (editorValue !== object) {
			onSave((editorValue && JSON.parse(editorValue)) || editorValue)
		}

		handleCancel()
	}

	return (
		<Modal
			className="salesModal"
			destroyOnClose={true}
			title={title}
			transitionName=""
			open={isModalOpen}
			footer=""
			onCancel={handleCancel}
		>
			<AceEditor
				fontSize={17}
				mode="json"
				onChange={value => setEditorValue(value)}
				onValidate={errors => setIsCodeValid(_.isEmpty(errors))}
				defaultValue={object}
				name="rawEditor"
				// отключение валидатора
				// setOptions={{
				// 	useWorker: false
				// }}
				editorProps={{ $blockScrolling: true }}
				style={{
					border: '1px solid #ccc'
				}}
				width="100%"
			/>

			<Button
				type="primary"
				size="large"
				style={{
					marginTop: 20,
					marginBottom: -10
				}}
				disabled={!isCodeValid}
				onClick={save}
			>
				Применить изменения
			</Button>
		</Modal>
	)
}