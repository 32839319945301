import * as _ from 'lodash-es'
import { useState } from 'react'
import WinnersModal from './WinnersModal'
import { Button } from 'antd'

export default function WinnersField({ data, onValueChange, defaultValue }) {
	const [ isModalOpen, setIsModalOpen ] = useState(false)
	const showModal = () => { setIsModalOpen(true) }
	const handleCancel = () => { setIsModalOpen(false) }

	let winners

	try {
		winners = JSON.parse(data.value)
	}
	catch {
		return <>Что-то не так с полем "winners".</>
	}

	return (
		<>
			<Button
				className="editBtn"
				style={{ fontSize: 15 }}
				onClick={showModal}
			>
				Изменить
			</Button>

			{ isModalOpen && (
				<WinnersModal
					onChange={winners => {
						const newBotsData = _.reduce(winners, (result, winner) => {
							result[winner.id] = [ winner.place, winner.rating ]

							return result
						}, {})

						onValueChange(JSON.stringify(newBotsData))
					}}
					winners={winners}
					handleCancel={handleCancel}
				/>
			)}
		</>
	)
}