import { Switch, Form, Input, Flex, Select, Button } from 'antd'
import Files from 'react-files'
import FeatherIcon from 'feather-icons-react'

const alphabet = 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ'

export default function TableTop({
		tableData,
		dataSource,
		setSearchInput,
		showEmptyDefs,
		setShowEmptyDefs,
		showNoStatus,
		setShowNoStatus,
		setSelectedLetters,
		onExportBtnClick,
		onFilesChange
	}) {

	return <>
	<Flex align justify='space-between'>
		<Flex vertical>
			<div style={{ fontSize: 18, marginBottom: 10 }}>Словарь проекта "Словесный микс"</div>
			<div style={{ fontSize: 16, marginBottom: 40 }}>Всего слов: { tableData.length }</div>
		</Flex>

		<Flex align justify='flex-end' gap='small'>
			<Button type="primary" className="button exportBtn" onClick={onExportBtnClick}>
				<FeatherIcon icon="log-out" size="18"/>
				Экспорт данных
			</Button>
			<Files accepts={[ '.csv' ]} onChange={onFilesChange}>
				<Button type="primary" className="button importBtn">
					<FeatherIcon icon="log-out" size="18"></FeatherIcon>
					Импорт данных
				</Button>
			</Files>
		</Flex>
	</Flex>
		

		<Form style={{ marginBottom: 10 }}>

			<Form.Item label="Поиск слова" style={{ marginBottom: 10 }}>
				<Input onChange={e => setSearchInput(e.target.value.trim().toLowerCase())}/>
			</Form.Item>

			<Flex align>
				<Form.Item label="Буквы" style={{ marginRight: 25 }}>
					<Select
						maxTagCount={0}
						showSearch={false}
						mode="multiple"
						defaultValue={alphabet.split('')}
						options={alphabet.split('')
							.map(a => ({
								label: a,
								value: a
							}))
						}
						allowClear
						onChange={letters => { setSelectedLetters(letters) }}
					/>
				</Form.Item>

				<Form.Item label="Без определений" style={{ marginRight: 25 }}>
					<Switch checked={showEmptyDefs} onChange={setShowEmptyDefs}/>
				</Form.Item>

				<Form.Item label="Непросмотренные">
					<Switch checked={showNoStatus} onChange={setShowNoStatus}/>
				</Form.Item>
			</Flex>

		</Form>

		<Form.Item label="Выбрано:">{ dataSource.length }</Form.Item>
	</>
}