import { useImmer } from 'use-immer'
import * as _ from 'lodash-es'
import { useRef, useState } from 'react'
import { Table, Input  } from 'antd'

import FormField from '../form/FormField'
import { useAuth } from '../../provider/AuthProvider'
import zebomba from '../../tools/zebomba'
import AppConfig from '../config/AppConfig'
import Button from '../UI/Button'

export default function Payments({ data }) {
	const historyTableData = useRef(null)
	const { user } = useAuth()
	const [ isRequestInProgress, setIsRequestInProgress ] = useState(false)

	const [ fields, updateFields ] = useImmer({
		userID: '',
		appID: ''
	})

	document.title = data.title

	const onShowBtnClick = async () => {
		setIsRequestInProgress(true)

		const requestData = _.assign({
			id: user.id,
			access_token: user.token,
			user_id: fields.userID
		}, user.apps.byName[fields.appID].dataForRequest)

		const response = await fetch(`${ AppConfig.api.getPayments }?${ new URLSearchParams(requestData) }`)

		const data = await response.json()

		setIsRequestInProgress(false)

		historyTableData.current = null

		if (data.payments) {
			if (!data.payments.length) {
				zebomba.message.success('История пуста')
			}
			else {
				let rows = _.map(data.payments, item => {
					return {
						transaction_time: item.transaction_time,
						amount: item.amount,
						product_code: item.product_code,
						ad_id: item.ad_id
					}
				})

				historyTableData.current = rows
			}
		}
		else if (data.error_key) {
			zebomba.message.error(data.error)
		}
		else {
			zebomba.message.error('Что-то пошло не так...')
		}
	}

	let historyTable

	if (historyTableData.current) {
		const columns = [
			{
				title: 'Время оплаты',
				key: 'transaction_time',
				dataIndex: 'transaction_time',
				sorter: (a, b) => Date.parse(a.transaction_time) - Date.parse(b.transaction_time),
				defaultSortOrder: 'descend'
			},
			{
				title: 'Потрачено валюты',
				key: 'amount',
				dataIndex: 'amount',
				sorter: (a, b) => a.amount - b.amount
			},
			{
				title: 'ID товара',
				key: 'product_code',
				dataIndex: 'product_code',
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
					return <Input
						autoFocus
						placeholder='Поиск...'
						onChange={e => {
							setSelectedKeys([ e.target.value.trim() ])
							confirm({ closeDropdown: false })
						}}
						/>
				},
				onFilter: (value, record) => _.startsWith(record.product_code, value)
			},
			{
				title: 'ID рекламы',
				key: 'ad_id',
				dataIndex: 'ad_id',
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
					return <Input
						autoFocus
						placeholder='Поиск...'
						onChange={e => {
							setSelectedKeys([ e.target.value.trim() ])
							confirm({ closeDropdown: false })
						}}
						/>
				},
				onFilter: (value, record) => _.startsWith(record.ad_id, value)
			}
		]

		historyTable = <Table dataSource={historyTableData.current} columns={columns}/>
	}

	return (
		<div className="center">
			<div className="coinsStats form">
				<div className="title">Платежи игрока</div>

				<FormField
					data={{ name: 'userID', label: 'ID пользователя', type: 'text' }}
					defaultValue={fields.userID}
					onFieldChange={field => updateFields(fields => { fields.userID = field.value })} />

				<FormField
					data={{ name: 'appID', label: 'Приложение', type: 'select', options: user.apps.allNamesList, hideIndex: true, showSearch: true }}
					onFieldChange={field => updateFields(fields => { fields.appID = field.config.options[field.value] })}/>

				<Button className="showBtn" disabled={!(fields.userID && fields.appID)} wait={isRequestInProgress} onClick={onShowBtnClick}>Показать</Button>

				{ historyTable }
			</div>
		</div>
	)
}