import * as _ from 'lodash-es'
import { Table, Tag, Checkbox, Input, Button, Popover } from 'antd'
import emojisConfig from "./configs/config"
import { useState } from 'react'
import { useAppData } from "../../pages/Project"

const DEFAULT_SMILES_IDS = [ 1, 2, 3, 4 ]

export default function EmojiTable({ smiles, setSmiles }) {
	const app = useAppData()
	const emojiConfig = emojisConfig[app.config.name]
	const [ selectedSmiles, setselectedSmiles ] = useState()

	const getSmileRarity = rarity => {
		let color

		if (rarity === 'rare') {
			color = 'blue'
		}
		else if (rarity === 'epic') {
			color = 'purple'
		}
		else if (rarity === 'legendary') {
			color = 'volcano'
		}

		return <Tag key={rarity} color={color}>{ rarity }</Tag>
	}

	const dataSource = _.map(emojiConfig, (smile, smileID) => {
		smileID = +smileID

		return {
			key: smileID,
			id: smileID,
			name: smile.name,
			rarityTag: getSmileRarity(smile.rarity),
			rarity: smile.rarity || 'none',
			type: smile.type,
			awards_mixer: smile.awards_mixer || '',
			hasPlayer: <Checkbox key={smileID} checked={_.includes(smiles, smileID)} onChange={e => {
				if (!e.target.checked) {
					setSmiles(smiles => _.filter(smiles, item => item !== smileID))
				}
				else {
					setSmiles(smiles => _.uniq([ ...smiles, smileID ]))
				}
			}}/>
		}
	})

	const allRarities = _.uniq(_.map(emojiConfig, smile => smile.rarity))
	const allMixers = _.uniq(_.map(emojiConfig, smile => smile.awards_mixer))

	const columns = [
		{
			title: 'ID',
			key: 'id',
			dataIndex: 'id'
		},
		{
			title: 'Название',
			key: 'name',
			dataIndex: 'name',
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
				return <Input
					autoFocus
					placeholder='Поиск...'
					onChange={e => {
						setSelectedKeys([ e.target.value.trim() ])
						confirm({ closeDropdown: false })
					}}
					/>
			},
			onFilter: (value, record) => _.includes(record.name, value)
		},
		{
			title: 'Редкость',
			key: 'rarityTag',
			dataIndex: 'rarityTag',
			filters: _.map(allRarities, rarity => ({
				text: rarity || 'Без редкости',
				value: rarity || 'none'
			})),
			onFilter: (value, record) => record.rarity === value
		},
		{
			title: 'Тип',
			key: 'type',
			dataIndex: 'type',
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
				return <Input
					autoFocus
					placeholder='Поиск...'
					onChange={e => {
						setSelectedKeys([ e.target.value.trim() ])
						confirm({ closeDropdown: false })
					}}
					/>
			},
			onFilter: (value, record) => _.includes(record.type, value)
		},
		{
			title: 'Миксер',
			key: 'awards_mixer',
			dataIndex: 'awards_mixer',
			filters: _.map(allMixers, rarity => ({
				text: rarity || 'Без миксера',
				value: rarity || ''
			})),
			onFilter: (value, record) => record.awards_mixer === value
		},
		{
			title: 'У игрока',
			key: 'hasPlayer',
			dataIndex: 'hasPlayer',
			filters: _.map([ true, false ], flag => ({
				text: (flag && 'Есть') || 'Нету',
				value: flag
			})),
			render: (node, record) => {
				if (DEFAULT_SMILES_IDS.includes(record.id)) {
					return (
						<Popover content={<>Дефолтные смайлы не указываются в поле emoji<br/>и не регулируются (нельзя убрать).</>}>
							Есть всегда
						</Popover>
					)
				}

				return node
			},
			onFilter: (value, record) => {
				if (DEFAULT_SMILES_IDS.includes(record.id)) {
					return value
				}

				return record.hasPlayer.props.checked === value
			}
		},
	]

	return <>
		<div style={{
			display: 'flex',
			alignItems: 'center'
		}}>

			<Button
				type="default"
				onClick={() => setSmiles(smiles => _.uniq([ ...selectedSmiles, ...smiles ]))}
				disabled={_.isEmpty(selectedSmiles)}>Добавить выбранные</Button>

			<Button
				style={{ marginLeft: 8 }}
				type="default"
				onClick={() => setSmiles(smiles => _.without(smiles, ...selectedSmiles))}
				disabled={_.isEmpty(selectedSmiles)}>Убрать выбранные</Button>

			<Button
				style={{ marginLeft: 8 }}
				type="default"
				onClick={() => setSmiles([])}>Убрать все</Button>

			<Button
				style={{ marginLeft: 8 }}
				type="default"
				onClick={() => {
				const allSmilesIDs = _.map(emojiConfig, (smile, smileID) => +smileID)

				setSmiles(allSmilesIDs)
			}}>Добавить все</Button>
		</div>

		<Table
			style={{
				marginTop: 14
			}}
			size='small'
			rowSelection={{
				type: 'checkbox',
				onSelect: (a, b, rows) => setselectedSmiles(_.map(rows, row => row.id)),
				onSelectAll: (a, rows) => setselectedSmiles(_.map(rows, row => row.id))
			}}
			dataSource={dataSource}
			columns={columns}/>
	</>
}