import * as _ from 'lodash-es'
import { useAuth } from '../../provider/AuthProvider'
import FormField from '../form/FormField'
import { useState, useRef } from 'react'
import AppConfig from '../config/AppConfig'
import zebomba from '../../tools/zebomba'
import { useImmer } from 'use-immer'
import { Table, Input } from 'antd'
import Button from '../UI/Button'

export default function ReviewsErrors({ data }) {
	const [ isRequestInProgress, setIsRequestInProgress ] = useState(false)
	const historyTableData = useRef(null)
	const [ formData, setFormData ] = useImmer({})

	const { user } = useAuth()

	document.title = data.title

	// let historyTable

	// if (historyTableData.current) {
	// 	historyTable = <Table data={historyTableData.current} showSpinner={isRequestInProgress}></Table>
	// }

	const onShowBtnClick = async () => {
		setIsRequestInProgress(true)

		const params = _.assign({
			id: user.id,
			access_token: user.token,
		}, user.apps.byName[formData.appID].dataForRequest)

		const request = formData.messagesType === 'error' ? AppConfig.api.getReviewsErrors : AppConfig.api.getReviews
		const response = await fetch(`${ request }?${ new URLSearchParams(params) }`)

		const data = await response.json()
		
		setIsRequestInProgress(false)

		historyTableData.current = null

		const reviews = data.reviews_errors || data.reviews

		if (reviews) {
			if (reviews.length) {
				const rows = _.map(reviews, item => {
					const userID = _.includes(item.platform, 'ok.ru') ? <a className="link" target="_blank" rel="noreferrer" href={`https://ok.ru/profile/${ item.user_id }`}>{ item.user_id }</a> : item.user_id

					return {
						time: item.time,
						email: item.email,
						id: item.id,
						map: `${ item.map }-${ item.point }`,
						platform: item.platform,
						rev: item.rev,
						review: _.has(item, 'review') ? item.review : _.has(item, 'error') ? item.error : '',
						user_id: userID
					}
				})

				historyTableData.current = rows

				setFormData(data => {
					data.canShow = true

					return data
				})
			}
			else {
				zebomba.message.success('История сообщений пуста')
			}
		}
		else if (data.error) {
			zebomba.message.error(data.error)
		}
		else {
			zebomba.message.error('Что-то пошло не так...')
		}
	}

	// Только Яндекс или Mobile
	// также CG для FIND
	const filteredApps = _.filter(user.apps.list, app => app.social === 'yandex' || 
		app.social === 'ok' || 
		app.platform === 'mobile' || 
		(app.name === 'find' && app.social === 'cg') ||
		(app.name === 'find' && app.social === 'tg')
	)
	const sortedApps = user.apps.sortNames(_.map(filteredApps, app => app.fullName))

	let historyTable

	if (historyTableData.current) {
		const columns = [
			{
				title: 'Время',
				key: 'time',
				dataIndex: 'time',
				sorter: (a, b) => Date.parse(a.time) - Date.parse(b.time),
				defaultSortOrder: 'descend'
			},
			{
				title: 'Email',
				key: 'email',
				dataIndex: 'email',
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
					return <Input
						autoFocus
						placeholder='Поиск...'
						onChange={e => {
							setSelectedKeys([ e.target.value.trim() ])
							confirm({ closeDropdown: false })
						}}
						/>
				},
				onFilter: (value, record) => record.email?.includes(value)
			},
			{
				title: 'ID',
				key: 'id',
				dataIndex: 'id',
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
					return <Input
						autoFocus
						placeholder='Поиск...'
						onChange={e => {
							setSelectedKeys([ e.target.value.trim() ])
							confirm({ closeDropdown: false })
						}}
						/>
				},
				onFilter: (value, record) => _.startsWith(record.id, value)
			},
			{
				title: 'Локация-точка',
				key: 'map',
				dataIndex: 'map',
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
					return <Input
						autoFocus
						placeholder='Поиск...'
						onChange={e => {
							setSelectedKeys([ e.target.value.trim() ])
							confirm({ closeDropdown: false })
						}}
						/>
				},
				onFilter: (value, record) => _.startsWith(record.user_id, value)
			},
			{
				title: 'Платформа',
				key: 'platform',
				dataIndex: 'platform',
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
					return <Input
						autoFocus
						placeholder='Поиск...'
						onChange={e => {
							setSelectedKeys([ e.target.value.trim() ])
							confirm({ closeDropdown: false })
						}}
						/>
				},
				onFilter: (value, record) => record.platform?.toLowerCase().includes(value)
			},
			{
				title: 'rev',
				key: 'rev',
				dataIndex: 'rev',
				sorter: (a, b) => a.rev - b.rev
			},
			{
				title: 'Сообщение',
				key: 'review',
				dataIndex: 'review',
				className: 'review',
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
					return <Input
						autoFocus
						placeholder='Поиск...'
						onChange={e => {
							setSelectedKeys([ e.target.value.trim() ])
							confirm({ closeDropdown: false })
						}}
						/>
				},
				onFilter: (value, record) => record.review && record.review.toLowerCase().includes(value)
			},
			{
				title: 'ID профиля',
				key: 'user_id',
				dataIndex: 'user_id',
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
					return <Input
						autoFocus
						placeholder='Поиск...'
						onChange={e => {
							setSelectedKeys([ e.target.value.trim() ])
							confirm({ closeDropdown: false })
						}}
						/>
				},
				onFilter: (value, record) => _.startsWith(record.user_id, value)
			}
		];

		historyTable = <Table
			dataSource={historyTableData.current}
			columns={columns}
			/>
	}

	return (
		<div className="content center">
			<div className="setGiftForm form">
				<div className="content">
					<div className="title">Сообщения</div>

					<FormField
						data={{
							name: 'appID',
							label: 'Приложение',
							type: 'select',
							options: sortedApps,
							hideIndex: true,
							showSearch: true
						}}
						onFieldChange={field => { setFormData(data => {
							data.appID = field.config.options[field.value]
							data.canShow = false
						}) }}
					/>

					<FormField
						data={{
							name: 'messagesType',
							label: 'Тип',
							type: 'select',
							options: {
								'common': 'Обычные',
								'error': 'Ошибки'
							},
							hideIndex: true,
							showSearch: false
						}}
						defaultValue="common"
						onFieldChange={field => { setFormData(data => {
							data.messagesType = field.value
							data.canShow = false
						}) }}
					/>

					<Button
						className='showBtn'
						disabled={!formData.appID}
						onClick={onShowBtnClick}
						wait={isRequestInProgress}>Показать</Button>
				</div>

				{ formData.canShow && historyTable }
			</div>
		</div>
	)
}