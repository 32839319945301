import { useParams } from "react-router-dom"
import { useAuth } from "../../provider/AuthProvider"
import { useEffect, useState } from "react"
import AppConfig from "../config/AppConfig"
import zebomba from "../../tools/zebomba"
import * as _ from 'lodash-es'
import { Fragment } from "react"

export default function ReviewsPage({ data }) {
	const [ reviews, setReviews ] = useState(null)
	const urlData = useParams()
	const { user } = useAuth()
	
	useEffect(() => {
		if (urlData['*']) {
			const [ appID, userID ] = urlData['*'].split('/'),
				[ appName, appPlatform, appVersion, appSocial ] = appID.split('_')
		
			const getReviews = async () => {
				const params = new URLSearchParams({
					access_token: user.token,
					id: user.id,
					app_name: appName,
					user_id: userID,
					platform: appPlatform,
					version: appVersion,
					soc_name: appSocial ? appSocial : 'vk'
				})

				const response = await fetch(`${ AppConfig.api.getReviews }?${ params }`)
		
				const data = await response.json()

				if (data.error_key) {
					zebomba.message.error(data.error)
				}
				else if (data.reviews) {
					setReviews(data.reviews)
				}
			}

			getReviews()
		}
	// eslint-disable-next-line
	}, [])

	document.title = data.title

	if (reviews) {
		const rows = _.map(reviews, (review, index) => {
			return (
				<tr key={ index }>
					<td>{ review.time }</td>
					<td className="review">{ review.review }</td>
					<td>{ `${ review.map }-${ review.point }` }</td>
					<td>{ review.id }</td>
					<td>{ review.rev }</td>
					<td>{ review.platform }</td>
					<td>{ review.email }</td>
				</tr>
			)
		})

		const [ appID, userID ] = urlData['*'].split('/')

		return (
			<Fragment>
				<div className="pageTitle">Таблица отзывов для { appID } ID: { userID }</div>

				<table className="table">
					<tr>
						<th>Время</th>
						<th>Комментарий</th>
						<th>Карта-точка</th>
						<th>ID</th>
						<th>rev</th>
						<th>Платформа</th>
						<th>Email</th>
					</tr>
					{ rows }
				</table>
			</Fragment>
		)
	}
}