export default {
	"action-vip": "Действует скидка!",
	"activate": "Активировать",
	"collect_items": "Заработай звёзд: {{cnt}}",
	"collect_cups": "Заработай кубков: {{cnt}}",
	"complete_daily_task": "Выполни Ежедневное задание: {{cnt}}",
	"enter_top_in_league": "Войди в топ-{{cnt}} лучших игроков своей лиги",
	"event_catch_items": "Поймай {{cnt}} в ивенте {{name}}",
	"event_complete": "Заверши особое событие {{name}}: {{cnt}}",
	"event_find_chests": "Найди сундуки ({{cnt}}) в ивенте {{name}}",
	"event_find_diamonds": "Найди алмазы ({{cnt}}) в ивенте {{name}} ",
	"event_get_resources": "Получи {{cnt}} в ивенте {{name}}",
	"event_open_chests": "Открой сундуки ({{cnt}}) в ивенте {{name}}",
	"event_recover_objects": "Восстанови объекты ({{cnt}}) в ивенте {{name}} ",
	"recover_objects": "Восстанови объекты ({{cnt}})",
	"event_win_games": "Выиграй игры ({{cnt}}) в ивенте {{name}}",
	"find_all_differences_on_image": "Найди все отличия на {{cnt}} картинках",
	"find_puzzles": "Собери {{cnt}} пазлов",
	"find_tasks_items": "Найди предметов миссии: {{cnt}}",
	"get_daily_bonus_some_times_in_a_row": "Получи ежедневный бонус: {{cnt}}",
	"invite_friends": "Пригласи друзей: {{cnt}}",
	"open_chests": "Открой сундуки ({{cnt}}) с любыми наградами",
	"open_mystery_chests": "Открой Мистические сундуки: {{cnt}}",
	"play_games": "Сыграй игр: {{cnt}}",
	"play_portal": "Сыграй в портал: {{cnt}}",
	"repost_achievements": "Сделай репосты ({{cnt}}) своих игровых достижений",
	"use_fortune_or_lottery": "Используй фортуну или лотерею: {{cnt}}",
	"use_hint_or_timer": "Используй подсказку или таймер: {{cnt}}",
	"use_smilies": "Используй смайлы во время раунда: {{cnt}}",
	"watch_video": "Посмотри рекламу: {{cnt}}",
	"win_games": "Выиграй игр: {{cnt}}",
	"use_diamonds": "Используй алмазов: {{cnt}}",
	"open_magic_flask": "Открой колбу: {{cnt}}",
	"fill_moneybox_coins": "Заполни копилку на {{cnt}} монет",
	"open_star_chest": "Открой звёздных сундуков: {{cnt}}",
	"send_friends_notifications": "Отправь уведомления своим друзьям: {{cnt}}",
	"play_games_tournament": "Сыграй игры на победителя: {{cnt}}",
	"play_games_winner": "Сыграй игры на вылет: {{cnt}}",
	"gift": "Подарок в обучении БП",
	"use_stars": "Используй звёзд: {{cnt}}",
	"earn_coins": "Заработай {{cnt}} монет",
	"earn_rating": "Заработай {{cnt}} монет",
	"use_boosters": "Используй {{cnt}} бустеров",
	"collect_words": "Собери {{cnt}} слов",
	"collect_words_5_letters_long": "Собери {{cnt}} слов длиной в 5 букв",
	"use_hints": "Используй подсказку или {{cnt}} раз",
	"get_daily_bonus": "Получи ежедневный бонус {{cnt}} раз",
	"play_bonus_level": "Сыграй в бонусный уровень {{cnt}} раз",
	"become_best_players_league": "Войди в топ лучших игроков своей лиги",
	"join_group": "Вступи в группу",
	"add_game_to_left_menu": "Добавь игру в левое меню",
	"open_chest": "Открой {{cnt}} сундуков с любыми наградами",
	"repost": "Сделай {{cnt}} репостов своих игровых достижений",
	"collect_words_3_letters_long": "Найди {{cnt}} коротких слов из 3х букв",
	"win_level": "Выиграй {{cnt}} уровней подряд",
	"fill_moneybox": "Заполни копилку на {{cnt}} монет",
	"get_puzzles": "Собери {{cnt}} пазлов"
}