const dev_vk_interstitials = {
    "loadingScreen": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 2
        }
    },
    "beforeBattle": {
        "probability": 100,
        "rules": {
            "map": 2,
            "point": 2
        }
    },
    "afterBattle": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 5,
            "no_payment_count": 0,
            "rev": 0
        }
    },
    "afterBattleRound": {
        "probability": 100,
        "roundsProbability": {
            "1": 100,
            "2": 100
        },
        "rules": {
            "map": 1,
            "point": 8,
            "no_payment_count": 0,
            "rev": 0
        }
    }
}

const release_vk_interstitials = {
    "loadingScreen": {
        "probability": 0,
        "rules": {
            "map": 1,
            "point": 2,
            "no_payment_count": 2,
            "rev": 0
        }
    },
    "beforeBattle": {
        "probability": 0,
        "rules": {
            "map": 2,
            "point": 2,
            "no_payment_count": 0,
            "rev": 0
        }
    },
    "afterBattle": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 5,
            "no_payment_count": 0,
            "rev": 0
        }
    },
    "afterBattleRound": {
        "probability": 100,
        "roundsProbability": {
            "1": 100,
            "2": 100
        },
        "rules": {
            "map": 1,
            "point": 8,
            "no_payment_count": 0,
            "rev": 0
        }
    }
}


export default {
	locationsInfo: {
		"1": {
			"name": "villa",
			"parts": {
				"1": {
					"name": "house",
					"rewards": {
						"8": {
							"coins": 20
						},
						"16": {
							"coins": 40
						},
						"25": {
							"coins": 80
						}
					},
					"actions": {
						"1": {
							"name": "garbage",
							"diamonds": 3
						},
						"2": {
							"name": "stairs",
							"diamonds": 3
						},
						"3": {
							"name": "weedsFountain",
							"diamonds": 3
						},
						"4": {
							"name": "walkway",
							"diamonds": 3
						},
						"5": {
							"name": "firstFloor",
							"diamonds": 3
						},
						"6": {
							"name": "cleanFountain",
							"diamonds": 3
						},
						"7": {
							"name": "mailbox",
							"diamonds": 3
						},
						"8": {
							"name": "repairBalcony",
							"diamonds": 3
						},
						"9": {
							"name": "fenceFountain",
							"diamonds": 3
						},
						"10": {
							"name": "oldTree",
							"diamonds": 4
						},
						"11": {
							"name": "ivyRoof",
							"diamonds": 4
						},
						"12": {
							"name": "waterFountain",
							"diamonds": 4
						},
						"13": {
							"name": "plantTree",
							"diamonds": 5
						},
						"14": {
							"name": "repairRoof",
							"diamonds": 5
						},
						"15": {
							"name": "liliesFountain",
							"diamonds": 5
						},
						"16": {
							"name": "waterSprouts",
							"diamonds": 5
						},
						"17": {
							"name": "repairWindows",
							"diamonds": 5
						},
						"18": {
							"name": "groundFlashlight",
							"diamonds": 5
						},
						"19": {
							"name": "decorateBalcony",
							"diamonds": 6
						},
						"20": {
							"name": "railing",
							"diamonds": 6
						},
						"21": {
							"name": "attachedFlashlight",
							"diamonds": 6
						},
						"22": {
							"name": "decorateWindows",
							"diamonds": 7
						},
						"23": {
							"name": "stairsPlant",
							"diamonds": 7
						},
						"24": {
							"name": "flowerbed",
							"diamonds": 7
						},
						"25": {
							"name": "paintHouse",
							"diamonds": 8
						}
					}
				},
				"2": {
					"name": "kitchen",
					"rewards": {
						"8": {
							"coins": 25
						},
						"16": {
							"coins": 50
						},
						"25": {
							"coins": 90
						}
					},
					"actions": {
						"1": {
							"name": "weedsTop",
							"diamonds": 4
						},
						"2": {
							"name": "weedsBottom",
							"diamonds": 4
						},
						"3": {
							"name": "pots",
							"diamonds": 4
						},
						"4": {
							"name": "burrows",
							"diamonds": 5
						},
						"5": {
							"name": "barbecue",
							"diamonds": 5
						},
						"6": {
							"name": "wardrobe",
							"diamonds": 5
						},
						"7": {
							"name": "stump",
							"diamonds": 6
						},
						"8": {
							"name": "meat",
							"diamonds": 6
						},
						"9": {
							"name": "tableCountertop",
							"diamonds": 6
						},
						"10": {
							"name": "bushes",
							"diamonds": 7
						},
						"11": {
							"name": "seaweed",
							"diamonds": 7
						},
						"12": {
							"name": "bake",
							"diamonds": 7
						},
						"13": {
							"name": "repairTable",
							"diamonds": 8
						},
						"14": {
							"name": "garbagePond",
							"diamonds": 8
						},
						"15": {
							"name": "canopy",
							"diamonds": 10
						},
						"16": {
							"name": "pavingStones",
							"diamonds": 9
						},
						"17": {
							"name": "plantsPond",
							"diamonds": 9
						},
						"18": {
							"name": "kitchenAccessories",
							"diamonds": 8
						},
						"19": {
							"name": "bench",
							"diamonds": 10
						},
						"20": {
							"name": "fishing",
							"diamonds": 10
						},
						"21": {
							"name": "flashlight",
							"diamonds": 9
						},
						"22": {
							"name": "decorateTable",
							"diamonds": 11
						},
						"23": {
							"name": "chairFishing",
							"diamonds": 11
						},
						"24": {
							"name": "track",
							"diamonds": 11
						},
						"25": {
							"name": "food",
							"diamonds": 12
						}
					}
				},
				"3": {
					"name": "workshop",
					"rewards": {
						"8": {
							"coins": 40
						},
						"16": {
							"coins": 80
						},
						"25": {
							"coins": 161
						}
					},
					"actions": {
						"1": {
							"name": "dandelions",
							"diamonds": 6
						},
						"2": {
							"name": "repairRoof",
							"diamonds": 6
						},
						"3": {
							"name": "garbage",
							"diamonds": 6
						},
						"4": {
							"name": "fallenTree",
							"diamonds": 9
						},
						"5": {
							"name": "fixWalls",
							"diamonds": 9
						},
						"6": {
							"name": "boxes",
							"diamonds": 9
						},
						"7": {
							"name": "hackTree",
							"diamonds": 12
						},
						"8": {
							"name": "lanterns",
							"diamonds": 12
						},
						"9": {
							"name": "door",
							"diamonds": 12
						},
						"10": {
							"name": "asphalt",
							"diamonds": 14
						},
						"11": {
							"name": "weeds",
							"diamonds": 14
						},
						"12": {
							"name": "repairRoofFinish",
							"diamonds": 14
						},
						"13": {
							"name": "steps",
							"diamonds": 17
						},
						"14": {
							"name": "fence",
							"diamonds": 17
						},
						"15": {
							"name": "repairWindows",
							"diamonds": 17
						},
						"16": {
							"name": "repairCar",
							"diamonds": 20
						},
						"17": {
							"name": "bushes",
							"diamonds": 20
						},
						"18": {
							"name": "paintWalls",
							"diamonds": 20
						},
						"19": {
							"name": "barrel",
							"diamonds": 22
						},
						"20": {
							"name": "plantFlowers",
							"diamonds": 22
						},
						"21": {
							"name": "sign",
							"diamonds": 22
						},
						"22": {
							"name": "wheels",
							"diamonds": 25
						},
						"23": {
							"name": "flowersWindow",
							"diamonds": 25
						},
						"24": {
							"name": "canopy",
							"diamonds": 25
						},
						"25": {
							"name": "paintCar",
							"diamonds": 25
						}
					}
				},
				"4": {
					"name": "beach",
					"rewards": {
						"8": {
							"coins": 48
						},
						"16": {
							"coins": 96
						},
						"25": {
							"coins": 193
						}
					},
					"actions": {
						"1": {
							"name": "cleanBeach",
							"diamonds": 7
						},
						"2": {
							"name": "wreckage",
							"diamonds": 7
						},
						"3": {
							"name": "area",
							"diamonds": 7
						},
						"4": {
							"name": "plants",
							"diamonds": 11
						},
						"5": {
							"name": "repairPier",
							"diamonds": 11
						},
						"6": {
							"name": "fence",
							"diamonds": 11
						},
						"7": {
							"name": "garbage",
							"diamonds": 14
						},
						"8": {
							"name": "repairBoat",
							"diamonds": 14
						},
						"9": {
							"name": "desk",
							"diamonds": 14
						},
						"10": {
							"name": "seaweed",
							"diamonds": 17
						},
						"11": {
							"name": "paintBoat",
							"diamonds": 17
						},
						"12": {
							"name": "roof",
							"diamonds": 17
						},
						"13": {
							"name": "decoration",
							"diamonds": 20
						},
						"14": {
							"name": "stone",
							"diamonds": 20
						},
						"15": {
							"name": "decorationInside",
							"diamonds": 20
						},
						"16": {
							"name": "decoratePier",
							"diamonds": 24
						},
						"17": {
							"name": "track",
							"diamonds": 24
						},
						"18": {
							"name": "decorationOutside",
							"diamonds": 24
						},
						"19": {
							"name": "lilies",
							"diamonds": 27
						},
						"20": {
							"name": "lanterns",
							"diamonds": 27
						},
						"21": {
							"name": "chairs",
							"diamonds": 27
						},
						"22": {
							"name": "swimmingCircles",
							"diamonds": 30
						},
						"23": {
							"name": "flowerbed",
							"diamonds": 30
						},
						"24": {
							"name": "carpet",
							"diamonds": 30
						},
						"25": {
							"name": "garland",
							"diamonds": 42
						}
					}
				}
			}
		},
		"2": {
			"name": "mountain",
			"parts": {
				"1": {
					"name": "hotel",
					"rewards": {
						"8": {
							"coins": 30
						},
						"16": {
							"coins": 65
						},
						"25": {
							"coins": 130
						}
					},
					"actions": {
						"1": {
							"name": "garbage",
							"diamonds": 5
						},
						"2": {
							"name": "bridge",
							"diamonds": 5
						},
						"3": {
							"name": "hedgehog",
							"diamonds": 5
						},
						"4": {
							"name": "removePaintFirst",
							"diamonds": 8
						},
						"5": {
							"name": "trash",
							"diamonds": 8
						},
						"6": {
							"name": "removePaintSecond",
							"diamonds": 8
						},
						"7": {
							"name": "ladder",
							"diamonds": 11
						},
						"8": {
							"name": "railings",
							"diamonds": 11
						},
						"9": {
							"name": "windows",
							"diamonds": 11
						},
						"10": {
							"name": "door",
							"diamonds": 14
						},
						"11": {
							"name": "web",
							"diamonds": 14
						},
						"12": {
							"name": "fence",
							"diamonds": 14
						},
						"13": {
							"name": "grass",
							"diamonds": 16
						},
						"14": {
							"name": "signboard",
							"diamonds": 16
						},
						"15": {
							"name": "roofGrass",
							"diamonds": 16
						},
						"16": {
							"name": "roofLights",
							"diamonds": 19
						},
						"17": {
							"name": "carpet",
							"diamonds": 19
						},
						"18": {
							"name": "tablet",
							"diamonds": 19
						},
						"19": {
							"name": "balconyFlower",
							"diamonds": 22
						},
						"20": {
							"name": "doorDecor",
							"diamonds": 22
						},
						"21": {
							"name": "bench",
							"diamonds": 22
						},
						"22": {
							"name": "swing",
							"diamonds": 25
						},
						"23": {
							"name": "lights",
							"diamonds": 25
						},
						"24": {
							"name": "flower",
							"diamonds": 25
						},
						"25": {
							"name": "lantern",
							"diamonds": 25
						}
					}
				},
				"2": {
					"name": "observationDeck",
					"rewards": {
						"8": {
							"coins": 55
						},
						"16": {
							"coins": 105
						},
						"25": {
							"coins": 215
						}
					},
					"actions": {
						"1": {
							"name": "garbage",
							"diamonds": 8
						},
						"2": {
							"name": "paint",
							"diamonds": 8
						},
						"3": {
							"name": "ladder",
							"diamonds": 8
						},
						"4": {
							"name": "pit",
							"diamonds": 13
						},
						"5": {
							"name": "leaf",
							"diamonds": 13
						},
						"6": {
							"name": "stoneFirst",
							"diamonds": 13
						},
						"7": {
							"name": "board",
							"diamonds": 18
						},
						"8": {
							"name": "branches",
							"diamonds": 18
						},
						"9": {
							"name": "beam",
							"diamonds": 18
						},
						"10": {
							"name": "stoneSecond",
							"diamonds": 23
						},
						"11": {
							"name": "floor",
							"diamonds": 23
						},
						"12": {
							"name": "fence",
							"diamonds": 23
						},
						"13": {
							"name": "pillar",
							"diamonds": 27
						},
						"14": {
							"name": "bushes",
							"diamonds": 27
						},
						"15": {
							"name": "stand",
							"diamonds": 27
						},
						"16": {
							"name": "map",
							"diamonds": 32
						},
						"17": {
							"name": "binoculars",
							"diamonds": 32
						},
						"18": {
							"name": "bench",
							"diamonds": 32
						},
						"19": {
							"name": "carpetFlower",
							"diamonds": 37
						},
						"20": {
							"name": "parrot",
							"diamonds": 37
						},
						"21": {
							"name": "lanternFirst",
							"diamonds": 37
						},
						"22": {
							"name": "flag",
							"diamonds": 42
						},
						"23": {
							"name": "pillow",
							"diamonds": 42
						},
						"24": {
							"name": "lanternSecond",
							"diamonds": 42
						},
						"25": {
							"name": "squirrel",
							"diamonds": 42
						}
					}
				},
				"3": {
					"name": "terrace",
					"rewards": {
						"8": {
							"coins": 80
						},
						"16": {
							"coins": 160
						},
						"25": {
							"coins": 320
						}
					},
					"actions": {
						"1": {
							"name": "slime",
							"diamonds": 11
						},
						"2": {
							"name": "garbage",
							"diamonds": 11
						},
						"3": {
							"name": "hole",
							"diamonds": 11
						},
						"4": {
							"name": "leaf",
							"diamonds": 19
						},
						"5": {
							"name": "web",
							"diamonds": 19
						},
						"6": {
							"name": "graffiti",
							"diamonds": 19
						},
						"7": {
							"name": "fence",
							"diamonds": 26
						},
						"8": {
							"name": "podium",
							"diamonds": 26
						},
						"9": {
							"name": "barCounter",
							"diamonds": 26
						},
						"10": {
							"name": "tableFirst",
							"diamonds": 34
						},
						"11": {
							"name": "tableSecond",
							"diamonds": 34
						},
						"12": {
							"name": "sofa",
							"diamonds": 34
						},
						"13": {
							"name": "flower",
							"diamonds": 41
						},
						"14": {
							"name": "tableBig",
							"diamonds": 41
						},
						"15": {
							"name": "chairs",
							"diamonds": 41
						},
						"16": {
							"name": "fridge",
							"diamonds": 49
						},
						"17": {
							"name": "wall",
							"diamonds": 49
						},
						"18": {
							"name": "photo",
							"diamonds": 49
						},
						"19": {
							"name": "poster",
							"diamonds": 56
						},
						"20": {
							"name": "fenceLight",
							"diamonds": 56
						},
						"21": {
							"name": "flambeau",
							"diamonds": 56
						},
						"22": {
							"name": "menu",
							"diamonds": 64
						},
						"23": {
							"name": "cocktails",
							"diamonds": 64
						},
						"24": {
							"name": "guitar",
							"diamonds": 64
						},
						"25": {
							"name": "exhibition",
							"diamonds": 64
						}
					}
				},
				"4": {
					"name": "cafe",
					"rewards": {
						"8": {
							"coins": 120
						},
						"16": {
							"coins": 240
						},
						"25": {
							"coins": 480
						}
					},
					"actions": {
						"1": {
							"name": "garbage",
							"diamonds": 16
						},
						"2": {
							"name": "removeLadder",
							"diamonds": 16
						},
						"3": {
							"name": "overgrown",
							"diamonds": 16
						},
						"4": {
							"name": "board",
							"diamonds": 27
						},
						"5": {
							"name": "tile",
							"diamonds": 27
						},
						"6": {
							"name": "wall",
							"diamonds": 27
						},
						"7": {
							"name": "road",
							"diamonds": 39
						},
						"8": {
							"name": "platform",
							"diamonds": 39
						},
						"9": {
							"name": "platformLadder",
							"diamonds": 39
						},
						"10": {
							"name": "mountainLadder",
							"diamonds": 50
						},
						"11": {
							"name": "barrel",
							"diamonds": 50
						},
						"12": {
							"name": "pillar",
							"diamonds": 50
						},
						"13": {
							"name": "table",
							"diamonds": 62
						},
						"14": {
							"name": "tree",
							"diamonds": 62
						},
						"15": {
							"name": "bushes",
							"diamonds": 62
						},
						"16": {
							"name": "armchair",
							"diamonds": 74
						},
						"17": {
							"name": "setTable",
							"diamonds": 74
						},
						"18": {
							"name": "carpet",
							"diamonds": 74
						},
						"19": {
							"name": "treeLight",
							"diamonds": 85
						},
						"20": {
							"name": "parrot",
							"diamonds": 85
						},
						"21": {
							"name": "barbecue",
							"diamonds": 85
						},
						"22": {
							"name": "chair",
							"diamonds": 97
						},
						"23": {
							"name": "signboard",
							"diamonds": 97
						},
						"24": {
							"name": "menu",
							"diamonds": 97
						},
						"25": {
							"name": "poster",
							"diamonds": 97
						}
					}
				}
			}
		},
		"3": {
			"name": "underground",
			"parts": {
				"1": {
					"name": "workshop",
					"rewards": {
						"8": {
							"coins": 55
						},
						"16": {
							"coins": 105
						},
						"26": {
							"coins": 215
						}
					},
					"actions": {
						"1": {
							"name": "garbage",
							"diamonds": 8
						},
						"2": {
							"name": "stairs",
							"diamonds": 8
						},
						"3": {
							"name": "drawer",
							"diamonds": 8
						},
						"4": {
							"name": "hole",
							"diamonds": 8
						},
						"5": {
							"name": "boxes",
							"diamonds": 13
						},
						"6": {
							"name": "wall",
							"diamonds": 13
						},
						"7": {
							"name": "poster",
							"diamonds": 13
						},
						"8": {
							"name": "battery",
							"diamonds": 18
						},
						"9": {
							"name": "crystall",
							"diamonds": 18
						},
						"10": {
							"name": "roofWindow",
							"diamonds": 18
						},
						"11": {
							"name": "books",
							"diamonds": 23
						},
						"12": {
							"name": "tablet",
							"diamonds": 23
						},
						"13": {
							"name": "tools",
							"diamonds": 23
						},
						"14": {
							"name": "plans",
							"diamonds": 27
						},
						"15": {
							"name": "table",
							"diamonds": 27
						},
						"16": {
							"name": "startDrawing",
							"diamonds": 27
						},
						"17": {
							"name": "robot",
							"diamonds": 32
						},
						"18": {
							"name": "curtain",
							"diamonds": 32
						},
						"19": {
							"name": "road",
							"diamonds": 32
						},
						"20": {
							"name": "bigTable",
							"diamonds": 37
						},
						"21": {
							"name": "lamp",
							"diamonds": 37
						},
						"22": {
							"name": "tech",
							"diamonds": 37
						},
						"23": {
							"name": "carpet",
							"diamonds": 42
						},
						"24": {
							"name": "chair",
							"diamonds": 42
						},
						"25": {
							"name": "continueDrawing",
							"diamonds": 42
						},
						"26": {
							"name": "board",
							"diamonds": 25
						}
					}
				},
				"2": {
					"name": "laboratory",
					"rewards": {
						"8": {
							"coins": 75
						},
						"16": {
							"coins": 145
						},
						"26": {
							"coins": 295
						}
					},
					"actions": {
						"1": {
							"name": "roof",
							"diamonds": 10
						},
						"2": {
							"name": "facade",
							"diamonds": 10
						},
						"3": {
							"name": "window",
							"diamonds": 10
						},
						"4": {
							"name": "tiles",
							"diamonds": 10
						},
						"5": {
							"name": "door",
							"diamonds": 17
						},
						"6": {
							"name": "crystals",
							"diamonds": 17
						},
						"7": {
							"name": "wallLantern",
							"diamonds": 17
						},
						"8": {
							"name": "board",
							"diamonds": 24
						},
						"9": {
							"name": "glass",
							"diamonds": 24
						},
						"10": {
							"name": "glassFrame",
							"diamonds": 24
						},
						"11": {
							"name": "floorTiles",
							"diamonds": 31
						},
						"12": {
							"name": "desktop",
							"diamonds": 31
						},
						"13": {
							"name": "additionalWardrobe",
							"diamonds": 31
						},
						"14": {
							"name": "wardrobe",
							"diamonds": 38
						},
						"15": {
							"name": "reagents",
							"diamonds": 38
						},
						"16": {
							"name": "wardrobeOn",
							"diamonds": 38
						},
						"17": {
							"name": "reactorBody",
							"diamonds": 45
						},
						"18": {
							"name": "reactorGlass",
							"diamonds": 45
						},
						"19": {
							"name": "reactorOn",
							"diamonds": 45
						},
						"20": {
							"name": "robot",
							"diamonds": 52
						},
						"21": {
							"name": "microscope",
							"diamonds": 52
						},
						"22": {
							"name": "monitor",
							"diamonds": 52
						},
						"23": {
							"name": "wiring",
							"diamonds": 59
						},
						"24": {
							"name": "computerPanel",
							"diamonds": 59
						},
						"25": {
							"name": "reboot",
							"diamonds": 59
						},
						"26": {
							"name": "chair",
							"diamonds": 42
						}
					}
				},
				"3": {
					"name": "exhibition",
					"rewards": {
						"8": {
							"coins": 95
						},
						"16": {
							"coins": 195
						},
						"26": {
							"coins": 385
						}
					},
					"actions": {
						"1": {
							"name": "sand",
							"diamonds": 13
						},
						"2": {
							"name": "paving",
							"diamonds": 13
						},
						"3": {
							"name": "stairs",
							"diamonds": 13
						},
						"4": {
							"name": "rocks",
							"diamonds": 13
						},
						"5": {
							"name": "branches",
							"diamonds": 22
						},
						"6": {
							"name": "removeFence",
							"diamonds": 22
						},
						"7": {
							"name": "boxes",
							"diamonds": 22
						},
						"8": {
							"name": "carpet",
							"diamonds": 31
						},
						"9": {
							"name": "newFence",
							"diamonds": 31
						},
						"10": {
							"name": "secondBoard",
							"diamonds": 31
						},
						"11": {
							"name": "table",
							"diamonds": 40
						},
						"12": {
							"name": "coasters",
							"diamonds": 40
						},
						"13": {
							"name": "crystalsFirst",
							"diamonds": 40
						},
						"14": {
							"name": "printSign",
							"diamonds": 50
						},
						"15": {
							"name": "glass",
							"diamonds": 50
						},
						"16": {
							"name": "crystalsSecond",
							"diamonds": 50
						},
						"17": {
							"name": "exhibitsAround",
							"diamonds": 59
						},
						"18": {
							"name": "exhibitsCenter",
							"diamonds": 59
						},
						"19": {
							"name": "garland",
							"diamonds": 59
						},
						"20": {
							"name": "arch",
							"diamonds": 68
						},
						"21": {
							"name": "archFlower",
							"diamonds": 68
						},
						"22": {
							"name": "sign",
							"diamonds": 68
						},
						"23": {
							"name": "firstBoard",
							"diamonds": 77
						},
						"24": {
							"name": "boardInfo",
							"diamonds": 77
						},
						"25": {
							"name": "flowers",
							"diamonds": 77
						},
						"26": {
							"name": "pointer",
							"diamonds": 64
						}
					}
				},
				"4": {
					"name": "library",
					"rewards": {
						"8": {
							"coins": 120
						},
						"16": {
							"coins": 240
						},
						"26": {
							"coins": 480
						}
					},
					"actions": {
						"1": {
							"name": "snake",
							"diamonds": 16
						},
						"2": {
							"name": "trashFirst",
							"diamonds": 16
						},
						"3": {
							"name": "book",
							"diamonds": 16
						},
						"4": {
							"name": "trashSecond",
							"diamonds": 16
						},
						"5": {
							"name": "rock",
							"diamonds": 27
						},
						"6": {
							"name": "trashThird",
							"diamonds": 27
						},
						"7": {
							"name": "clearGlobe",
							"diamonds": 27
						},
						"8": {
							"name": "fenceGarbage",
							"diamonds": 39
						},
						"9": {
							"name": "liana",
							"diamonds": 39
						},
						"10": {
							"name": "hole",
							"diamonds": 39
						},
						"11": {
							"name": "roofLiana",
							"diamonds": 50
						},
						"12": {
							"name": "web",
							"diamonds": 50
						},
						"13": {
							"name": "removeBoards",
							"diamonds": 50
						},
						"14": {
							"name": "window",
							"diamonds": 62
						},
						"15": {
							"name": "globeBorder",
							"diamonds": 62
						},
						"16": {
							"name": "fence",
							"diamonds": 62
						},
						"17": {
							"name": "stoneSteps",
							"diamonds": 74
						},
						"18": {
							"name": "table",
							"diamonds": 74
						},
						"19": {
							"name": "railing",
							"diamonds": 74
						},
						"20": {
							"name": "noticeBoard",
							"diamonds": 85
						},
						"21": {
							"name": "lantern",
							"diamonds": 85
						},
						"22": {
							"name": "infoBoard",
							"diamonds": 85
						},
						"23": {
							"name": "owl",
							"diamonds": 97
						},
						"24": {
							"name": "bibliography",
							"diamonds": 97
						},
						"25": {
							"name": "carpet",
							"diamonds": 97
						},
						"26": {
							"name": "garland",
							"diamonds": 97
						}
					}
				}
			}
		}
	},
	byKeys: {
		web: {
			dev: {
				vk: {
					interstitials: dev_vk_interstitials
				},
				ok: {
					interstitials: dev_vk_interstitials
				},
				yandex: {
					interstitials: dev_vk_interstitials
				}
			},
			release: {
				vk: {
					interstitials: release_vk_interstitials
				},
				ok: {
					interstitials: release_vk_interstitials
				},
				yandex: {
					interstitials: release_vk_interstitials
				}
			}
		}
	}
}