export default {
	tasks: {
		"1": {
			"name": "collect_stars",
			"difficult": {
				"1": {
					"count": 2,
					"items": 10
				},
				"2": {
					"count": 3,
					"items": 20
				},
				"3": {
					"count": 5,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"2": {
			"name": "collect_rating",
			"difficult": {
				"1": {
					"count": 17,
					"items": 10
				},
				"2": {
					"count": 25,
					"items": 20
				},
				"3": {
					"count": 38,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"5": {
			"name": "use_fortune_or_lottery",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "lobby"
		},
		"8": {
			"name": "find_all_differences_on_image",
			"difficult": {
				"1": {
					"count": 10,
					"items": 10
				},
				"2": {
					"count": 15,
					"items": 20
				},
				"3": {
					"count": 23,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"9": {
			"name": "use_hint_or_timer",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"10": {
			"name": "get_daily_bonus_some_times_in_a_row",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			}
		},
		"11": {
			"name": "complete_daily_task",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "dailyQuests"
		},
		"13": {
			"name": "enter_top_in_league",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10,
					"place": 50
				},
				"2": {
					"count": 1,
					"items": 20,
					"place": 10
				},
				"3": {
					"count": 1,
					"items": 30,
					"place": 3
				}
			},
			"popup": "leaderboard"
		},
		"17": {
			"name": "use_smilies",
			"difficult": {
				"1": {
					"count": 2,
					"items": 10
				},
				"2": {
					"count": 3,
					"items": 20
				},
				"3": {
					"count": 5,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"18": {
			"name": "find_puzzles",
			"difficult": {
				"1": {
					"count": 8,
					"items": 10
				},
				"2": {
					"count": 12,
					"items": 20
				},
				"3": {
					"count": 18,
					"items": 30
				}
			},
			"popup": "dailyQuests"
		},
		"22": {
			"name": "use_stars",
			"difficult": {
				"1": {
					"count": 2,
					"items": 10
				},
				"2": {
					"count": 3,
					"items": 20
				},
				"3": {
					"count": 5,
					"items": 30
				}
			},
			"popup": "gameTask"
		},
		"27": {
			"name": "recover_objects",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "gameTask"
		},
		"36": {
			"name": "win_games",
			"difficult": {
				"2": {
					"count": 1,
					"items": 20
				},
				"3": {
					"count": 2,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"38": {
			"name": "open_magic_flask",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"39": {
			"name": "fill_moneybox_coins",
			"difficult": {
				"1": {
					"count": 25,
					"items": 10
				}
			},
			"popup": "tournament"
		},
		"42": {
			"name": "send_friends_notifications",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "friends"
		},
		"43": {
			"name": "play_games_tournament",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"44": {
			"name": "play_games_winner",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"45": {
			"name": "gift",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				}
			}
		}
	}
}