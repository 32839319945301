import { Modal, Button, Popover } from "antd"
import * as _ from 'lodash-es'
import ActionsSelector from "./modal/ActionsSelector"
import ActionsTable from "./modal/ActionsTable"
import { useState, useRef } from "react"
import { useForm } from "../AppForm"

export default function ActionsModal({ isModalOpen, handleCancel, updateActionsField }) {
	const mainForm = useForm()
	const [ actionsData, setActionsData ] = useState()
	const actionsField = useRef(_.cloneDeep(mainForm.fields.actions || {}))

	if (actionsData && !actionsField.current[actionsData.part]) {
		actionsField.current[actionsData.part] = {}
	}

	const save = () => {
		updateActionsField(actionsField.current)

		handleCancel()
	}

	const modalTitle = <>
		<Popover content={<>Данное поле actions содержит только действия без учёта локации.<br/>
			При изменении локации в этом окне действия не сбрасываются.<br/>
			Локацию (map) нужно изменять в основной форме.</>}>

			<div className="hinted">Действия на локации (actions)</div>
		</Popover>
	</>

	return <Modal
		className="villaActionsModal"
		width={582}
		destroyOnClose={true}
		transitionName=""
		title={modalTitle}
		open={isModalOpen}
		footer=""
		onCancel={handleCancel}>		

		<ActionsSelector onChange={data => { setActionsData(data) }}/>

		{ actionsData && (
			<ActionsTable
				key={`${ actionsData.location }${ actionsData.part }`}
				actionsData={actionsData}
				actionsField={actionsField.current[actionsData.part]}
			/>
		)}

		<Button
			type="primary"
			size="large"
			disabled={!actionsData}
			onClick={save}
			style={{ marginTop: 30, marginBottom: -10 }}
		>
			Применить изменения
		</Button>
	</Modal>
}