import * as _ from 'lodash-es'
import FeatherIcon from "feather-icons-react/build/FeatherIcon"

import { useParams, Link } from "react-router-dom"
import { useAuth } from "../../provider/AuthProvider"
import pagesConfig from "../config/pagesConfig"

export default function PagesLinks({ appsLink, currentPage }) {
	const urlData = useParams()
	const { user } = useAuth()
	const { appID, userID } = urlData
	const excludePaths = [ 'home' ]

	const links = [
		appsLink
	]

	_.forEach(pagesConfig, (pageData, pageName) => {
		// Есть разрешение
		if (!_.has(pageData, 'permission') || user.hasPermission(pageData.permission)) {
			if (!_.includes(excludePaths, pageName)) {
				if (pageName === 'reviews') {
					if (appID && userID) {
						const appPath = appID.replace(/_/g, '.')
				
						const appData = _.get(user.apps.byKeys, appPath)
						
						if (appData && appData.reviews === true) {
							const reviewsLink = <Link to={`/reviews/${ appID }/${ userID }`} className="link" key="reviews">Отзывы</Link>
				
							links.unshift(reviewsLink)
						}
					}
				}
				else {
					const link = <Link to={`/${ pageName }`} className={`link ${ currentPage === pageName ? 'active' : '' }`} key={pageName}>
						<FeatherIcon size="19" icon={pageData.icon}/>
						{ pageData.title }
					</Link>

					links.push(link)
				}
			}
		}
	})

	return (
		<div className="pagesLinks">
			{ _.map(links, (link, index) => <div key={index}>{ link }</div>) }
		</div>
	)
}