import { Button } from 'antd'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import { useState } from 'react'
import SalesModal from './SalesModal'

export default function Sales({ setFields }) {
	const [ isModalOpen, setIsModalOpen ] = useState(false)
	const showModal = () => { setIsModalOpen(true) }
	const handleCancel = () => { setIsModalOpen(false) }

	return (
		<>
			<Button type="primary" className="button" onClick={showModal}>
				<FeatherIcon icon="star" size={19}/>
				Акции
			</Button>

			{ isModalOpen && <SalesModal isModalOpen={isModalOpen} handleCancel={handleCancel}/> }
		</>
	)
}