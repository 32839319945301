import * as _ from 'lodash-es'
import { useState, useRef } from 'react'
import { useImmer } from 'use-immer'
import { Input, Table, Select } from 'antd'

import { useAuth } from '../../../provider/AuthProvider'
import FormField from '../../form/FormField'
import AppConfig from '../../config/AppConfig'
import zebomba from '../../../tools/zebomba'
import messagesConfig from './messagesConfig'
import Button from '../../UI/Button'

export default function SetGift({ data }) {
	const [ isRequestInProgress, setIsRequestInProgress ] = useState(false)
	const historyTableData = useRef({ needUpdate: true })
	const [ formData, setFormData ] = useImmer({})

	const { user } = useAuth()

	document.title = data.title

	if (historyTableData.current.needUpdate) {
		historyTableData.current.needUpdate = false

		const params = {
			id: user.id,
			access_token: user.token
		}

		const getGiftHistory = async () => {
			setIsRequestInProgress(true)

			const response = await fetch(`${ AppConfig.api.getGiftHistory }?${ new URLSearchParams(params) }`)
			const data = await response.json()

			setIsRequestInProgress(false)

			const history = data.gift_history

			if (history) {
				if (history.length) {
					let rows = _.map(history, item => {
						return {
							id: item.id,
							user_id: item.user_id,
							create_time: item.create_time,
							project_id: item.project_id,
							coins: item.coins,
							player_id: item.player_id,
							type: item.type,
							message_id: item.message_id
						}
					})
	
					historyTableData.current = { rows }
				}
			}
			else if (data.error) {
				zebomba.message.error(data.error)
			}
			else {
				zebomba.message.error('Что-то пошло не так...')
			}
		}

		getGiftHistory()
	}

	let historyTable

	if (historyTableData.current.rows) {
		const columns = [
			{
				title: 'ID',
				key: 'id',
				dataIndex: 'id',
				width: 70,
				align: 'center',
				sorter: (a, b) => a.id - b.id,
			},
			{
				title: 'ID пользователя',
				key: 'user_id',
				dataIndex: 'user_id',
				align: 'center',
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
					<Input
						autoFocus
						placeholder='Поиск...'
						onChange={e => {
							setSelectedKeys([ e.target.value.trim() ])
							confirm({ closeDropdown: false })
						}}
					/>),
				onFilter: (value, record) => _.startsWith(record.user_id, value)
			},
			{
				title: 'Дата создания',
				key: 'create_time',
				dataIndex: 'create_time',
				sorter: (a, b) => Date.parse(a.create_time) - Date.parse(b.create_time),
				defaultSortOrder: 'descend'
			},
			{
				title: 'ID проекта',
				key: 'project_id',
				dataIndex: 'project_id',
				align: 'center',
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
					return <Input
						autoFocus
						placeholder='Поиск...'
						onChange={e => {
							setSelectedKeys([ e.target.value.trim() ])
							confirm({ closeDropdown: false })
						}}
						/>
				},
				onFilter: (value, record) => record.project_id?.includes(value)
			},
			{
				title: 'Монеты',
				key: 'coins',
				dataIndex: 'coins',
				align: 'center',
				sorter: (a, b) => a.coins > b.coins,
			},
			{
				title: 'ID игрока',
				key: 'player_id',
				dataIndex: 'player_id',
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
					return <Input
						autoFocus
						placeholder='Поиск...'
						onChange={e => {
							setSelectedKeys([ e.target.value.trim() ])
							confirm({ closeDropdown: false })
						}}
						/>
				},
				onFilter: (value, record) => _.startsWith(record.player_id, value)
			},
			{
				title: 'Тип',
				key: 'type',
				dataIndex: 'type',
				align: 'center'
			},
			{
				title: 'ID сообщения',
				key: 'message_id',
				dataIndex: 'message_id',
				filterSearch: true,
				align: 'center',
				filters: _.map(messagesConfig, (item, index) => ({ text: index, value: index })),
				onFilter: (value, record) => record.message_id?.includes(value),
			}
		]

		historyTable = <Table
			size='small'
			pagination={{ pageSize: 13 }}
			dataSource={historyTableData.current.rows}
			columns={columns}></Table>
	}

	const isSetBtnDisabled = !(formData.appID && formData.usersIDs && formData.giftType && formData.giftValue)

	const giftTypes = {
		'coins': 'Монеты',
		'potions': 'Зелья',
		'crystals': 'Кристаллы',
		'hero_scrolls': 'Свитки героев',
		'totem_spheres': 'Сферы тотемов'
	}

	const onSetBtnClick = async () => {
		setIsRequestInProgress(true)

		const params = _.assign({
			id: user.id,
			access_token: user.token,
			message_id: formData.giftMessage,
			amount: formData.giftValue,
			user_ids: formData.usersIDs,
			type: formData.giftType
		}, user.apps.byName[formData.appID].dataForRequest)

		const response = await fetch(AppConfig.api.setGift, {
			method: 'POST',
			body: new URLSearchParams(params)
		})

		const data = await response.json()
		
		setIsRequestInProgress(false)

		historyTableData.current.needUpdate = true

		if (data.success === true) {
			zebomba.message.success('Подарок успешно начислен')
		}
		else if (data.error) {
			zebomba.message.error(data.error)
		}
		else {
			zebomba.message.error('Что-то пошло не так...')
		}
	}

	return (
		<div className="content">
			<div className="setGiftForm form">
				<div className="title">Начисление подарка</div>

				<FormField
					data={{ name: 'appID', label: 'Приложение', type: 'select', options: user.apps.allNamesList, hideIndex: true }}
					onFieldChange={field => { setFormData(data => { data.appID = field.config.options[field.value] }) }} />

				<div className='formFieldItem field_select'>
					<label className="fieldLabel">ID пользователей</label>

					<Select
						open={false}
						tokenSeparators={[',']}
						mode="tags"
						onChange={list => setFormData(data => { data.usersIDs = list.join(',') })}
					/>
				</div>

				<FormField
					data={{ name: 'giftType', label: 'Тип подарка', type: 'select', options: giftTypes, hideIndex: true }}
					onFieldChange={field => { setFormData(data => { data.giftType = field.value }) }}/>

				<FormField
					data={{ name: 'giftMessage', label: 'Сообщение', type: 'select', options: messagesConfig }}
					onFieldChange={field => { setFormData(data => { data.giftMessage = field.value }) }}/>

				<FormField
					data={{ name: 'giftValue', label: 'Количество', type: 'number' }}
					onFieldChange={field => { setFormData(data => { data.giftValue = field.value }) }}
					defaultValue={formData.giftValue}/>

				<Button
					className='setBtn'
					disabled={isSetBtnDisabled}
					wait={isRequestInProgress}
					onClick={onSetBtnClick}
				>
						Начислить
				</Button>
			</div>

			{ historyTable }
		</div>
	)
}