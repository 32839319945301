import * as _ from 'lodash-es'
import { useAuth } from "../../provider/AuthProvider"
import appsConfig from '../../configs/apps/config'
import AppLinks from './AppLinks'

export default function AppLink({ appName, config }) {
	const { user } = useAuth()
	
	const platforms = user.apps.byKeys[appName]

	if (platforms) {
		return (
			<div className={`item ${ appName }`}>
				<div className="titleName">
					<div className="projectTitle">{ appsConfig[appName].title }</div>
					<div className="projectName">{ appName }</div>
				</div>

				<div className="linksBlock">
					<div className="projectName">{ appName }</div>

					<div className="platforms">
					{
						_.map(platforms, (data, platformName) => (
							<Platform
								key={ platformName }
								data={{
									platformName,
									versions: config.links[platformName],
									appName
								}} />)
						)
					}
					</div>
				</div>
			</div>
		)
	}
}

function Platform({ data }) {
	const { user } = useAuth()

	const { platformName, appName } = data,
		linkHref = `${ appName }_${ platformName }`

	const serverVersions = user.apps.byKeys[data.appName][platformName]

	let versions = data.versions

	if (platformName === 'mobile') {
		versions = serverVersions
	}

	let platformTitle = platformName === 'web' ? 'Web-HTML' : 'Mobile-Unity'

	if (platformName === 'ios') {
		platformTitle = 'iOS'
	}
	else if (platformName !== 'web' && appsConfig[appName].hasCordova) {
		platformTitle = 'Mobile-Cordova'
	}
	else if (platformName === 'web' && appName === 'fish') {
		platformTitle = 'Web-COCOS'
	}
	else if (_.includes([ 'cargo', 'water', 'alien', 'uw' ], appName) && platformName === 'web') {
		platformTitle = 'Unity-Web'
	}

	return (
		<div className={`platform ${ platformName }`}>
		<div className="title">{ platformTitle }</div>

		<div className="versions">
		{
			_.map(versions, (socials, versionName) => {
				const serverData = serverVersions[versionName]
				
				return serverData && (
					<Version
						key={ versionName }
						data={{ appName, serverData, versionName, socials, linkHref }}
					/>
				)
			})
		}
		</div>
	</div>
	)
}

function Version({ data }) {
	return (
		<div className={`versionsItem ${ data.versionName }`}>
			<div className="title">{ data.versionName }</div>

			<div className="links">{ <AppLinks data={data}/> }</div>
		</div>
	)
}