export default {
	1: {
		//Наименование
		'name': 'angry',
		//Смайл по умолчанию
		'default': 1,
		//Тип эмоции (звук)
		'type': 'angry'			
	},
	2: {
		'name': 'sad',
		'default': 1,			
		'type': 'sad'			
	},
	3: {
		'name': 'boring',
		'default': 1,	
		'type': 'yawn'			
	},
	4: {
		'name': 'laugh',
		'default': 1,	
		'type': 'joy'			
	},
	5: {
		'name': 'bpass_hello',
		'rarity': 'rare',
		'type': 'joy'
	},
	6: {
		'name': 'bpass_cool',
		'rarity': 'common',
		'type': 'smile-m'
	},
	7: {
		'name': 'bpass_devil',
		'rarity': 'epic',
		'type': 'smile-m'
	},
	8: {
		'name': 'bpass_question',
		'rarity': 'rare',
		'type': 'surprise'
	},
	9: {
		'name': 'bpass_thinking',
		'rarity': 'epic',
		'type': 'surprise'
	},
	10: {
		'name': 'bpass_angry',
		'rarity': 'common',
		'type': 'angry'
	},
	11: {
		'name': 'bpass_bomb',
		'rarity': 'legendary',
		'type': 'smile-f'
	},
	12: {
		'name': 'bpass_time',
		'rarity': 'rare',
		'type': 'angry'
	},
	13: {
		'name': 'bpass_nice',
		'rarity': 'common',
		'type': 'joy'
	},
	14: {
		'name': 'bpass_applause',
		'rarity': 'epic',
		'type': 'joy'
	}
}