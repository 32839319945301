// eslint-disable-next-line import/no-anonymous-default-export
export default {
	home: {
		title: 'Система тестирования'
	},
	reviews: {
		title: 'Отзывы'
	},
	copy: {
		title: 'Копирование',
		icon: 'copy'
	},
	coinsStats: {
		permission: 'coins_history',
		title: 'Расход монет',
		icon: 'file-text'
	},
	payments: {
		permission: 'payments',
		title: 'Платежи',
		icon: 'credit-card'
	},
	linksGenerator: {
		permission: 'profiles_links',
		title: '1000 ОК',
		icon: 'link'
	},
	setGift: {
		permission: 'gift',
		title: 'Подарок',
		icon: 'gift'
	},
	infoPopups: {
		title: 'Инфо-окна',
		icon: 'info',
		permission: 'information_window'
	},
	reviewsErrors: {
		permission: 'reviews',
		title: 'Сообщения',
		icon: 'mail'
	},
	setCrystals: {
		permission: 'crystals',
		title: 'Кристаллы',
		icon: 'gift'
	},
	dictionary: {
		title: 'Словарь',
		icon: 'book',
		permission: 'dictionary'
	},
	addition: {
		title: 'Добавление',
		icon: 'plus-square',
		permission: 'profile_addition',
	},
	devtools: {
		title: 'dev tools',
		icon: 'book',
		permission: 'devtools'
	}
}