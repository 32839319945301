import * as _ from 'lodash-es'
import { Modal, Spin } from 'antd'
import { useAuth } from '../../../provider/AuthProvider'
import AppConfig from '../../config/AppConfig'
import { useAppData } from '../../pages/Project'
import { useImmer } from 'use-immer'
import zebomba from '../../../tools/zebomba'
import AceEditor from 'react-ace'
import 'ace-builds/src-min-noconflict/mode-json'
import 'ace-builds/webpack-resolver'

export default function PreviewConfigModal({ isOpened, handleCancel, configName, sectionTitle }) {
	const { user } = useAuth()
	const app = useAppData()
	const [ configData, setConfigData ] = useImmer()

	const getConfig = async () => {
		const params = _.assign({
			access_token: user.token,
			id: user.id,
			config_name: configName
		}, user.apps.byName[app.config.appID].dataForRequest)

		const response = await fetch(`${ AppConfig.api.getConfig }?${ new URLSearchParams(params) }`)

		const data = await response.json()

		if (isOpened) {
			const responseConfigName = configName.split('_').map(word => _.capitalize(word)).join('')
			const config = data[responseConfigName]

			if (!config) {
				zebomba.message.error(`Не найден конфиг "${ responseConfigName }" в ответе запроса "get_config"`)
			}
			else if (!_.isEmpty(config)) {
				setConfigData(config.config)
			}
			else if (data.error) {
				zebomba.message.error(data)
			}
		}
	}

	if (!configData) {
		getConfig()
			.catch(e => { zebomba.message.error(e) })
	}


	return (
		<Modal
			open
			width={600}
			destroyOnClose={true}
			transitionName=""
			footer=""
			title={`Конфиг ${ configName } секции "${ sectionTitle }"`}
			onCancel={handleCancel}
		>

			{ !configData && (
				<Spin size="large" tip="get_config" style={{ position: 'relative', top: 10, height: 120 }}>
					<div className="content"/>
				</Spin>
			)}

			{ configData && (
				<AceEditor
					fontSize={17}
					mode="json"
					defaultValue={JSON.stringify(configData, null, 3)}
					setOptions={{
						useWorker: false
					}}
					editorProps={{ $blockScrolling: true }}
					style={{
						border: '1px solid #ccc'
					}}
					width="100%"
					readOnly
				/>
			)}

		</Modal>
	)
}