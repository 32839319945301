import config from '../../configs/apps/config'
import * as _ from 'lodash-es'
import FeaturesPointsList from '../features/FeaturesPointsList'

export default function FeaturesPoints({ data }) {
	document.title = data.title

	return <div className="featuresPoints">
		<div className="infoMessage info">Точки фичей могут отличаться для каждой игры, её соц. сети и версии (дев, релиз).<br/>Текущие точки взяты из дев версии ВК.</div>

		{ _.map(config, app => <FeaturesPointsList key={app.name} app={app}/>) }
	</div>
}