import { Modal, Radio } from 'antd'
import * as _ from 'lodash-es'
import { useState } from 'react'

export default function InterstitialAdModal({ appID, interstitials, isModalOpen, handleCancel }) {
	const defaultSelectedAd = _.keys(interstitials)[0]
	const [ selectedAd, setSelectedAd ] = useState(defaultSelectedAd)

	const adConfig = {
		loadingScreen: {
			title: 'Загрузочный экран',
			info: 'Показывается в конце загрузочного экрана.'
		},
		beforeBattle: {
			title: 'Перед боем',
			info: 'Показывается перед началом боя, после нажатия на кнопку "Играть".'
		},
		afterBattleRound: {
			title: 'После раунда',
			info: 'Показывается в конце раунда, когда найдено последнее отличие.'
		},
		afterBattle: {
			title: 'После боя',
			info: 'Показывается сразу после боя, после закрытия окна победы/поражения.'
		}
	}

	const tabs = _.map(interstitials, (adData, adType) => <Radio.Button key={adType} value={adType}>{ adConfig[adType]?.title }</Radio.Button>)

	const adData = interstitials[selectedAd]

	return (
		<Modal className="interstitialAd" width={532} destroyOnClose={true} transitionName="" title={`Interstitial-реклама для ${ appID }`} open={isModalOpen} footer="" onCancel={handleCancel}>
			<Radio.Group defaultValue={defaultSelectedAd} buttonStyle="solid" onChange={e => setSelectedAd(e.target.value)}>
				{ tabs }
			</Radio.Group>

			<div className="iContent">
				<div className="iTitle">
					{ adConfig[selectedAd]?.title }
					<div className="name">{ selectedAd }</div>
				</div>

				<div className="info">
					<div>{ adConfig[selectedAd]?.info }<br/><br/></div>
					<div>Условия:</div>
					<div>— Выключен блокировщик рекламы</div>
					<div>— соц. сеть: VK, MOBILE, YANDEX, VKPLAY или (ОК и не анонимный)</div>
					<div>— соответствие данных серверному конфигу:</div>

					<div className="serverConfig">
					{
						_.flatMap(adData, (data, key) => {
							if (key === 'probability') {
								return <div key={key} className='item'>
									<div className="title">Вероятность</div>
									<div className="name">{ key }: </div>
									<div className="value">{ data }</div>
								</div>
							}
							
							if (key === 'rules') {
								return _.map(data, (ruleValue, rule) => {
									const ruleTitle = rule === 'map' && 'Локация'
										|| rule === 'point' && 'Точка'
										|| rule === 'no_payment_count' && 'Дней без оплаты'
										|| rule === 'rev' && 'Потрачено валюты'

									return <div key={rule} className='item'>
										<div className="title">{ ruleTitle }</div>
										<div className="name">{ rule }: </div>
										<div className="value">{ ruleValue }</div>
									</div>
								})
							}

							if (key === 'roundsProbability') {
								return <div key={key} className='item'>
									<div className="title">Вероятности раундов</div>
									<div className="name">{ key }: </div>
									<div className="value">{ _.map(data, (value, key) => `${ key }-й: ${value}`).join('; ') }</div>
								</div>
							}
						})
					}
					</div>
				</div>
			</div>
		</Modal>
	)
}