import { useState } from 'react'
import { Button } from 'antd'
import ActionsModal from './ActionsModal'

export default function ActionsField({ onValueChange }) {
	const [ isModalOpen, setIsModalOpen ] = useState(false)
	const showModal = () => { setIsModalOpen(true) }
	const handleCancel = () => { setIsModalOpen(false) }

	return (
		<>
			<Button
				style={{ fontSize: 15 }}
				onClick={showModal}
			>
				Изменить
			</Button>

			{ isModalOpen && (
				<ActionsModal
					isModalOpen={isModalOpen}
					handleCancel={handleCancel}
					updateActionsField={onValueChange}
				/>)
			}
		</>
	)
}