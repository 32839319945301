export default function LoadingSpinner({ className, width, height, position }) {
	const styles = {
		width: '50px',
		height: '50px'
	}

	if (width) {
		styles.width = `${ width }px`
	}

	if (height) {
		styles.height = `${ height }px`
	}

	switch (position) {
		case 'center': {
			styles.margin = '0 auto'
			break
		}
		default: {}
	}

	return <div style={styles} className={ `loadingSpinner ${ className || '' }` }>
		<div className="circle"></div>
	</div>
}