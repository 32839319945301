import { Modal, Button } from 'antd'
import * as _ from 'lodash-es'
import { useImmer } from 'use-immer'
import { useEffect } from 'react'
import { useAuth } from '../../../../provider/AuthProvider'

import CheckboxField from '../../fields/CheckboxField'
import zebomba from '../../../../tools/zebomba'
import RewardsTable from './RewardsTable'

const MAX_LEVEL = 100

export default function BattlePassRewardsModal({ rewards: initialRewards, handleCancel, onChangeHandler, closeModal }) {
	const { storage } = useAuth()

	const rewardsList = _.map(initialRewards, (rewards, level) => new Reward(level, rewards))
	const [ rewards, setRewards ] = useImmer(rewardsList)

	useEffect(() => {
		setRewards(rewardsList)
	// eslint-disable-next-line
	}, [ initialRewards ])

	const applyForAllRewards = (rewardType, value) => {
		const newRewards = _.map(rewards, reward => {
			reward[rewardType] = value

			return reward
		})

		setRewards(newRewards)
	}

	const removeReward = reward => {
		setRewards(rewards => _.without(rewards, reward))
	}

	const applyChanges = (needCloseModal = true) => {
		const value = _.reduce(rewards, (result, reward) => {
			result[reward.level] = [ reward.topReward, reward.bottomReward ]

			return result
		}, {})

		onChangeHandler(value)

		zebomba.message.success('Награды Боевого пропуска изменены')

		needCloseModal && closeModal()
	}

	const addReward = () => {
		const maxLevel = _.maxBy(rewards, reward => +reward.level)?.level || 0

		setRewards(rewards => {
			rewards.push(new Reward(maxLevel + 1))

			return rewards
		})
	}

	const onFillRewardsChange = value => {
		storage.data.userSettings.fillBPRewards = Number(value)

		storage.save()

		applyChanges(false)
	}

	return (
		<Modal
			width={530}
			className="battlePassRewards"
			destroyOnClose={true}
			transitionName=""
			title="Награды Боевого пропуска"
			open={true}
			footer=""
			onCancel={handleCancel}>
				<CheckboxField
					data={{ name: 'fillRewards', label: 'Заполнять автоматически до текущего уровня (куки)', value: storage.data.userSettings.fillBPRewards }}
					defaultValue={storage.data.userSettings.fillBPRewards}
					onValueChange={onFillRewardsChange}/>

				<div className="descr">При изменении уровня БП (battle_pass[0]) награды буду заполнены до этого уровня включительно — будет выставлен статус "Можно взять".<br/></div>

				<div className="max" style={{ marginTop: 20 }}>Максимум уровней: <b>{ MAX_LEVEL }</b></div>

				<RewardsTable
					rewards={rewards}
					setRewards={setRewards}
					addReward={addReward}
					removeReward={removeReward}
					applyForAllRewards={applyForAllRewards}
					maxLevel={MAX_LEVEL}
					/>

				<Button type="primary" style={{ height: 41, fontSize: 15, marginTop: 40 }} onClick={applyChanges}>Применить изменения</Button>
		</Modal>
	)
}

class Reward {
	level
	topReward = 0
	bottomReward = 0
	
	constructor(level, rewards) {
		this.level = Number(level)

		if (rewards) {
			this.topReward = rewards[0]
			this.bottomReward = rewards[1]
		}
	}
}