const dev_vk_interstitials = {
    "loadingScreen": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 2
        }
    },
    "beforeBattle": {
        "probability": 100,
        "rules": {
            "map": 2,
            "point": 2
        }
    },
    "afterBattle": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 5,
            "no_payment_count": 0,
            "rev": 0
        }
    },
    "afterBattleRound": {
        "probability": 100,
        "roundsProbability": {
            "1": 100,
            "2": 100
        },
        "rules": {
            "map": 1,
            "point": 8,
            "no_payment_count": 0,
            "rev": 0
        }
    }
}

export default {
	byKeys: {
		web: {
			dev: {
				vk: {
					interstitials: dev_vk_interstitials
				},
				ok: {
					interstitials: dev_vk_interstitials
				},
				yandex: {
					interstitials: dev_vk_interstitials
				}
			}
		}
	}
}