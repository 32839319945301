import { Fragment } from "react"
import AppsMenu from "../AppsMenu"

export default function Apps() {
	return <Fragment>
		<div className="sectionTitle">Проекты</div>
		<div className="apps">
			<AppsMenu/>
		</div>
	</Fragment>
}