import find from './find.js'
import cat from './cat.js'
import trip from './trip.js'
import alice from './alice.js'
import sweet from './sweet.js'
import villa from './villa.js'
import indi from './indi.js'

export default {
	find,
	cat,
	trip,
	alice,
	sweet,
	villa,
	// house такой же как на Вилле
	house: villa,
	indi
}