import Cookies from "js-cookie"
import AppConfig from "../components/config/AppConfig"
import React, { createContext, useContext, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import User from '../user/User'
import LZString from 'lz-string'
import zebomba from "../tools/zebomba"

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate()

	const { current: storage } = useRef(new Storage())

	const authData = storage.data.authData || {}

    const [ user, setUser ] = useState(new User(authData))

    const tryLogin = async authData => {
		const { login, password } = authData

		const response = await fetch(AppConfig.api.authorize, {
			method: 'POST',
			body: new URLSearchParams({ username: login, password })
		})

		const data = await response.json(),
			{ access_token, id } = data

		if (access_token && id) {
			const userData = {
				id: data.id,
				name: data.name,
				token: data.access_token,
				permissions: data.features,
				availableProjects: data.available_projects,
				ip: data.ip
			}

			storage.data.authData = userData
			storage.save()

			new User(userData)

			setUser(new User(userData))

			navigate('/')
		}
		else {
			zebomba.message.error(data.error)
		}
    }

	const logout = () => {
		delete storage.data.authData

		storage.save()

		setUser({})
	}

    return <AuthContext.Provider value={{ user, tryLogin, logout, storage }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}

class Storage {
	data = {
		userSettings: {}
	}

	constructor() {
		Cookies.remove('zebombaSystem')
		Cookies.remove('zebombaSystemAuthData')
		Cookies.remove('zebombaTesting')

		const zebombaCookie = Cookies.get('zebombaTestSystem')

		if (zebombaCookie) {
			// compress пришёл позже, поэтому первый вариант для тех, у кого старые cookie
			// todo: удалить через какое-то время
			try {
				this.data = JSON.parse(zebombaCookie)
			}
			catch {
				this.data =  JSON.parse(LZString.decompressFromEncodedURIComponent(zebombaCookie))
			}
		}
	}

	save() {
		const data = LZString.compressToEncodedURIComponent(JSON.stringify(this.data, null, 0))

		Cookies.set('zebombaTestSystem', data, { expires: 365 })
	}
}