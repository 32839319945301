import { Fragment, createElement, useReducer } from "react"
import { IMaskInput } from "react-imask"
import * as _ from 'lodash-es'
import { Table, Button } from "antd"
import FeatherIcon from "feather-icons-react/build/FeatherIcon"

import zebomba from "../../../tools/zebomba"
import FieldFactory from "../../appForm/FieldFactory"
import listReducer from "./listReducer"
import AddBox from "./AddBox"

export default function EditListPopup({ data }) {
	const [ list, dispatch ] = useReducer(listReducer, data.list)
	
	// Какое типа каждый айтем
	const itemsReturnType = data.itemsType || 'text'
	// В каком виде представлен каждый айтем
	const itemsType = (data.options && 'select') || itemsReturnType
	const { fieldName } = data

	const onValueChange = (index, value) => dispatch({
		type: 'valueChanged',
		data: { index, value }
	})

	const onIndexChange = (oldIndex, newIndex) => dispatch({
		type: 'indexChanged',
		data: { oldIndex, newIndex }
	})

	const onListAdd = ({index, value}) => dispatch({
		type: 'listItemAdded',
		data: { index, value }
	})

	const deleteItemByIndex = index => dispatch({
		type: 'itemDeletedByIndex',
		data: { index }
	})

	const onSaveBtnClick = () => {
		data.onFieldChange(list)

		zebomba.message.success('Данные успешно изменены')
	}

	const lockIndex = _.isArray(list)

	const getTableColumns = () => {
		let columns = []

		_.forEach(data.columns || [ 'key', 'value' ], (title, index) => {
			title = title === ('key' && 'Ключ') || (title === 'value' && 'Значение') || title
			
			columns.push({
				title,
				key: !index ? 'dataKey' : 'dataValue',
				dataIndex: !index ? 'dataKey' : 'dataValue',
				align: "center"
			})
		})

		columns.push({
			title: '',
			key: 'deleteBtn',
			dataIndex: 'deleteBtn'
		})

		return columns
	}

	const getTableData = () => _.map(list, (item, index) => {
		const indexInputProps = {
			className: 'index',
			mask: Number,
			defaultValue: index,
			disabled: lockIndex
		}

		if (!lockIndex) {
			indexInputProps.onChange = e => onIndexChange(index, e.target.value)
		}

		const fieldProps = {
			name: `${ fieldName }[${ index }]`,
			type: itemsType,
			value: item
		}

		if (data.options) {
			fieldProps.options = data.options
		}

		return {
			dataKey: createElement(IMaskInput, indexInputProps),
			dataValue: (
				<div className="value">
					<FieldFactory
						data={fieldProps}
						onFieldChange={field => onValueChange(index, field.value)}/>
				</div>
			),
			deleteBtn: <Button danger className="deleteBtn" onClick={() => deleteItemByIndex(index)}><FeatherIcon size="19" icon="x"/></Button>
		}
	})

	const table = <Table
		size="small"
		dataSource={getTableData()}
		columns={getTableColumns()}
		pagination={false}
		showHeader/>

	return (
		<Fragment>
			<h4 className="popupTitle">{ fieldName } { `: ${ itemsReturnType === 'number' ? 'Number' : 'String' }${lockIndex ? '[]' : '{}' } {`}</h4>

			<div className="listBox table">
				{ table }
			</div>

			<div className="listBox">
				<AddBox
					key={list.length}
					itemsType={itemsType}
					options={data.options}
					itemsReturnType={itemsReturnType}
					defaultIndex={list.length}
					onListAdd={onListAdd}
					lockIndex={lockIndex}/>
			</div>

			<div className="closingParths">{ '}' }</div>

			<div className="button blue saveBtn" onClick={onSaveBtnClick}>Применить изменения</div>

		</Fragment>
	)
}