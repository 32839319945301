import FieldFactory from "../FieldFactory"
import { useAuth } from "../../../provider/AuthProvider"
import { useRef } from "react"
import * as _ from 'lodash-es'
import RawField from "../fields/RawField"

export default function BattlePassSection({ data, onFieldChange, formFields }) {
	const { storage } = useAuth()
	const prevBPLevel = useRef(formFields.battle_pass && formFields.battle_pass[0])

	const fillRewards = battlePass => {
		const level = battlePass[0]

		if (level > 0) {
			battlePass[3] = _.toPlainObject(new Array(level + 1).fill([1,1]))

			// Без нулевого уровня
			delete battlePass[3][0]
		}
	}

	const onBattlePassChange = field => {
		if (field.value && prevBPLevel.current !== field.value[0]) {
			prevBPLevel.current = field.value[0]

			if (storage.data.userSettings.fillBPRewards) {
				fillRewards(field.value)
			}
		}

		onFieldChange(field)
	}

	const battlePassField = <FieldFactory data={{ name: 'battle_pass' }} onFieldChange={onBattlePassChange}/>

	return (
		<div id={ `section_${ data.name }` } className="section">
			<div className="content">
				<div className="header">
					<div className="top">
						<div className="titleExtended">{ data.title }</div>
						<div className="name">{ data.showName ? data.name : '' }</div>
					</div>

					{
						// Показывать кнопку только если данное поле есть в formFields
						data.showName && _.has(formFields, data.name) && (
							<RawField
								data={data}
								onValueChange={value => {
									const field = {
										name: data.name,
										value
									}

									onFieldChange(field)
								}}
								defaultValue={formFields[data.name]}
							/>
						)
					}
				</div>

				<div className="fields">
					{ battlePassField }
				</div>
			</div>
		</div>
	)
}