import * as _ from 'lodash-es'
import AppConfig from '../../../config/AppConfig'
import zebomba from '../../../../tools/zebomba'

export default function setPromo(app, user, data) {
	const { start_date, end_date, saleID, gameFridayID, stopTime, setPromotions, gameFridayAlt } = data

	const params = {
		access_token: user.token,
		id: user.id,
		user_id: app.config.socialUserID,
		app_name: app.config.appID,
		start_date,
		end_date,
		type: saleID,
		alternative: Number(gameFridayAlt)
	}

	if (gameFridayID) {
		params.promo_id = gameFridayID
	}

	if (stopTime) {
		params.stop_time = Math.floor(stopTime.toDate().getTime() / 1e3)
	}

	const sendRequest = async () => {
		const response = await fetch(AppConfig.api.setPromo, {
			method: 'POST',
			body: new URLSearchParams(params)
		})

		const data = await response.json()

		if (data.success === true) {
			setPromotions(promotions => {
				let promotion = _.find(promotions, promotion => promotion.type === saleID)

				if (!promotion) {
					promotion = {
						type: saleID
					}

					promotions.push(promotion)
				}

				if (gameFridayID) {
					promotion.promo_id = gameFridayID
				}

				promotion.start_date = start_date * 1e3
				promotion.end_date = end_date * 1e3

				zebomba.message.success('Акция успешно обновлена')
			})
		}
		else {
			zebomba.message.error('Что-то пошло не так...')
		}
	}

	sendRequest()
}