import { useState, useEffect } from "react"

export default function CheckboxField({ data, onValueChange, defaultValue }) {
	const [ value, setValue ] = useState(defaultValue)


	useEffect(() => {
		if (value !== defaultValue) {
			setValue(defaultValue)
		}
	// eslint-disable-next-line
	}, [ defaultValue ])

	const onChange = () => {
		setValue(!value)

		onValueChange(Number(!value))
	}

	return (
		<div className="checkboxField" onClick={onChange} data-name={data.name}>
			{ data.label && <div className="title">{ data.label }</div> }
			<label className={ `labelCheck ${ value ? 'checked' : '' }` } onClick={onChange}></label>
		</div>
	)
}