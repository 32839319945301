import React from "react"
import { Navbar } from "../navbar/Navbar"
import Authentication from '../../routes/Authentication'
import AppsMenu from "../navbar/AppsMenu"

const Home = () => {
	document.title = 'Система тестирования'

	return (
		<Authentication>
			<Navbar/>

			<div className="appsMenu home">
				<AppsMenu></AppsMenu>
			</div>
		</Authentication>
	)
}

export default Home
