import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import { Button } from 'antd'
import { useState } from 'react'

import SnapshotsModal from './SnapshotsModal'

export default function Snapshots({ interstitials, appID }) {
	const [ isModalOpen, setIsModalOpen ] = useState(false)
	const showModal = () => { setIsModalOpen(true) }
	const handleCancel = () => { setIsModalOpen(false) }

	return (
		<>
			<Button className="button" type="primary" onClick={showModal}>
				<FeatherIcon icon="database" size="20"/>
				Наборы данных
			</Button>

			{ isModalOpen && <SnapshotsModal isModalOpen={isModalOpen} handleCancel={handleCancel}/> }
		</>
	)
}