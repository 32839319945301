import { Select, Form, Alert } from 'antd'
import * as _ from 'lodash-es'
import { useEffect } from 'react'
import { useImmer } from 'use-immer'
import { useForm } from '../../AppForm'
import { useAppData } from '../../../pages/Project'

export default function ActionsSelector({ onChange }) {
	const { appWorldData } = useAppData()
	const { locationsInfo } = appWorldData
	const mainForm = useForm()

	const { map, part } = mainForm.fields
	const defaultLocation = locationsInfo[map] ? Number(map) : 1
	const defaultPart = locationsInfo[map] && locationsInfo[map]?.parts[part] ? Number(part) : 1

	const [ data, setData ] = useImmer({
		location: defaultLocation,
		part: defaultPart
	})

	useEffect(() => {
		if (data.location && data.part) {
			const actionsData = _.assign({
				locationName: locationsInfo[data.location].name,
				location: data.location,
				part: data.part
			}, locationsInfo[data.location].parts[data.part])

			onChange(actionsData)
		}
	// eslint-disable-next-line
	}, [ data ])

	const alerts = getAlerts(locationsInfo, map, part)
	const locations = getLocations(locationsInfo)
	const parts = getParts(locationsInfo, data.location)

	return <>
		{ alerts }

		<div style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: 20
		}}>
			<Form.Item
				label="Локация"
				style={{ flexGrow: 1 }}
			>
				<Select
					defaultValue={defaultLocation}
					onChange={location => { setData(data => { data.location = location }) }}
				>
					{ locations }
				</Select>
			</Form.Item>

			{ parts && (
				<Form.Item
					label="Часть"
					key={data.location}
					style={{ flexGrow: 1, marginLeft: 20 }}
				>
					<Select
						defaultValue={defaultPart}
						onChange={part => { setData(data => { data.part = part }) }}
					>
						{ parts }
					</Select>
				</Form.Item>
			)}
		</div>
	</>
}

const getAlerts = (locationsInfo, map, part) => {
	let message

	const validLocation = locationsInfo[map]

	if (!validLocation) {
		message = `Локации "${ map }" нет в конфиге.`
	}

	const validPart = locationsInfo[map] && locationsInfo[map]?.parts[part]

	if (!validPart) {
		message = `Для части "${ part }" у локации "${ map }" нет конфига.`
	}

	if (message) {
		return <Alert
			message={message}
			style={{ margin: '20px 0' }}
		/>
	}
}

const getLocations = locationsInfo => _.map(locationsInfo, (item, locationID) => {
	locationID = Number(locationID)

	return  (
		<Select.Option
			key={locationID}
			value={locationID}
		>
			{ locationID } { item.name }
		</Select.Option>
	)
})

const getParts = (locationsInfo, selectedLocation) => {
	const parts = locationsInfo[selectedLocation]?.parts

	if (parts) {
		return _.map(parts, (data, partID) => {
			partID = Number(partID)

			return <Select.Option
				key={partID}
				value={partID}
			>
				{ partID } { data.name }
			</Select.Option>
		})
	}

	return null
}