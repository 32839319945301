class ApiConfig {
	/** Запрос авторизации */
	get authorize() {
		return `${ AppConfig.apiURL }/authorize`
	}

	/** Запрос получения данных приложения */
	get getAppInfo() {
		return `${ AppConfig.apiURL }/get_app_info`
	}

	/** Обновление данных приложения */
	get updateUser() {
		return `${ AppConfig.apiURL }/update_user`
	}

	/** Проверка IP на доступ */
	get checkUserIP() {
		return `${ AppConfig.apiURL }/check_user_ip`
	}

	/** Сброс данных приложения */
	get resetUser() {
		return `${ AppConfig.apiURL }/reset_user`
	}

	/** Копирование данных пользователя */
	get copyUser() {
		return `${ AppConfig.apiURL }/copy_user`
	}

	/** Копирование данных пользователя по заданным параметрам */
	get copyUserByParams() {
		return `${ AppConfig.apiURL }/copy_user_by_params`
	}

	/** Добавление пользователю игрового профиля */
	get additionUser() {
		return `${ AppConfig.apiURL }/set_profile_id`
	}

	/** Возврат платежей в приложении */
	get refundPayment() {
		return `${ AppConfig.apiURL }/refund_payment`
	}

	/** История монет игрока */
	get getCoinsHistoryByDate() {
		return `${ AppConfig.apiURL }/get_coins_history_by_date`
	}

	/** История платежей игрока */
	get getPayments() {
		return `${ AppConfig.apiURL }/get_payments`
	}

	/** История начисления подарков игрока */
	get getGiftHistory() {
		return `${ AppConfig.apiURL }/get_gift_history`
	}

	/** Начисления подарка */
	get setGift() {
		return `${ AppConfig.apiURL }/set_gift`
	}

	/** Получение сообщений от пользователей */
	get getReviews() {
		return `${ AppConfig.apiURL }/get_reviews`
	}

	/** Получение сообщений от пользователей об ошибках */
	get getReviewsErrors() {
		return `${ AppConfig.apiURL }/get_reviews_errors`
	}

	/** Начисление кристаллов */
	get setCrystals() {
		return `${ AppConfig.apiURL }/set_crystals`
	}

	/** Сохранение состояний приложения */
	get setSnapshot() {
		return `${ AppConfig.apiURL }/set_snapshot`
	}

	/** Начисление инфо-окон */
	get setGiftsToUser() {
		return `${ AppConfig.apiURL }/set_gifts_to_user`
	}

	/** Обновление праздичной акции (GameFriday) */
	get setPromo() {
		return `${ AppConfig.apiURL }/set_promo`
	}

	/** Получение различных конфигов */
	get getConfig() {
		return `${ AppConfig.apiURL }/get_config`
	}

	/** Получение толкового словаря проекта Словесный микс */
	get getGameDictionary() {
		return `${ AppConfig.apiURL }/get_game_dictionary`
	}

	/** Обновление слова в словаре */
	get updateWordDescription() {
		return `${ AppConfig.apiURL }/update_word_description`
	}

	/** Обновление статуса слова */
	get updateWordStatus() {
		return `${ AppConfig.apiURL }/update_word_status`
	}

	/** Импорт/экспорт словаря в CSV */
	get dictionaryCsv() {
		return `${ AppConfig.apiURL }/dictionary_csv`
	}
}

class AppConfig {
	static apiURL = 'https://panel.zebomba.ru/api/index.php/api'

	static api = new ApiConfig()

	static appsOrder = [ 'find', 'cat', 'trip', 'words', 'wg', 'villa', 'pirate', 'alice', 'sweet', 'house', 'indi', 'fish', 'birds', 'cargo', 'water', 'alien', 'uw' ]
}

export default AppConfig