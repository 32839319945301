import { useForm } from "../AppForm"
import PopupTypes from "../../popups/PopupTypes"
import * as _ from 'lodash-es'
import { Button } from "antd"


export default function ListField({ data, onValueChange, defaultValue }) {
	const { setPopupData } = useForm()

	if (!defaultValue || _.includes([ '[[]]', '[]' ], defaultValue)) {
		if (!data.returnType) {
			throw new Error(`You have to set "returnType" property for ${ data.name } field in config`)
		}

		defaultValue = data.returnType === 'array' ? [] : {}
	}

	const popupData = _.assign(data, {
		type: PopupTypes.editList,
		fieldName: data.name,
		list: _.isString(defaultValue) ? JSON.parse(defaultValue) : defaultValue,
		onFieldChange: value => onValueChange(value)
	})

	return (
		<Button style={{ fontSize: 15 }} onClick={() => setPopupData(popupData)}>Изменить</Button>
	)
}