import { Modal, Input, Button } from "antd"
import { useImmer } from "use-immer"

import AppConfig from '../../config/AppConfig'
import { useAuth } from '../../../provider/AuthProvider'
import zebomba from '../../../tools/zebomba'
import * as _ from 'lodash-es'

export default function EditWordModal({ wordData, onSave, onCancel }) {
	const { user } = useAuth()
	const [ word, setWord ] = useImmer(wordData)
	const [ inProgress, setInProgress ] = useImmer(false)
	// const wordDef = useRef()

	const saveWord = async () => {
		setInProgress(true)

		const requestData = _.assign({
			id: user.id,
			access_token: user.token,
			word_id: Number(word.id),
			description: word.description,
			admin_id: user.id
		})

		const response = await fetch(AppConfig.api.updateWordDescription, {
			method: 'POST',
			body: new URLSearchParams(requestData)
		})

		const responseData = await response.json()
		
		if (responseData.error) {
			zebomba.message.error(responseData.error, responseData.error_key)
		}
		else if (responseData.success) {
			zebomba.message.success(`Слово "${ word.word }" успешно обновлено`)

			onSave(word)
			onCancel()
		}
	}

	return (
		<Modal
			width={582}
			destroyOnClose={true}
			transitionName=""
			open
			footer=""
			onCancel={onCancel}
			style={{
				top: '50%',
				transform: 'translateY(-50%)'
			}}
		>
			<div
				className="word"
				style={{
					fontSize: 20,
					marginBottom: 15
				}}
			>
				{ wordData.word }
			</div>

			<Input.TextArea
				onKeyUp={e => {
					console.log(e.target.textContent.trim())
					setWord(word => {
						word.description = e.target.textContent.trim()

						return word
					})
				}}
				defaultValue={wordData.description}
				rows={9}
				style={{
					fontSize: 16,
					marginBottom: 40
				}}
			/>

			<Button type="primary" onClick={saveWord} disabled={inProgress}>Сохранить</Button>
		</Modal>		
	)
}