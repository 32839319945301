import * as _ from 'lodash-es'
import RawField from '../fields/RawField'
import FieldFactory from '../FieldFactory'
import { useRef } from 'react'
import BotsField from './LeaderboardWinners/BotsField'
import WinnersField from './LeaderboardWinners/WinnersField'

const fieldsConfig = [
	{
		name: 'rating_id',
		type: 'number',
		title: 'Порядковый номер недели в году'
	},
	{
		name: 'bots',
		title: 'Текущая лига'
	},
	{
		name: 'winners',
		title: 'Призёры прошлой лиги'
	}
]

export default function LeaderboardWinnersSection({ data, onFieldChange, formFields }) {
	const fieldValueRef = useRef(formFields.leaderboard_data && formFields.leaderboard_data[0])
	const fieldValue = fieldValueRef.current
	const isDataEmpty = _.isEmpty(fieldValue)

	let fields,
		customField

	if (!isDataEmpty) {
		fields = _.filter(_.map(fieldsConfig, field => {
			if (field.name === 'rating_id') {
				fieldValue.rating_id = Number(fieldValue.rating_id)

				if (!(fieldValue.rating_id || fieldValue.rating_id === 0)) {
					return
				}
			}
			else if (_.isEmpty(fieldValue[field.name])) {
				return
			}

			if (field.name === 'bots') {
				customField = BotsField
			}
			else if (field.name === 'winners') {
				customField = WinnersField
			}

			return (
				<FieldFactory
					key={field.name}
					data={{
						name: field.name,
						type: field.type,
						title: field.title,
						value: fieldValue[field.name],
						customField
					}}
					onFieldChange={field => {
						fieldValue[field.name] = field.value

						onFieldChange({
							name: 'leaderboard_data',
							value: [ fieldValue ]
						})
					}}
				/>
			)
		}))
	}

	return (
		<div id={ `section_${ data.name }` } className="section">
			<div className="content">
				<div className="header">
					<div className="top">
						<div className="titleExtended">{ data.title }</div>
						<div className="name">{ data.showName ? data.name : '' }</div>
					</div>

					{
							// Показывать кнопку только если данное поле есть в formFields
							data.showName && !isDataEmpty && (
								<RawField
									data={data}
									onValueChange={value => {
										const field = {
											name: data.name,
											value
										}

										onFieldChange(field)
									}}
									defaultValue={formFields[data.name]}
								/>
							)
						}
				</div>

				<div className="fields">
					{ isDataEmpty && <div className="noData">Нет данных. Они должны быть получены в течение игры.</div> }
					{ fields }
				</div>
			</div>
		</div>
	)
}