const dev_vk_interstitials = {
    "loadingScreen": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 2
        }
    },
    "beforeBattle": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 12
        }
    },
    "afterBattle": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 5,
            "no_payment_count": 0,
            "rev": 0
        }
    },
    "afterBattleRound": {
        "probability": 100,
        "roundsProbability": {
            "1": 100,
            "2": 100
        },
        "rules": {
            "map": 1,
            "point": 8,
            "no_payment_count": 0,
            "rev": 0
        }
    }
}

const release_vk_interstitials = {
    "loadingScreen": {
        "probability": 0,
        "rules": {
            "map": 1,
            "point": 2
        }
    },
    "beforeBattle": {
        "probability": 0,
        "rules": {
            "map": 1,
            "point": 12
        }
    },
    "afterBattle": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 5,
            "no_payment_count": 0,
            "rev": 0
        }
    },
    "afterBattleRound": {
        "probability": 100,
        "roundsProbability": {
            "1": 100,
            "2": 100
        },
        "rules": {
            "map": 1,
            "point": 8,
            "no_payment_count": 0,
            "rev": 0
        }
    }
}

export default {
	locationsInfo: {
		"1": {
			"name": "villa",
			"parts": {
				"1": {
					"name": "house",
					"rewards": {
						"8": {
							"coins": 20
						},
						"16": {
							"coins": 40
						},
						"25": {
							"coins": 80
						}
					},
					"actions": {
						"1": {
							"name": "stairs",
							"diamonds": 3
						},
						"2": {
							"name": "gazeboIvy",
							"diamonds": 3
						},
						"3": {
							"name": "leaves",
							"diamonds": 3
						},
						"4": {
							"name": "railings",
							"diamonds": 3
						},
						"5": {
							"name": "gazebo",
							"diamonds": 3
						},
						"6": {
							"name": "nettle",
							"diamonds": 3
						},
						"7": {
							"name": "stepsClean",
							"diamonds": 3
						},
						"8": {
							"name": "gazeboRoof",
							"diamonds": 3
						},
						"9": {
							"name": "tree",
							"diamonds": 3
						},
						"10": {
							"name": "stepsRepair",
							"diamonds": 4
						},
						"11": {
							"name": "paving",
							"diamonds": 4
						},
						"12": {
							"name": "treeWater",
							"diamonds": 4
						},
						"13": {
							"name": "houseIvy",
							"diamonds": 5
						},
						"14": {
							"name": "border",
							"diamonds": 5
						},
						"15": {
							"name": "treeBeautify",
							"diamonds": 5
						},
						"16": {
							"name": "walls",
							"diamonds": 5
						},
						"17": {
							"name": "road",
							"diamonds": 5
						},
						"18": {
							"name": "arch",
							"diamonds": 5
						},
						"19": {
							"name": "windows",
							"diamonds": 6
						},
						"20": {
							"name": "flowers",
							"diamonds": 6
						},
						"21": {
							"name": "lights",
							"diamonds": 6
						},
						"22": {
							"name": "roof",
							"diamonds": 7
						},
						"23": {
							"name": "hedge",
							"diamonds": 7
						},
						"24": {
							"name": "windowsDecorate",
							"diamonds": 7
						},
						"25": {
							"name": "stepsDecorate",
							"diamonds": 8
						}
					}
				},
				"2": {
					"name": "garden",
					"rewards": {
						"8": {
							"coins": 25
						},
						"16": {
							"coins": 50
						},
						"25": {
							"coins": 90
						}
					},
					"actions": {
						"1": {
							"name": "weeds",
							"diamonds": 4
						},
						"2": {
							"name": "benchRepair",
							"diamonds": 4
						},
						"3": {
							"name": "fence",
							"diamonds": 4
						},
						"4": {
							"name": "tree",
							"diamonds": 5
						},
						"5": {
							"name": "stone",
							"diamonds": 5
						},
						"6": {
							"name": "arch",
							"diamonds": 5
						},
						"7": {
							"name": "hedge",
							"diamonds": 6
						},
						"8": {
							"name": "fountain",
							"diamonds": 6
						},
						"9": {
							"name": "flowerbed",
							"diamonds": 6
						},
						"10": {
							"name": "shrubs",
							"diamonds": 7
						},
						"11": {
							"name": "floor",
							"diamonds": 7
						},
						"12": {
							"name": "soil",
							"diamonds": 7
						},
						"13": {
							"name": "tiles",
							"diamonds": 8
						},
						"14": {
							"name": "lanterns",
							"diamonds": 8
						},
						"15": {
							"name": "bird",
							"diamonds": 10
						},
						"16": {
							"name": "stubBorder",
							"diamonds": 9
						},
						"17": {
							"name": "paving",
							"diamonds": 9
						},
						"18": {
							"name": "vegetables",
							"diamonds": 8
						},
						"19": {
							"name": "stubDecoration",
							"diamonds": 10
						},
						"20": {
							"name": "flowers",
							"diamonds": 10
						},
						"21": {
							"name": "berries",
							"diamonds": 9
						},
						"22": {
							"name": "border",
							"diamonds": 11
						},
						"23": {
							"name": "water",
							"diamonds": 11
						},
						"24": {
							"name": "scarecrow",
							"diamonds": 11
						},
						"25": {
							"name": "benches",
							"diamonds": 12
						}
					}
				},
				"3": {
					"name": "barn",
					"rewards": {
						"8": {
							"coins": 40
						},
						"16": {
							"coins": 80
						},
						"25": {
							"coins": 161
						}
					},
					"actions": {
						"1": {
							"name": "bridge",
							"diamonds": 6
						},
						"2": {
							"name": "tableClean",
							"diamonds": 6
						},
						"3": {
							"name": "leanto",
							"diamonds": 6
						},
						"4": {
							"name": "pond",
							"diamonds": 9
						},
						"5": {
							"name": "tableFix",
							"diamonds": 9
						},
						"6": {
							"name": "roof",
							"diamonds": 9
						},
						"7": {
							"name": "lilies",
							"diamonds": 12
						},
						"8": {
							"name": "benches",
							"diamonds": 12
						},
						"9": {
							"name": "walls",
							"diamonds": 12
						},
						"10": {
							"name": "pondVeg",
							"diamonds": 14
						},
						"11": {
							"name": "barbeque",
							"diamonds": 14
						},
						"12": {
							"name": "entrance",
							"diamonds": 14
						},
						"13": {
							"name": "fish",
							"diamonds": 17
						},
						"14": {
							"name": "sausage",
							"diamonds": 17
						},
						"15": {
							"name": "barnPaint",
							"diamonds": 17
						},
						"16": {
							"name": "tree",
							"diamonds": 20
						},
						"17": {
							"name": "table",
							"diamonds": 20
						},
						"18": {
							"name": "carFix",
							"diamonds": 20
						},
						"19": {
							"name": "swingFix",
							"diamonds": 22
						},
						"20": {
							"name": "weed",
							"diamonds": 22
						},
						"21": {
							"name": "carPaint",
							"diamonds": 22
						},
						"22": {
							"name": "swingPaint",
							"diamonds": 25
						},
						"23": {
							"name": "bugs",
							"diamonds": 25
						},
						"24": {
							"name": "wheels",
							"diamonds": 25
						},
						"25": {
							"name": "paving",
							"diamonds": 25
						}
					}
				},
				"4": {
					"name": "beach",
					"rewards": {
						"8": {
							"coins": 48
						},
						"16": {
							"coins": 96
						},
						"25": {
							"coins": 193
						}
					},
					"actions": {
						"1": {
							"name": "garbageWater",
							"diamonds": 7
						},
						"2": {
							"name": "weeds",
							"diamonds": 7
						},
						"3": {
							"name": "nettle",
							"diamonds": 7
						},
						"4": {
							"name": "algae",
							"diamonds": 11
						},
						"5": {
							"name": "carriage",
							"diamonds": 11
						},
						"6": {
							"name": "well",
							"diamonds": 11
						},
						"7": {
							"name": "boat",
							"diamonds": 14
						},
						"8": {
							"name": "stone",
							"diamonds": 14
						},
						"9": {
							"name": "roof",
							"diamonds": 14
						},
						"10": {
							"name": "pierce",
							"diamonds": 17
						},
						"11": {
							"name": "bush",
							"diamonds": 17
						},
						"12": {
							"name": "paving",
							"diamonds": 17
						},
						"13": {
							"name": "garbageBeach",
							"diamonds": 20
						},
						"14": {
							"name": "lanternLeft",
							"diamonds": 20
						},
						"15": {
							"name": "fence",
							"diamonds": 20
						},
						"16": {
							"name": "boatPaint",
							"diamonds": 24
						},
						"17": {
							"name": "plants",
							"diamonds": 24
						},
						"18": {
							"name": "tree",
							"diamonds": 24
						},
						"19": {
							"name": "leaves",
							"diamonds": 27
						},
						"20": {
							"name": "stair",
							"diamonds": 27
						},
						"21": {
							"name": "lanternRight",
							"diamonds": 27
						},
						"22": {
							"name": "palms",
							"diamonds": 30
						},
						"23": {
							"name": "railings",
							"diamonds": 30
						},
						"24": {
							"name": "lounger",
							"diamonds": 30
						},
						"25": {
							"name": "hammock",
							"diamonds": 42
						}
					}
				}
			}
		},
		"2": {
			"name": "island",
			"parts": {
				"1": {
					"name": "house",
					"rewards": {
						"8": {
							"coins": 30
						},
						"16": {
							"coins": 65
						},
						"25": {
							"coins": 130
						}
					},
					"actions": {
						"1": {
							"name": "mudRoof",
							"diamonds": 5
						},
						"2": {
							"name": "wilson",
							"diamonds": 5
						},
						"3": {
							"name": "capybara",
							"diamonds": 5
						},
						"4": {
							"name": "holeRoof",
							"diamonds": 8
						},
						"5": {
							"name": "windowRoof",
							"diamonds": 8
						},
						"6": {
							"name": "garbage",
							"diamonds": 8
						},
						"7": {
							"name": "balkDown",
							"diamonds": 11
						},
						"8": {
							"name": "windowHouse",
							"diamonds": 11
						},
						"9": {
							"name": "weed",
							"diamonds": 11
						},
						"10": {
							"name": "ladder",
							"diamonds": 14
						},
						"11": {
							"name": "railingOne",
							"diamonds": 14
						},
						"12": {
							"name": "fenceFlower",
							"diamonds": 14
						},
						"13": {
							"name": "balkHouse",
							"diamonds": 16
						},
						"14": {
							"name": "floorGazebo",
							"diamonds": 16
						},
						"15": {
							"name": "soil",
							"diamonds": 16
						},
						"16": {
							"name": "railingTwo",
							"diamonds": 19
						},
						"17": {
							"name": "sticks",
							"diamonds": 19
						},
						"18": {
							"name": "flowers",
							"diamonds": 19
						},
						"19": {
							"name": "lantern",
							"diamonds": 22
						},
						"20": {
							"name": "fence",
							"diamonds": 22
						},
						"21": {
							"name": "road",
							"diamonds": 22
						},
						"22": {
							"name": "decorGazebo",
							"diamonds": 25
						},
						"23": {
							"name": "letterBox",
							"diamonds": 25
						},
						"24": {
							"name": "cleanFence",
							"diamonds": 25
						},
						"25": {
							"name": "lightFence",
							"diamonds": 25
						}
					}
				},
				"2": {
					"name": "farm",
					"rewards": {
						"8": {
							"coins": 55
						},
						"16": {
							"coins": 105
						},
						"25": {
							"coins": 215
						}
					},
					"actions": {
						"1": {
							"name": "mudWell",
							"diamonds": 8
						},
						"2": {
							"name": "balkHouse",
							"diamonds": 8
						},
						"3": {
							"name": "garbage",
							"diamonds": 8
						},
						"4": {
							"name": "well",
							"diamonds": 13
						},
						"5": {
							"name": "door",
							"diamonds": 13
						},
						"6": {
							"name": "drinker",
							"diamonds": 13
						},
						"7": {
							"name": "bucketWell",
							"diamonds": 18
						},
						"8": {
							"name": "ladder",
							"diamonds": 18
						},
						"9": {
							"name": "cow",
							"diamonds": 18
						},
						"10": {
							"name": "weed",
							"diamonds": 23
						},
						"11": {
							"name": "roof",
							"diamonds": 23
						},
						"12": {
							"name": "lantern",
							"diamonds": 23
						},
						"13": {
							"name": "straw",
							"diamonds": 27
						},
						"14": {
							"name": "vane",
							"diamonds": 27
						},
						"15": {
							"name": "cleanPond",
							"diamonds": 27
						},
						"16": {
							"name": "soil",
							"diamonds": 32
						},
						"17": {
							"name": "gates",
							"diamonds": 32
						},
						"18": {
							"name": "duck",
							"diamonds": 32
						},
						"19": {
							"name": "vegetables",
							"diamonds": 37
						},
						"20": {
							"name": "wallsPaint",
							"diamonds": 37
						},
						"21": {
							"name": "plantTree",
							"diamonds": 37
						},
						"22": {
							"name": "scarecrow",
							"diamonds": 42
						},
						"23": {
							"name": "flowers",
							"diamonds": 42
						},
						"24": {
							"name": "fences",
							"diamonds": 42
						},
						"25": {
							"name": "road",
							"diamonds": 42
						}
					}
				},
				"3": {
					"name": "cafe",
					"rewards": {
						"8": {
							"coins": 80
						},
						"16": {
							"coins": 160
						},
						"25": {
							"coins": 320
						}
					},
					"actions": {
						"1": {
							"name": "roofCafe",
							"diamonds": 11
						},
						"2": {
							"name": "signboard",
							"diamonds": 11
						},
						"3": {
							"name": "pavilionRoof",
							"diamonds": 11
						},
						"4": {
							"name": "window",
							"diamonds": 19
						},
						"5": {
							"name": "canopy",
							"diamonds": 19
						},
						"6": {
							"name": "floor",
							"diamonds": 19
						},
						"7": {
							"name": "wallsPaint",
							"diamonds": 26
						},
						"8": {
							"name": "barStool",
							"diamonds": 26
						},
						"9": {
							"name": "tables",
							"diamonds": 26
						},
						"10": {
							"name": "cafeStairs",
							"diamonds": 34
						},
						"11": {
							"name": "cafeFood",
							"diamonds": 34
						},
						"12": {
							"name": "chairs",
							"diamonds": 34
						},
						"13": {
							"name": "parrot",
							"diamonds": 41
						},
						"14": {
							"name": "pavilionPlants",
							"diamonds": 41
						},
						"15": {
							"name": "railing",
							"diamonds": 41
						},
						"16": {
							"name": "cafeFlowers",
							"diamonds": 49
						},
						"17": {
							"name": "road",
							"diamonds": 49
						},
						"18": {
							"name": "pavilionStairs",
							"diamonds": 49
						},
						"19": {
							"name": "cafePlants",
							"diamonds": 56
						},
						"20": {
							"name": "lantern",
							"diamonds": 56
						},
						"21": {
							"name": "pavilionFood",
							"diamonds": 56
						},
						"22": {
							"name": "surfboard",
							"diamonds": 64
						},
						"23": {
							"name": "beachDecor",
							"diamonds": 64
						},
						"24": {
							"name": "roofDecor",
							"diamonds": 64
						},
						"25": {
							"name": "menu",
							"diamonds": 64
						}
					}
				},
				"4": {
					"name": "lighthouse",
					"rewards": {
						"8": {
							"coins": 120
						},
						"16": {
							"coins": 240
						},
						"25": {
							"coins": 480
						}
					},
					"actions": {
						"1": {
							"name": "garbage",
							"diamonds": 16
						},
						"2": {
							"name": "jetty",
							"diamonds": 74
						},
						"3": {
							"name": "fishShop",
							"diamonds": 16
						},
						"4": {
							"name": "roof",
							"diamonds": 27
						},
						"5": {
							"name": "railing",
							"diamonds": 16
						},
						"6": {
							"name": "removeSignboard",
							"diamonds": 27
						},
						"7": {
							"name": "window",
							"diamonds": 39
						},
						"8": {
							"name": "fishingRod",
							"diamonds": 27
						},
						"9": {
							"name": "newSignboard",
							"diamonds": 39
						},
						"10": {
							"name": "barrel",
							"diamonds": 50
						},
						"11": {
							"name": "fixBoat",
							"diamonds": 39
						},
						"12": {
							"name": "lantern",
							"diamonds": 50
						},
						"13": {
							"name": "lightLighthouse",
							"diamonds": 62
						},
						"14": {
							"name": "paintBoat",
							"diamonds": 50
						},
						"15": {
							"name": "barrelFish",
							"diamonds": 97
						},
						"16": {
							"name": "paintLighthouse",
							"diamonds": 74
						},
						"17": {
							"name": "lifebuoy",
							"diamonds": 74
						},
						"18": {
							"name": "road",
							"diamonds": 62
						},
						"19": {
							"name": "decorLighthouse",
							"diamonds": 85
						},
						"20": {
							"name": "flagpole",
							"diamonds": 85
						},
						"21": {
							"name": "paintShips",
							"diamonds": 85
						},
						"22": {
							"name": "vane",
							"diamonds": 97
						},
						"23": {
							"name": "bell",
							"diamonds": 97
						},
						"24": {
							"name": "paddle",
							"diamonds": 97
						},
						"25": {
							"name": "nameBoat",
							"diamonds": 62
						}
					}
				}
			}
		},
		"3": {
			"name": "village",
			"parts": {
				"1": {
					"name": "museum",
					"rewards": {
						"8": {
							"coins": 55
						},
						"16": {
							"coins": 105
						},
						"25": {
							"coins": 215
						}
					},
					"actions": {
						"1": {
							"name": "thickets",
							"diamonds": 8
						},
						"2": {
							"name": "pit",
							"diamonds": 8
						},
						"3": {
							"name": "garbage",
							"diamonds": 8
						},
						"4": {
							"name": "columnsRepair",
							"diamonds": 13
						},
						"5": {
							"name": "floor",
							"diamonds": 13
						},
						"6": {
							"name": "table",
							"diamonds": 13
						},
						"7": {
							"name": "porch",
							"diamonds": 18
						},
						"8": {
							"name": "door",
							"diamonds": 18
						},
						"9": {
							"name": "showcases",
							"diamonds": 18
						},
						"10": {
							"name": "columnsRepaint",
							"diamonds": 23
						},
						"11": {
							"name": "lawn",
							"diamonds": 23
						},
						"12": {
							"name": "roofMain",
							"diamonds": 23
						},
						"13": {
							"name": "sculpture",
							"diamonds": 27
						},
						"14": {
							"name": "web",
							"diamonds": 27
						},
						"15": {
							"name": "balcony",
							"diamonds": 27
						},
						"16": {
							"name": "stuffedFish",
							"diamonds": 32
						},
						"17": {
							"name": "roofSide",
							"diamonds": 32
						},
						"18": {
							"name": "exhibits",
							"diamonds": 32
						},
						"19": {
							"name": "boxOffice",
							"diamonds": 37
						},
						"20": {
							"name": "souvenirs",
							"diamonds": 37
						},
						"21": {
							"name": "tablet",
							"diamonds": 37
						},
						"22": {
							"name": "road",
							"diamonds": 42
						},
						"23": {
							"name": "stand",
							"diamonds": 42
						},
						"24": {
							"name": "lanterns",
							"diamonds": 42
						},
						"25": {
							"name": "poster",
							"diamonds": 25
						}
					}
				},
				"2": {
					"name": "shop",
					"rewards": {
						"8": {
							"coins": 75
						},
						"16": {
							"coins": 145
						},
						"25": {
							"coins": 295
						}
					},
					"actions": {
						"1": {
							"name": "garbage",
							"diamonds": 10
						},
						"2": {
							"name": "area",
							"diamonds": 10
						},
						"3": {
							"name": "stalls",
							"diamonds": 10
						},
						"4": {
							"name": "wallsRepair",
							"diamonds": 17
						},
						"5": {
							"name": "roofLeft",
							"diamonds": 17
						},
						"6": {
							"name": "cartRemove",
							"diamonds": 17
						},
						"7": {
							"name": "oldBoard",
							"diamonds": 24
						},
						"8": {
							"name": "bench",
							"diamonds": 24
						},
						"9": {
							"name": "post",
							"diamonds": 24
						},
						"10": {
							"name": "roofRight",
							"diamonds": 31
						},
						"11": {
							"name": "wallsRepaint",
							"diamonds": 31
						},
						"12": {
							"name": "boxes",
							"diamonds": 31
						},
						"13": {
							"name": "table",
							"diamonds": 38
						},
						"14": {
							"name": "aquarium",
							"diamonds": 38
						},
						"15": {
							"name": "canopy",
							"diamonds": 38
						},
						"16": {
							"name": "signboard",
							"diamonds": 45
						},
						"17": {
							"name": "scales",
							"diamonds": 45
						},
						"18": {
							"name": "cartNew",
							"diamonds": 45
						},
						"19": {
							"name": "fishFrozen",
							"diamonds": 52
						},
						"20": {
							"name": "signs",
							"diamonds": 52
						},
						"21": {
							"name": "light",
							"diamonds": 52
						},
						"22": {
							"name": "dish",
							"diamonds": 59
						},
						"23": {
							"name": "cat",
							"diamonds": 59
						},
						"24": {
							"name": "flashlights",
							"diamonds": 59
						},
						"25": {
							"name": "stock",
							"diamonds": 42
						}
					}
				},
				"3": {
					"name": "park",
					"rewards": {
						"8": {
							"coins": 95
						},
						"16": {
							"coins": 195
						},
						"25": {
							"coins": 385
						}
					},
					"actions": {
						"1": {
							"name": "garbageGround",
							"diamonds": 13
						},
						"2": {
							"name": "pit",
							"diamonds": 13
						},
						"3": {
							"name": "stones",
							"diamonds": 13
						},
						"4": {
							"name": "curbs",
							"diamonds": 22
						},
						"5": {
							"name": "sidewalk",
							"diamonds": 22
						},
						"6": {
							"name": "bench",
							"diamonds": 22
						},
						"7": {
							"name": "garbageBushes",
							"diamonds": 31
						},
						"8": {
							"name": "branches",
							"diamonds": 31
						},
						"9": {
							"name": "fountainClean",
							"diamonds": 31
						},
						"10": {
							"name": "fountainRepair",
							"diamonds": 40
						},
						"11": {
							"name": "mermaidRepair",
							"diamonds": 40
						},
						"12": {
							"name": "flowers",
							"diamonds": 40
						},
						"13": {
							"name": "garbageCans",
							"diamonds": 50
						},
						"14": {
							"name": "bushesLeft",
							"diamonds": 50
						},
						"15": {
							"name": "bushesRight",
							"diamonds": 50
						},
						"16": {
							"name": "seashellRepair",
							"diamonds": 59
						},
						"17": {
							"name": "fishRepair",
							"diamonds": 59
						},
						"18": {
							"name": "fishFountain",
							"diamonds": 59
						},
						"19": {
							"name": "dolphinRepair",
							"diamonds": 68
						},
						"20": {
							"name": "lanterns",
							"diamonds": 68
						},
						"21": {
							"name": "flowerBeds",
							"diamonds": 68
						},
						"22": {
							"name": "flowerpots",
							"diamonds": 77
						},
						"23": {
							"name": "waterFountain",
							"diamonds": 77
						},
						"24": {
							"name": "decorateFountain",
							"diamonds": 77
						},
						"25": {
							"name": "arch",
							"diamonds": 64
						}
					}
				},
				"4": {
					"name": "marina",
					"rewards": {
						"8": {
							"coins": 120
						},
						"16": {
							"coins": 240
						},
						"25": {
							"coins": 480
						}
					},
					"actions": {
						"1": {
							"name": "pit",
							"diamonds": 16
						},
						"2": {
							"name": "garbageStones",
							"diamonds": 16
						},
						"3": {
							"name": "pierRepair",
							"diamonds": 16
						},
						"4": {
							"name": "garbageSea",
							"diamonds": 27
						},
						"5": {
							"name": "boatsClean",
							"diamonds": 27
						},
						"6": {
							"name": "boatsRepair",
							"diamonds": 27
						},
						"7": {
							"name": "fence",
							"diamonds": 39
						},
						"8": {
							"name": "track",
							"diamonds": 39
						},
						"9": {
							"name": "ladder",
							"diamonds": 39
						},
						"10": {
							"name": "lanterns",
							"diamonds": 50
						},
						"11": {
							"name": "mast",
							"diamonds": 50
						},
						"12": {
							"name": "sailsBig",
							"diamonds": 50
						},
						"13": {
							"name": "sailsSmall",
							"diamonds": 62
						},
						"14": {
							"name": "carpet",
							"diamonds": 62
						},
						"15": {
							"name": "bench",
							"diamonds": 62
						},
						"16": {
							"name": "network",
							"diamonds": 74
						},
						"17": {
							"name": "diving",
							"diamonds": 74
						},
						"18": {
							"name": "barrel",
							"diamonds": 74
						},
						"19": {
							"name": "chest",
							"diamonds": 85
						},
						"20": {
							"name": "fishingRod",
							"diamonds": 85
						},
						"21": {
							"name": "barrelsCatch",
							"diamonds": 85
						},
						"22": {
							"name": "lifeline",
							"diamonds": 97
						},
						"23": {
							"name": "toys",
							"diamonds": 97
						},
						"24": {
							"name": "castle",
							"diamonds": 97
						},
						"25": {
							"name": "ship",
							"diamonds": 97
						}
					}
				}
			}
		},
		"4": {
			"name": "resort",
			"parts": {
				"1": {
					"name": "paradise",
					"rewards": {
						"8": {
							"coins": 55
						},
						"16": {
							"coins": 105
						},
						"25": {
							"coins": 215
						}
					},
					"actions": {
						"1": {
							"name": "pit",
							"diamonds": 8
						},
						"2": {
							"name": "lane",
							"diamonds": 8
						},
						"3": {
							"name": "tiles",
							"diamonds": 8
						},
						"4": {
							"name": "benchRight",
							"diamonds": 13
						},
						"5": {
							"name": "table",
							"diamonds": 13
						},
						"6": {
							"name": "benchLeft",
							"diamonds": 13
						},
						"7": {
							"name": "pillow",
							"diamonds": 18
						},
						"8": {
							"name": "litterbox",
							"diamonds": 18
						},
						"9": {
							"name": "garbageGreenhouse1",
							"diamonds": 18
						},
						"10": {
							"name": "tools",
							"diamonds": 23
						},
						"11": {
							"name": "cleanArch",
							"diamonds": 23
						},
						"12": {
							"name": "garbageArch",
							"diamonds": 23
						},
						"13": {
							"name": "plantsArch",
							"diamonds": 27
						},
						"14": {
							"name": "greenhouse1",
							"diamonds": 27
						},
						"15": {
							"name": "greenhousePlants1",
							"diamonds": 27
						},
						"16": {
							"name": "garbageGreenhouse2",
							"diamonds": 32
						},
						"17": {
							"name": "greenhouse2",
							"diamonds": 32
						},
						"18": {
							"name": "pots",
							"diamonds": 32
						},
						"19": {
							"name": "greenhousePlants2",
							"diamonds": 37
						},
						"20": {
							"name": "lanterns",
							"diamonds": 37
						},
						"21": {
							"name": "plantsPots",
							"diamonds": 37
						},
						"22": {
							"name": "flowerbed",
							"diamonds": 42
						},
						"23": {
							"name": "bush",
							"diamonds": 42
						},
						"24": {
							"name": "dragonfruit",
							"diamonds": 42
						},
						"25": {
							"name": "decoration",
							"diamonds": 25
						}
					}
				},
				"2": {
					"name": "volcano",
					"rewards": {
						"8": {
							"coins": 75
						},
						"16": {
							"coins": 145
						},
						"25": {
							"coins": 295
						}
					},
					"actions": {
						"1": {
							"name": "garbage",
							"diamonds": 10
						},
						"2": {
							"name": "pit",
							"diamonds": 10
						},
						"3": {
							"name": "spring",
							"diamonds": 10
						},
						"4": {
							"name": "stairs",
							"diamonds": 17
						},
						"5": {
							"name": "bird",
							"diamonds": 17
						},
						"6": {
							"name": "platform",
							"diamonds": 17
						},
						"7": {
							"name": "weeds",
							"diamonds": 24
						},
						"8": {
							"name": "railings",
							"diamonds": 24
						},
						"9": {
							"name": "bushes",
							"diamonds": 24
						},
						"10": {
							"name": "roof",
							"diamonds": 31
						},
						"11": {
							"name": "chimney",
							"diamonds": 31
						},
						"12": {
							"name": "sign",
							"diamonds": 31
						},
						"13": {
							"name": "door",
							"diamonds": 38
						},
						"14": {
							"name": "windows",
							"diamonds": 38
						},
						"15": {
							"name": "flowers",
							"diamonds": 38
						},
						"16": {
							"name": "brickwork",
							"diamonds": 45
						},
						"17": {
							"name": "bucket",
							"diamonds": 45
						},
						"18": {
							"name": "bathhouse",
							"diamonds": 52
						},
						"19": {
							"name": "pillows",
							"diamonds": 45
						},
						"20": {
							"name": "lighting",
							"diamonds": 52
						},
						"21": {
							"name": "carpet",
							"diamonds": 52
						},
						"22": {
							"name": "furniture",
							"diamonds": 59
						},
						"23": {
							"name": "towel",
							"diamonds": 59
						},
						"24": {
							"name": "drinks",
							"diamonds": 59
						},
						"25": {
							"name": "candles",
							"diamonds": 42
						}
					}
				},
				"3": {
					"name": "lagoon",
					"rewards": {
						"8": {
							"coins": 95
						},
						"16": {
							"coins": 195
						},
						"25": {
							"coins": 385
						}
					},
					"actions": {
						"1": {
							"name": "garbage",
							"diamonds": 13
						},
						"2": {
							"name": "bottles",
							"diamonds": 13
						},
						"3": {
							"name": "beach",
							"diamonds": 13
						},
						"4": {
							"name": "platform",
							"diamonds": 22
						},
						"5": {
							"name": "shed",
							"diamonds": 22
						},
						"6": {
							"name": "stairs",
							"diamonds": 22
						},
						"7": {
							"name": "weeds",
							"diamonds": 31
						},
						"8": {
							"name": "hole",
							"diamonds": 31
						},
						"9": {
							"name": "windows",
							"diamonds": 31
						},
						"10": {
							"name": "door",
							"diamonds": 40
						},
						"11": {
							"name": "sign",
							"diamonds": 40
						},
						"12": {
							"name": "lianes",
							"diamonds": 40
						},
						"13": {
							"name": "pit",
							"diamonds": 50
						},
						"14": {
							"name": "pool",
							"diamonds": 50
						},
						"15": {
							"name": "loungers",
							"diamonds": 50
						},
						"16": {
							"name": "railings",
							"diamonds": 59
						},
						"17": {
							"name": "curtains",
							"diamonds": 59
						},
						"18": {
							"name": "pillows",
							"diamonds": 59
						},
						"19": {
							"name": "chair",
							"diamonds": 68
						},
						"20": {
							"name": "spotlights",
							"diamonds": 68
						},
						"21": {
							"name": "chromakey",
							"diamonds": 68
						},
						"22": {
							"name": "flowers",
							"diamonds": 77
						},
						"23": {
							"name": "drinks",
							"diamonds": 77
						},
						"24": {
							"name": "flamingo",
							"diamonds": 77
						},
						"25": {
							"name": "camera",
							"diamonds": 64
						}
					}
				},
				"4": {
					"name": "clouds",
					"rewards": {
						"8": {
							"coins": 120
						},
						"16": {
							"coins": 240
						},
						"25": {
							"coins": 480
						}
					},
					"actions": {
						"1": {
							"name": "tiles",
							"diamonds": 16
						},
						"2": {
							"name": "floor",
							"diamonds": 16
						},
						"3": {
							"name": "basin",
							"diamonds": 16
						},
						"4": {
							"name": "hole",
							"diamonds": 27
						},
						"5": {
							"name": "windows",
							"diamonds": 27
						},
						"6": {
							"name": "door",
							"diamonds": 27
						},
						"7": {
							"name": "house",
							"diamonds": 39
						},
						"8": {
							"name": "chair",
							"diamonds": 39
						},
						"9": {
							"name": "flowers",
							"diamonds": 39
						},
						"10": {
							"name": "facade",
							"diamonds": 50
						},
						"11": {
							"name": "roof",
							"diamonds": 50
						},
						"12": {
							"name": "fountain",
							"diamonds": 50
						},
						"13": {
							"name": "bar",
							"diamonds": 62
						},
						"14": {
							"name": "barRoof",
							"diamonds": 62
						},
						"15": {
							"name": "barWalls",
							"diamonds": 62
						},
						"16": {
							"name": "fireplace",
							"diamonds": 74
						},
						"17": {
							"name": "digBushes",
							"diamonds": 74
						},
						"18": {
							"name": "light",
							"diamonds": 74
						},
						"19": {
							"name": "plantBushes",
							"diamonds": 85
						},
						"20": {
							"name": "pillows",
							"diamonds": 85
						},
						"21": {
							"name": "stones",
							"diamonds": 85
						},
						"22": {
							"name": "dreamCatcher",
							"diamonds": 97
						},
						"23": {
							"name": "mats",
							"diamonds": 97
						},
						"24": {
							"name": "waterFlowers",
							"diamonds": 97
						},
						"25": {
							"name": "sign",
							"diamonds": 97
						}
					}
				}
			}
		},
		"5": {
            "name": "festival",
            "parts": {
                "1": {
                    "name": "house",
                    "rewards": {
                        "8": {
                            "coins": 55
                        },
                        "16": {
                            "coins": 105
                        },
                        "25": {
                            "coins": 215
                        }
                    },
                    "actions": {
                        "1": {
                            "name": "roof",
                            "diamonds": 8
                        },
                        "2": {
                            "name": "wilson",
                            "diamonds": 8
                        },
                        "3": {
                            "name": "capybara",
                            "diamonds": 8
                        },
                        "4": {
                            "name": "roofLight",
                            "diamonds": 13
                        },
                        "5": {
                            "name": "windowRoof",
                            "diamonds": 13
                        },
                        "6": {
                            "name": "garbage",
                            "diamonds": 13
                        },
                        "7": {
                            "name": "balkDown",
                            "diamonds": 18
                        },
                        "8": {
                            "name": "parrot",
                            "diamonds": 18
                        },
                        "9": {
                            "name": "gardenBed",
                            "diamonds": 18
                        },
                        "10": {
                            "name": "ladder",
                            "diamonds": 23
                        },
                        "11": {
                            "name": "ribbon",
                            "diamonds": 23
                        },
                        "12": {
                            "name": "fenceFlower",
                            "diamonds": 23
                        },
                        "13": {
                            "name": "notification",
                            "diamonds": 27
                        },
                        "14": {
                            "name": "carpet",
                            "diamonds": 27
                        },
                        "15": {
                            "name": "soil",
                            "diamonds": 27
                        },
                        "16": {
                            "name": "railing",
                            "diamonds": 32
                        },
                        "17": {
                            "name": "photo",
                            "diamonds": 32
                        },
                        "18": {
                            "name": "flowers",
                            "diamonds": 32
                        },
                        "19": {
                            "name": "competitions",
                            "diamonds": 37
                        },
                        "20": {
                            "name": "fence",
                            "diamonds": 37
                        },
                        "21": {
                            "name": "road",
                            "diamonds": 37
                        },
                        "22": {
                            "name": "gazebo",
                            "diamonds": 42
                        },
                        "23": {
                            "name": "letterBox",
                            "diamonds": 42
                        },
                        "24": {
                            "name": "masterClass",
                            "diamonds": 42
                        },
                        "25": {
                            "name": "lightFence",
                            "diamonds": 25
                        }
                    }
                },
                "2": {
                    "name": "farm",
                    "rewards": {
                        "8": {
                            "coins": 75
                        },
                        "16": {
                            "coins": 145
                        },
                        "25": {
                            "coins": 295
                        }
                    },
                    "actions": {
                        "1": {
                            "name": "mudGazebo",
                            "diamonds": 10
                        },
                        "2": {
                            "name": "balkHouse",
                            "diamonds": 10
                        },
                        "3": {
                            "name": "delivery",
                            "diamonds": 10
                        },
                        "4": {
                            "name": "wellWater",
                            "diamonds": 17
                        },
                        "5": {
                            "name": "doorDecor",
                            "diamonds": 17
                        },
                        "6": {
                            "name": "drinker",
                            "diamonds": 17
                        },
                        "7": {
                            "name": "bucketWell",
                            "diamonds": 24
                        },
                        "8": {
                            "name": "ladder",
                            "diamonds": 24
                        },
                        "9": {
                            "name": "cow",
                            "diamonds": 24
                        },
                        "10": {
                            "name": "plant",
                            "diamonds": 31
                        },
                        "11": {
                            "name": "roof",
                            "diamonds": 31
                        },
                        "12": {
                            "name": "lantern",
                            "diamonds": 31
                        },
                        "13": {
                            "name": "straw",
                            "diamonds": 38
                        },
                        "14": {
                            "name": "vane",
                            "diamonds": 38
                        },
                        "15": {
                            "name": "pond",
                            "diamonds": 38
                        },
                        "16": {
                            "name": "orderSeeds",
                            "diamonds": 45
                        },
                        "17": {
                            "name": "gates",
                            "diamonds": 45
                        },
                        "18": {
                            "name": "duck",
                            "diamonds": 45
                        },
                        "19": {
                            "name": "listOfSongs",
                            "diamonds": 52
                        },
                        "20": {
                            "name": "wallsPaint",
                            "diamonds": 52
                        },
                        "21": {
                            "name": "plantTree",
                            "diamonds": 52
                        },
                        "22": {
                            "name": "scarecrowDecor",
                            "diamonds": 59
                        },
                        "23": {
                            "name": "flowers",
                            "diamonds": 59
                        },
                        "24": {
                            "name": "organizer",
                            "diamonds": 59
                        },
                        "25": {
                            "name": "road",
                            "diamonds": 42
                        }
                    }
                },
                "3": {
                    "name": "cafe",
                    "rewards": {
                        "8": {
                            "coins": 95
                        },
                        "16": {
                            "coins": 195
                        },
                        "25": {
                            "coins": 385
                        }
                    },
                    "actions": {
                        "1": {
                            "name": "roofCafe",
                            "diamonds": 13
                        },
                        "2": {
                            "name": "signboard",
                            "diamonds": 13
                        },
                        "3": {
                            "name": "menu",
                            "diamonds": 13
                        },
                        "4": {
                            "name": "window",
                            "diamonds": 22
                        },
                        "5": {
                            "name": "canopy",
                            "diamonds": 22
                        },
                        "6": {
                            "name": "cat",
                            "diamonds": 22
                        },
                        "7": {
                            "name": "ornaments",
                            "diamonds": 31
                        },
                        "8": {
                            "name": "barStool",
                            "diamonds": 31
                        },
                        "9": {
                            "name": "lecture",
                            "diamonds": 31
                        },
                        "10": {
                            "name": "cafeStairs",
                            "diamonds": 40
                        },
                        "11": {
                            "name": "cafeFood",
                            "diamonds": 40
                        },
                        "12": {
                            "name": "chairsDecor",
                            "diamonds": 40
                        },
                        "13": {
                            "name": "parrot",
                            "diamonds": 50
                        },
                        "14": {
                            "name": "pavilionPlants",
                            "diamonds": 50
                        },
                        "15": {
                            "name": "railing",
                            "diamonds": 50
                        },
                        "16": {
                            "name": "windowFlower",
                            "diamonds": 59
                        },
                        "17": {
                            "name": "road",
                            "diamonds": 59
                        },
                        "18": {
                            "name": "orderIngredients",
                            "diamonds": 59
                        },
                        "19": {
                            "name": "cafePlants",
                            "diamonds": 68
                        },
                        "20": {
                            "name": "lantern",
                            "diamonds": 68
                        },
                        "21": {
                            "name": "serving",
                            "diamonds": 77
                        },
                        "22": {
                            "name": "surfboard",
                            "diamonds": 77
                        },
                        "23": {
                            "name": "collectDecorations",
                            "diamonds": 77
                        },
                        "24": {
                            "name": "lanternJellyfish",
                            "diamonds": 77
                        },
                        "25": {
                            "name": "menuBoard",
                            "diamonds": 64
                        }
                    }
                },
                "4": {
                    "name": "lighthouse",
                    "rewards": {
                        "8": {
                            "coins": 120
                        },
                        "16": {
                            "coins": 240
                        },
                        "25": {
                            "coins": 480
                        }
                    },
                    "actions": {
                        "1": {
                            "name": "seagull",
                            "diamonds": 16
                        },
                        "2": {
                            "name": "jetty",
                            "diamonds": 16
                        },
                        "3": {
                            "name": "paperLanterns",
                            "diamonds": 16
                        },
                        "4": {
                            "name": "decorLighthouse",
                            "diamonds": 27
                        },
                        "5": {
                            "name": "railing",
                            "diamonds": 27
                        },
                        "6": {
                            "name": "weatherForecast",
                            "diamonds": 27
                        },
                        "7": {
                            "name": "window",
                            "diamonds": 39
                        },
                        "8": {
                            "name": "feedFish",
                            "diamonds": 39
                        },
                        "9": {
                            "name": "signboard",
                            "diamonds": 39
                        },
                        "10": {
                            "name": "barrel",
                            "diamonds": 50
                        },
                        "11": {
                            "name": "boat",
                            "diamonds": 50
                        },
                        "12": {
                            "name": "lantern",
                            "diamonds": 50
                        },
                        "13": {
                            "name": "lightLighthouse",
                            "diamonds": 62
                        },
                        "14": {
                            "name": "paintBoat",
                            "diamonds": 62
                        },
                        "15": {
                            "name": "flowers",
                            "diamonds": 62
                        },
                        "16": {
                            "name": "paintLighthouse",
                            "diamonds": 74
                        },
                        "17": {
                            "name": "helpPuppy",
                            "diamonds": 74
                        },
                        "18": {
                            "name": "buyTickets",
                            "diamonds": 74
                        },
                        "19": {
                            "name": "garlandLighthouse",
                            "diamonds": 85
                        },
                        "20": {
                            "name": "flagpole",
                            "diamonds": 85
                        },
                        "21": {
                            "name": "paintShips",
                            "diamonds": 85
                        },
                        "22": {
                            "name": "interview",
                            "diamonds": 97
                        },
                        "23": {
                            "name": "bell",
                            "diamonds": 97
                        },
                        "24": {
                            "name": "startFestival",
                            "diamonds": 97
                        },
                        "25": {
                            "name": "nameBoat",
                            "diamonds": 100
                        }
                    }
                }
            }
        },
		"6": {
            'name': 'villaAutumn',
            'parts': {
                '1': {
                    'name': 'house',
                    'rewards': {
                        '1': {
                            'coins': 2
                        },
                        '4': {
                            'coins': 3
                        },
                        '7': {
                            'coins': 5
                        },
                        '10': {
                            'coins': 6
                        },
                        '13': {
                            'coins': 8
                        },
                        '16': {
                            'coins': 9
                        },
                        '19': {
                            'coins': 11
                        },
                        '22': {
                            'coins': 13
                        },
                        '25': {
                            'coins': 15
                        }
                    },
                    'actions': {
                        '1': {
                            'name': 'mowGrass',
                            'diamonds': 1
                        },
                        '2': {
                            'name': 'ladder',
                            'diamonds': 1
                        },
                        '3': {
                            'name': 'ivy',
                            'diamonds': 1
                        },
                        '4': {
                            'name': 'leaves',
                            'diamonds': 3
                        },
                        '5': {
                            'name': 'railings',
                            'diamonds': 3
                        },
                        '6': {
                            'name': 'garland',
                            'diamonds': 3
                        },
                        '7': {
                            'name': 'removeNettles',
                            'diamonds': 4
                        },
                        '8': {
                            'name': 'cleanPorch',
                            'diamonds': 4
                        },
                        '9': {
                            'name': 'route',
                            'diamonds': 4
                        },
                        '10': {
                            'name': 'yellowedLeaves',
                            'diamonds': 5
                        },
                        '11': {
                            'name': 'baskets',
                            'diamonds': 5
                        },
                        '12': {
                            'name': 'pavingDecor',
                            'diamonds': 5
                        },
                        '13': {
                            'name': 'treeWater',
                            'diamonds': 6
                        },
                        '14': {
                            'name': 'snacks',
                            'diamonds': 6
                        },
                        '15': {
                            'name': 'border',
                            'diamonds': 6
                        },
                        '16': {
                            'name': 'squirrel',
                            'diamonds': 8
                        },
                        '17': {
                            'name': 'wallPaint',
                            'diamonds': 8
                        },
                        '18': {
                            'name': 'road',
                            'diamonds': 8
                        },
                        '19': {
                            'name': 'blog',
                            'diamonds': 9
                        },
                        '20': {
                            'name': 'patterns',
                            'diamonds': 9
                        },
                        '21': {
                            'name': 'flowers',
                            'diamonds': 9
                        },
                        '22': {
                            'name': 'lights',
                            'diamonds': 10
                        },
                        '23': {
                            'name': 'tiles',
                            'diamonds': 10
                        },
                        '24': {
                            'name': 'hedge',
                            'diamonds': 10
                        },
                        '25': {
                            'name': 'postcards',
                            'diamonds': 11
                        },
                        '26': {
                            'name': 'decoratePorch',
                            'diamonds': 11
                        }
                    }
                },
                '2': {
                    'name': 'garden',
                    'rewards': {
                        '1': {
                            'coins': 3
                        },
                        '4': {
                            'coins': 4
                        },
                        '7': {
                            'coins': 7
                        },
                        '10': {
                            'coins': 8
                        },
                        '13': {
                            'coins': 11
                        },
                        '16': {
                            'coins': 13
                        },
                        '19': {
                            'coins': 16
                        },
                        '22': {
                            'coins': 18
                        },
                        '25': {
                            'coins': 21
                        }
                    },
                    'actions': {
                        '1': {
                            'name': 'flowers',
                            'diamonds': 1
                        },
                        '2': {
                            'name': 'weeds',
                            'diamonds': 1
                        },
                        '3': {
                            'name': 'plaid',
                            'diamonds': 1
                        },
                        '4': {
                            'name': 'cleanFence',
                            'diamonds': 1
                        },
                        '5': {
                            'name': 'footprints',
                            'diamonds': 1
                        },
                        '6': {
                            'name': 'decorateStone',
                            'diamonds': 1
                        },
                        '7': {
                            'name': 'cleanArch',
                            'diamonds': 1
                        },
                        '8': {
                            'name': 'lanternPumpkin',
                            'diamonds': 1
                        },
                        '9': {
                            'name': 'fountain',
                            'diamonds': 1
                        },
                        '10': {
                            'name': 'herbs',
                            'diamonds': 1
                        },
                        '11': {
                            'name': 'scenario',
                            'diamonds': 1
                        },
                        '12': {
                            'name': 'stoneFloor',
                            'diamonds': 1
                        },
                        '13': {
                            'name': 'crow',
                            'diamonds': 1
                        },
                        '14': {
                            'name': 'tile',
                            'diamonds': 1
                        },
                        '15': {
                            'name': 'flashlight',
                            'diamonds': 1
                        },
                        '16': {
                            'name': 'beds',
                            'diamonds': 1
                        },
                        '17': {
                            'name': 'stubBorder',
                            'diamonds': 1
                        },
                        '18': {
                            'name': 'decoratePaving',
                            'diamonds': 1
                        },
                        '19': {
                            'name': 'volunteer',
                            'diamonds': 1
                        },
                        '20': {
                            'name': 'feedBird',
                            'diamonds': 1
                        },
                        '21': {
                            'name': 'benchFlower',
                            'diamonds': 1
                        },
                        '22': {
                            'name': 'photo',
                            'diamonds': 1
                        },
                        '23': {
                            'name': 'paintBorder',
                            'diamonds': 1
                        },
                        '24': {
                            'name': 'fountainWater',
                            'diamonds': 1
                        },
                        '25': {
                            'name': 'scarecrow',
                            'diamonds': 1
                        },
                        '26': {
                            'name': 'bench',
                            'diamonds': 1
                        }
                    }
                },
                '3': {
                    'name': 'barn',
                    'rewards': {
                        '1': {
                            'coins': 3
                        },
                        '4': {
                            'coins': 4
                        },
                        '7': {
                            'coins': 7
                        },
                        '10': {
                            'coins': 8
                        },
                        '13': {
                            'coins': 11
                        },
                        '16': {
                            'coins': 14
                        },
                        '19': {
                            'coins': 17
                        },
                        '22': {
                            'coins': 19
                        },
                        '25': {
                            'coins': 22
                        }
                    },
                    'actions': {
                        '1': {
                            'name': 'lantern',
                            'diamonds': 2
                        },
                        '2': {
                            'name': 'ribbon',
                            'diamonds': 2
                        },
                        '3': {
                            'name': 'basket',
                            'diamonds': 2
                        },
                        '4': {
                            'name': 'leanto',
                            'diamonds': 2
                        },
                        '5': {
                            'name': 'pond',
                            'diamonds': 2
                        },
                        '6': {
                            'name': 'recipe',
                            'diamonds': 2
                        },
                        '7': {
                            'name': 'solarPanels',
                            'diamonds': 2
                        },
                        '8': {
                            'name': 'lilies',
                            'diamonds': 2
                        },
                        '9': {
                            'name': 'paintBenches',
                            'diamonds': 2
                        },
                        '10': {
                            'name': 'puppy',
                            'diamonds': 2
                        },
                        '11': {
                            'name': 'pondPlants',
                            'diamonds': 2
                        },
                        '12': {
                            'name': 'grillSet',
                            'diamonds': 2
                        },
                        '13': {
                            'name': 'decorateEntrance',
                            'diamonds': 2
                        },
                        '14': {
                            'name': 'feedFish',
                            'diamonds': 2
                        },
                        '15': {
                            'name': 'groceryList',
                            'diamonds': 2
                        },
                        '16': {
                            'name': 'paintBar',
                            'diamonds': 2
                        },
                        '17': {
                            'name': 'kitty',
                            'diamonds': 2
                        },
                        '18': {
                            'name': 'setTable',
                            'diamonds': 2
                        },
                        '19': {
                            'name': 'carPaint',
                            'diamonds': 2
                        },
                        '20': {
                            'name': 'swing',
                            'diamonds': 2
                        },
                        '21': {
                            'name': 'weeds',
                            'diamonds': 2
                        },
                        '22': {
                            'name': 'herbarium',
                            'diamonds': 2
                        },
                        '23': {
                            'name': 'paintSwing',
                            'diamonds': 2
                        },
                        '24': {
                            'name': 'seeds',
                            'diamonds': 2
                        },
                        '25': {
                            'name': 'wheels',
                            'diamonds': 2
                        },
                        '26': {
                            'name': 'paving',
                            'diamonds': 2
                        }
                    }
                },
                '4': {
                    'name': 'beach',
                    'rewards': {
                        '1': {
                            'coins': 3
                        },
                        '4': {
                            'coins': 4
                        },
                        '7': {
                            'coins': 7
                        },
                        '10': {
                            'coins': 9
                        },
                        '13': {
                            'coins': 11
                        },
                        '16': {
                            'coins': 14
                        },
                        '19': {
                            'coins': 17
                        },
                        '22': {
                            'coins': 20
                        },
                        '25': {
                            'coins': 23
                        }
                    },
                    'actions': {
                        '1': {
                            'name': 'leaves',
                            'diamonds': 2
                        },
                        '2': {
                            'name': 'promotion',
                            'diamonds': 2
                        },
                        '3': {
                            'name': 'weeds',
                            'diamonds': 2
                        },
                        '4': {
                            'name': 'crab',
                            'diamonds': 2
                        },
                        '5': {
                            'name': 'trashWater',
                            'diamonds': 2
                        },
                        '6': {
                            'name': 'carriage',
                            'diamonds': 2
                        },
                        '7': {
                            'name': 'well',
                            'diamonds': 2
                        },
                        '8': {
                            'name': 'fishingRods',
                            'diamonds': 2
                        },
                        '9': {
                            'name': 'stone',
                            'diamonds': 2
                        },
                        '10': {
                            'name': 'tiles',
                            'diamonds': 2
                        },
                        '11': {
                            'name': 'seagull',
                            'diamonds': 2
                        },
                        '12': {
                            'name': 'letter',
                            'diamonds': 2
                        },
                        '13': {
                            'name': 'paving',
                            'diamonds': 2
                        },
                        '14': {
                            'name': 'trash',
                            'diamonds': 2
                        },
                        '15': {
                            'name': 'lantern',
                            'diamonds': 2
                        },
                        '16': {
                            'name': 'fence',
                            'diamonds': 2
                        },
                        '17': {
                            'name': 'marathon',
                            'diamonds': 2
                        },
                        '18': {
                            'name': 'plants',
                            'diamonds': 2
                        },
                        '19': {
                            'name': 'fertilizers',
                            'diamonds': 2
                        },
                        '20': {
                            'name': 'palmLeaves',
                            'diamonds': 2
                        },
                        '21': {
                            'name': 'ladder',
                            'diamonds': 2
                        },
                        '22': {
                            'name': 'lanternSecond',
                            'diamonds': 2
                        },
                        '23': {
                            'name': 'fair',
                            'diamonds': 2
                        },
                        '24': {
                            'name': 'railing',
                            'diamonds': 2
                        },
                        '25': {
                            'name': 'sunLoungers',
                            'diamonds': 2
                        },
                        '26': {
                            'name': 'hammock',
                            'diamonds': 2
                        }
                    }
                }
            }
        }
	},
	web: {
		dev: {
			vk: {
				interstitials: dev_vk_interstitials
			},
			ok: {
				interstitials: dev_vk_interstitials
			},
			yandex: {
				interstitials: dev_vk_interstitials
			}
		},
		release: {
			vk: {
				interstitials: release_vk_interstitials
			},
			ok: {
				interstitials: release_vk_interstitials
			},
			yandex: {
				interstitials: release_vk_interstitials
			}
		}
	}
}