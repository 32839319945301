import { Modal, Button, Form, Select, DatePicker, Alert, Checkbox } from 'antd'
import * as _ from 'lodash-es'
import { useAppData } from '../../../pages/Project'
import gameFridays from './gameFridays'
import locale from 'antd/es/date-picker/locale/ru_RU'
import 'dayjs/locale/ru'
import { useAuth } from '../../../../provider/AuthProvider'
import dayjs from 'dayjs'
import { useImmer } from 'use-immer'
import ActiveSales from './ActiveSales'
import salesConfig from './salesConfig'
import setPromo from './setPromo'
import { useEffect } from 'react'

export default function SalesModal({ isModalOpen, handleCancel }) {
	const [ form ] = Form.useForm()
	const { user } = useAuth()
	const app = useAppData()
	const [ formData, setFormData ] = useImmer({})
	const [ promotions, setPromotions ] = useImmer(app.allUserData.promotions)

	useEffect(() => {
		app.allUserData.promotions = promotions
	// eslint-disable-next-line
	}, [ promotions ])

	const onFormFinish = async ({ saleID, saleDates, gameFridayID, stopTime, gameFridayAlt }) => {
		const start_date = Math.floor(saleDates[0].toDate().getTime() / 1e3)
		const end_date = Math.floor(saleDates[1].toDate().getTime() / 1e3)

		setPromo(app, user, {
			start_date,
			end_date,
			saleID,
			gameFridayID,
			stopTime,
			setPromotions,
			gameFridayAlt
		})
	}

	const initialValues = {
		saleDates: [
			dayjs(new Date()),
			dayjs(new Date().setHours(23, 59, 59))
		]
	}

	const salesOptions = _.map(salesConfig, (name, saleID) => <Select.Option key={saleID} value={saleID}>{ name }</Select.Option>)

	let timePeriodWasSet = formData.saleDates

	if (!_.isEmpty(promotions) && !formData.saleDates) {
		const promotion = _.find(app.allUserData.promotions, promotion => promotion.type === formData.saleID)

		if (promotion) {
			const { start_date, end_date } = promotion

			initialValues.saleDates = [
				dayjs(start_date),
				dayjs(end_date)
			]

			_.defer(() => form.setFieldsValue(initialValues))

			timePeriodWasSet = true
		}
	}

	const disablePromotion = promoID => {
		promoID = Number(promoID)

		const promotion = _.find(app.allUserData.promotions, promotion => promotion.type === promoID)

		if (promotion) {
			const data = {
				start_date: (new Date().getTime() - 60e3) / 1e3,
				end_date: (new Date().getTime() - 60e3) / 1e3,
				saleID: promoID,
				setPromotions
			}

			if (promoID === 0) {
				data.gameFridayID = 1
			}

			setPromo(app, user, data)

			_.defer(() => form.resetFields())
		}
	}

	return (
		<Modal
			className="salesModal"
			destroyOnClose={true}
			width="auto"
			style={{
				left: '50%',
				display: 'inline-block',
				transform: 'translateX(-50%)'
			}}
			transitionName=""
			title={ `Акции для ${ app.config.name.toUpperCase() }` }
			open={isModalOpen}
			footer=""
			onCancel={handleCancel}>

			<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
				<Form
					style={{ marginTop: 20, width: 350 }}
					form={form}
					layout='vertical'
					onValuesChange={data => {
						data = _.omitBy(data, _.isNull)

						if (!_.isEmpty(data)) {
							setFormData(prevData => _.assign(prevData, data))
						}
					}}
					initialValues={initialValues}
					onFinish={onFormFinish}
				>
					<Form.Item
						name="saleID"
						label="Акция"
						rules={[
							{
								required: true,
								message: 'Обязательное поле',
							}
						]}
					>
						<Select
							showSearch
							filterOption={(input, option) => _.includes(option.children[2].toLowerCase(), input) || _.includes(option.key, input)}
						>
							{ salesOptions }
						</Select>
					</Form.Item>
					
					<Form.Item
						noStyle
						shouldUpdate={(prevValues, currentValues) => prevValues.saleID !== currentValues.saleID}
					>
					{({ getFieldValue }) => {
						const saleID = Number(getFieldValue('saleID'))

						if (saleID === 0) {
							const salesOptions = _.map(gameFridays, (name, saleID) => <Select.Option key={saleID} value={saleID}>{ saleID } { name }</Select.Option>)

							let gameFridayID,
								gameFridayAlt

							if (!_.isEmpty(app.allUserData.promotions)) {
								const promotion = _.find(app.allUserData.promotions, promotion => promotion.type === saleID)
						
								if (promotion) {
									gameFridayID = String(promotion.promo_id)
									gameFridayAlt = String(promotion.alternative)
								}
							}

							return <>
								<Alert
									message={<>Доступ с точки 1-5.<br/>У разработчиков она называется Игровая пятница (GameFriday).</>}
									type="info"
									style={{ margin: '20px 0' }}
								/>

								<Form.Item
									name="gameFridayID"
									label="Вариант"
									rules={[
										{
											required: true,
											message: 'Обязательное поле',
										}
									]}
									initialValue={gameFridayID}
								>
									<Select
										showSearch
										filterOption={(input, option) => _.includes(option.children[2].toLowerCase(), input) || _.includes(option.key, input)}
									>{ salesOptions }</Select>
								</Form.Item>

								<Form.Item
									initialValue={gameFridayAlt}
									name="gameFridayAlt"
									valuePropName="checked"
									label={<>Альтернативная<br/>(акция с уменьшенной стоимостью покупок)</>}
								>
									<Checkbox checked={gameFridayAlt}/>
								</Form.Item>
							</>
						}
					}}
					</Form.Item>

					<Form.Item
						noStyle
						shouldUpdate={(prevValues, currentValues) => prevValues.saleID !== currentValues.saleID}
					>
					{({ getFieldValue }) => {
						const saleID = getFieldValue('saleID')

						if (saleID === 3) {
							return <>
								<Form.Item
									name="stopTime"
									label="Время, до которого можно получить призы"
									rules={[
										{
											required: true,
											message: 'Обязательное поле'
										}
									]}
								>
									<DatePicker showTime showNow locale={locale}/>
								</Form.Item>
							</>
						}
					}}
					</Form.Item>

					<Form.Item
						noStyle
						shouldUpdate={(prevValues, currentValues) => prevValues.saleID !== currentValues.saleID}
					>
					{({ getFieldValue }) => {
						const saleID = getFieldValue('saleID')
						
						if (saleID && saleID !== 'none') {
							return <>
								<Form.Item
									name="saleDates"
									label="Период действия"
									rules={[
										{
											required: true,
											message: 'Обязательное поле'
										}
									]}
								>
									<DatePicker.RangePicker showTime locale={locale}/>
								</Form.Item>

								<Form.Item noStyle>
								{ !timePeriodWasSet
									&& <Alert
											style={{ marginBottom: 20 }}
											type="warning"
											message={<>Нет данных о выбранной акции.<br/>Период выставлен автоматически.</>}/> }
								</Form.Item>
							</>
						}
					}}
					</Form.Item>

					<Button
						type="primary"
						className="saveBtn"
						htmlType="submit"
					>
						Сохранить
					</Button>
				</Form>

				<ActiveSales promotions={promotions} disablePromotion={disablePromotion}/>
			</div>
		</Modal>
	)
}