import { Modal, Input, Button, Form, Empty } from 'antd'
import * as _ from 'lodash-es'
import { useState } from 'react'
import AppConfig from '../../../config/AppConfig'
import { useAuth } from '../../../../provider/AuthProvider'
import { useAppData } from '../../../pages/Project'
import { useForm } from '../../AppForm'
import Snapshot from './Snapshot'
import zebomba from '../../../../tools/zebomba'

export default function SnapshotsModal({ isModalOpen, handleCancel }) {
	const app = useAppData()
	const [ snapshots, setSnapshots ] = useState([ ...app.snapshots ])
	const { user } = useAuth()
	const { fields, setAppFormFields } = useForm()

	const sendSnapshots = async (snapshots) => {
		const response = await fetch(AppConfig.api.setSnapshot, {
			method: 'POST',
			body: new URLSearchParams({
				access_token: user.token,
				id: user.id,
				app_name: app.config.name,
				snapshots: JSON.stringify(snapshots)
			})
		})

		const data = await response.json()

		app.snapshots = snapshots

		if (data.success === true) {
			zebomba.message.success('Наборы успешно обновлены')
		}
		else {
			zebomba.message.error('Что-то пошло не так...')
		}
	}

	const addSnapshot = data => {
		const snapshot = {
			label: data.snapshot,
			fields,
			time: Date.now()
		}

		setSnapshots(prevSnaps => {
			const newSnaps = [ ...prevSnaps, snapshot ]

			sendSnapshots(newSnaps)

			return newSnaps
		})
	}

	const removeSnapshot = snapshot => {
		const newSnaps = _.without(snapshots, snapshot)

		setSnapshots(newSnaps)
		sendSnapshots(newSnaps)
	}

	const onApply = snapshot => {
		setAppFormFields(snapshot.fields)
	}

	const snapshotsItems = _.map(snapshots, (snapshot, index) => <Snapshot
			key={index}
			snapshot={snapshot}
			onRemove={() => removeSnapshot(snapshot)}
			onApply={() => onApply(snapshot)}
			/>)

	return (
		<Modal
			className="snapshotsModal"
			width={600}
			destroyOnClose={true}
			transitionName=""
			title={ `Наборы данных (слепки) для ${ app.config.name.toUpperCase() }` }
			open={isModalOpen}
			footer=""
			onCancel={handleCancel}>

			<div className="snapshots">{ (snapshotsItems.length && snapshotsItems) || <Empty /> }</div>

			<Form onFinish={addSnapshot} className="newSnapshotForm">
				<Form.Item
					name="snapshot"
					rules={[
						{
						required: true,
						message: '',
						}
					]}
					>

					<Input placeholder='Название нового набора (можно с пробелами)'/>
				</Form.Item>

				<Button type='primary' htmlType="submit" className="addBtn">Добавить</Button>
			</Form>

		</Modal>
	)
}