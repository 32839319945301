import * as _ from 'lodash-es'
import config from "../configs/apps/config"
import AppConfig from '../components/config/AppConfig'

const socials = [
	'vk',
	'ok',
	'fs',
	'fb',
	'mm',
	'yandex',
	'playmarket',
	'vkplay',
	'cg',
	'tg'
]

class Apps {
	#apps = {

	}

	constructor( availableProjects ) {
		this.byKeys = availableProjects

		delete this.byKeys?.features

		const apps = []

		_.forEach(config, (app, name) => {
			_.forEach(_.keys(app.links), platform => {
				_.forEach([ 'dev', 'release' ], version => {
					let appName = `${ name }_${ platform }_${ version }`
		
					if (platform === 'web') {
						_.forEach(socials, social => {
							const app = `${ appName }_${ social }`
		
							apps.push(app)
						})
					}
					else {
						apps.push(appName)
					}
				})
			})
		})

		_.forEach(apps, appName => {
			const serverData = _.get(availableProjects, appName.replace(/_/g, '.'))

			if (serverData) {
				delete serverData.dbConnectionId
				delete serverData.appConfigId
			}

			this.#apps[appName] = new App(appName, serverData)
		})

		this.byName = this.#apps

		this.available = _.reduce(this.#apps, (apps, app) => {
			if (app.isAvailable) {
				apps[app.fullName] = app
			}

			return apps
		}, {})
	}

	sortNames( names ) {
		return _.sortBy(names, item => _.indexOf(AppConfig.appsOrder, item.split('_')[0]))
	}

	get list() {
		return this.#apps
	}

	get allNamesList() {
		return this.sortNames(_.keys(this.byName))
	}
}

class App {
	/** Айдишники профилей, которым предоставляется доступ */
	ids = null

	isAvailable = false

	constructor( fullName, serverData ) {
		if (serverData) {
			this.isAvailable = true

			this.ids = serverData.ids.split(',')
		}

		const [ name, platform, version, social ] = fullName.split('_')

		this.name = name
		this.platform = platform
		this.version = version
		this.social = social
		this.fullName = fullName
	}

	get dataForRequest() {
		const data = {}

		if (this.name) {
			data.app_name = this.name
		}

		if (this.platform) {
			data.platform = this.platform
		}

		if (this.version) {
			data.version = this.version
		}

		if (this.social) {
			data.soc_name = this.social
		}

		return data
	}
}

export default Apps