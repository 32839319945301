import { useAuth } from "../../provider/AuthProvider"
import FormField from "../form/FormField"
import { createElement } from "react"
import { useImmer } from "use-immer"
import * as _ from 'lodash-es'
import AppConfig from "../config/AppConfig"
import zebomba from "../../tools/zebomba"
import { useNavigate } from "react-router-dom"
import { Button } from "antd"

export default function AdditionPage({ data }) {
	const navigate = useNavigate()
	const { user } = useAuth()
	const availableApps = user.apps.available
	const [ fieldsData, setFieldsData ] = useImmer({})
	const [ isRequestInProgress, setIsRequestInProgress ] = useImmer(false)

	document.title = data.title

	const onFieldChange = field => {
		setFieldsData(prevData => {
			const newData = _.assign({}, prevData)

			if (field.config.type === 'select') {
				newData[field.name] = field.config.options[field.value]
			}
			else {
				newData[field.name] = String(field.value).trim()
			}

			return newData
		})
	}

	const tryAddition = async () => {
		const setInProgress = _.delay(() => setIsRequestInProgress(true), 300)

		const [ appName, appPlatform, appVersion, appSocial ] = fieldsData.appID.split('_')

		const response = await fetch(AppConfig.api.additionUser, {
			method: 'POST',
			body: new URLSearchParams({
				access_token: user.token,
				id: user.id,
				app_name: appName,
				platform: appPlatform,
				version: appVersion,
				soc_name: appSocial,
				admin_id: fieldsData.adminID,
				profile_ids: fieldsData.profileID
			})
		})

		const data = await response.json()
		
		clearTimeout(setInProgress)
		setIsRequestInProgress(false)

		if (data.success) {
			let appURL = `/apps/${ fieldsData.appID }/${ fieldsData.profileID }`

			navigate(appURL)
		}
		else if (data.error_key) {
			zebomba.message.error(data.error)
		}
	}

	// let fromUserIDField,
	// 	toOptions

	// if (fieldsData.appID) {
	// 	const fieldProps = {
	// 		data: {
	// 			name: 'fromUserID',
	// 			label: 'Откуда копировать (ID пользователя)',
	// 			type: 'text'
	// 		},
	// 		onFieldChange: field => { onFieldChange(field) },
	// 		defaultValue: fieldsData.fromUserID
	// 	}

	// 	if (availableApps[fieldsData.appID]) {
	// 		toOptions = availableApps[fieldsData.appID].ids

	// 		if (!fieldsData.toUserID) {
	// 			setFieldsData(fieldsData => {
	// 				fieldsData.toUserID = toOptions[0]
	// 			})
	// 		}

	// 		fromUserIDField = createElement(FormField, fieldProps)
	// 	}
	// 	else {
	// 		fromUserIDField = <div>
	// 			Отсутствует твой ID для этого приложения
	// 		</div>
	// 	}
	// }

	return (
		<div className="additionForm form">
			<div className="title">Добавление игрового профиля</div>

			<FormField
				data={{ name: 'appID', label: 'Приложение', type: 'select', options: user.apps.allNamesList, hideIndex: true, showSearch: true }}
				onFieldChange={onFieldChange} />

			<FormField
				data={{ name: 'adminID', label: 'ID пользователя в системе', type: 'number'}}
				onFieldChange={field => onFieldChange(field)}
				defaultValue={fieldsData.adminID}
				/>

			<FormField
				data={{ name: 'profileID', label: 'ID игрового профиля', type: 'number'}}
				onFieldChange={field => onFieldChange(field)}
				defaultValue={fieldsData.profileID}
				/>

			<Button
				type="primary"
				className="button additionBtn"
				onClick={tryAddition}
				loading={isRequestInProgress}
				disabled={!(fieldsData.appID && fieldsData.adminID && fieldsData.profileID)}>Добавить</Button>
		</div>
	)
}