import cargo from './cargo.json'
import find from './find.json'
import words from './words.json'
import water from './water.json'
import fish from './fish.json'
import cat from './cat.json'
import trip from './trip.json'
import alice from './alice.json'
import villa from './villa.json'
import sweet from './sweet.json'
import alien from './alien.json'
import house from './house.json'
import pirate from './pirate.json'
import indi from './indi.json'
import wg from './wg.json'
import uw from './uw.json'

export default {
	find,
	cat,
	trip,
	words,
	wg,
	villa,
	pirate,
	alice,
	indi,
	sweet,
	house,
	fish,
	cargo,
	water,
	alien,
	uw
}