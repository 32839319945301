const dev_vk_interstitials = {
    "loadingScreen": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 2
        }
    },
    "beforeBattle": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 12
        }
    },
    "afterBattle": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 5,
            "no_payment_count": 0,
            "rev": 0
        }
    },
    "afterBattleRound": {
        "probability": 100,
        "roundsProbability": {
            "1": 100,
            "2": 100
        },
        "rules": {
            "map": 1,
            "point": 8,
            "no_payment_count": 0,
            "rev": 0
        }
    }
}

const release_vk_interstitials = {
    "loadingScreen": {
        "probability": 0,
        "rules": {
            "map": 1,
            "point": 2
        }
    },
    "beforeBattle": {
        "probability": 0,
        "rules": {
            "map": 1,
            "point": 12
        }
    },
    "afterBattle": {
        "probability": 100,
        "rules": {
            "map": 1,
            "point": 5,
            "no_payment_count": 0,
            "rev": 0
        }
    },
    "afterBattleRound": {
        "probability": 100,
        "roundsProbability": {
            "1": 100,
            "2": 100
        },
        "rules": {
            "map": 1,
            "point": 8,
            "no_payment_count": 0,
            "rev": 0
        }
    }
}

export default {
	locationsInfo: {
		"1": {
            "name": "aztec",
            "parts": {
                "1": {
                    "name": "market",
                    "rewards": {
                        "8": {
                            "coins": 20
                        },
                        "16": {
                            "coins": 40
                        },
                        "26": {
                            "coins": 80
                        }
                    },
                    "actions": {
                        "1": {
                            "name": "garbage",
                            "diamonds": 3
                        },
                        "2": {
                            "name": "oldPots",
                            "diamonds": 3
                        },
                        "3": {
                            "name": "clearPath",
                            "diamonds": 3
                        },
                        "4": {
                            "name": "stones",
                            "diamonds": 3
                        },
                        "5": {
                            "name": "removeBarrel",
                            "diamonds": 3
                        },
                        "6": {
                            "name": "road",
                            "diamonds": 3
                        },
                        "7": {
                            "name": "walls",
                            "diamonds": 3
                        },
                        "8": {
                            "name": "greenhouseRoof",
                            "diamonds": 3
                        },
                        "9": {
                            "name": "columns",
                            "diamonds": 3
                        },
                        "10": {
                            "name": "roof",
                            "diamonds": 3
                        },
                        "11": {
                            "name": "ladder",
                            "diamonds": 1
                        },
                        "12": {
                            "name": "interiorDecor",
                            "diamonds": 1
                        },
                        "13": {
                            "name": "wallsDecor",
                            "diamonds": 1
                        },
                        "14": {
                            "name": "wallsCorner",
                            "diamonds": 5
                        },
                        "15": {
                            "name": "barnRoof",
                            "diamonds": 5
                        },
                        "16": {
                            "name": "box",
                            "diamonds": 5
                        },
                        "17": {
                            "name": "calmGoose",
                            "diamonds": 5
                        },
                        "18": {
                            "name": "bench",
                            "diamonds": 5
                        },
                        "19": {
                            "name": "goodsBox",
                            "diamonds": 5
                        },
                        "20": {
                            "name": "fence",
                            "diamonds": 2
                        },
                        "21": {
                            "name": "gate",
                            "diamonds": 2
                        },
                        "22": {
                            "name": "gateDecor",
                            "diamonds": 2
                        },
                        "23": {
                            "name": "fruits",
                            "diamonds": 2
                        },
                        "24": {
                            "name": "flowers",
                            "diamonds": 2
                        },
                        "25": {
                            "name": "vases",
                            "diamonds": 3
                        },
                        "26": {
                            "name": "signboard",
                            "diamonds": 3
                        }
                    }
                },
                "2": {
                    "name": "base",
                    "rewards": {
                        "8": {
                            "coins": 25
                        },
                        "16": {
                            "coins": 50
                        },
                        "26": {
                            "coins": 90
                        }
                    },
                    "actions": {
                        "1": {
                            "name": "stones",
                            "diamonds": 1
                        },
                        "2": {
                            "name": "barn",
                            "diamonds": 1
                        },
                        "3": {
                            "name": "bridge",
                            "diamonds": 1
                        },
                        "4": {
                            "name": "angle",
                            "diamonds": 1
                        },
                        "5": {
                            "name": "stair",
                            "diamonds": 3
                        },
                        "6": {
                            "name": "canopy",
                            "diamonds": 3
                        },
                        "7": {
                            "name": "rope",
                            "diamonds": 3
                        },
                        "8": {
                            "name": "floor",
                            "diamonds": 6
                        },
                        "9": {
                            "name": "wall",
                            "diamonds": 6
                        },
                        "10": {
                            "name": "window",
                            "diamonds": 6
                        },
                        "11": {
                            "name": "roof",
                            "diamonds": 7
                        },
                        "12": {
                            "name": "roofWindow",
                            "diamonds": 7
                        },
                        "13": {
                            "name": "railing",
                            "diamonds": 7
                        },
                        "14": {
                            "name": "carpet",
                            "diamonds": 8
                        },
                        "15": {
                            "name": "flag",
                            "diamonds": 8
                        },
                        "16": {
                            "name": "windowBottom",
                            "diamonds": 8
                        },
                        "17": {
                            "name": "table",
                            "diamonds": 9
                        },
                        "18": {
                            "name": "bench",
                            "diamonds": 9
                        },
                        "19": {
                            "name": "necessaryThings",
                            "diamonds": 9
                        },
                        "20": {
                            "name": "barTable",
                            "diamonds": 10
                        },
                        "21": {
                            "name": "barrel",
                            "diamonds": 10
                        },
                        "22": {
                            "name": "lantern",
                            "diamonds": 10
                        },
                        "23": {
                            "name": "cutSquid",
                            "diamonds": 11
                        },
                        "24": {
                            "name": "boxCore",
                            "diamonds": 11
                        },
                        "25": {
                            "name": "signboard",
                            "diamonds": 11
                        },
                        "26": {
                            "name": "parrot",
                            "diamonds": 12
                        }
                    }
                },
                "3": {
                    "name": "temple",
                    "rewards": {
                        "8": {
                            "coins": 40
                        },
                        "16": {
                            "coins": 80
                        },
                        "26": {
                            "coins": 161
                        }
                    },
                    "actions": {
                        "1": {
                            "name": "garbage",
                            "diamonds": 6
                        },
                        "2": {
                            "name": "road",
                            "diamonds": 6
                        },
                        "3": {
                            "name": "ladder",
                            "diamonds": 6
                        },
                        "4": {
                            "name": "columns",
                            "diamonds": 6
                        },
                        "5": {
                            "name": "trash",
                            "diamonds": 9
                        },
                        "6": {
                            "name": "cleanWater",
                            "diamonds": 9
                        },
                        "7": {
                            "name": "fountain",
                            "diamonds": 9
                        },
                        "8": {
                            "name": "wall",
                            "diamonds": 12
                        },
                        "9": {
                            "name": "stone",
                            "diamonds": 12
                        },
                        "10": {
                            "name": "bigLadder",
                            "diamonds": 12
                        },
                        "11": {
                            "name": "parapet",
                            "diamonds": 14
                        },
                        "12": {
                            "name": "roof",
                            "diamonds": 14
                        },
                        "13": {
                            "name": "basRelief",
                            "diamonds": 14
                        },
                        "14": {
                            "name": "hole",
                            "diamonds": 17
                        },
                        "15": {
                            "name": "ladderTop",
                            "diamonds": 17
                        },
                        "16": {
                            "name": "sculpt",
                            "diamonds": 17
                        },
                        "17": {
                            "name": "sculptHead",
                            "diamonds": 20
                        },
                        "18": {
                            "name": "flower",
                            "diamonds": 20
                        },
                        "19": {
                            "name": "garbageBack",
                            "diamonds": 20
                        },
                        "20": {
                            "name": "cleanWaterBack",
                            "diamonds": 22
                        },
                        "21": {
                            "name": "installFountain",
                            "diamonds": 22
                        },
                        "22": {
                            "name": "torchFirst",
                            "diamonds": 22
                        },
                        "23": {
                            "name": "donationBowl",
                            "diamonds": 25
                        },
                        "24": {
                            "name": "fountainSculpture",
                            "diamonds": 25
                        },
                        "25": {
                            "name": "torchSecond",
                            "diamonds": 25
                        },
                        "26": {
                            "name": "inputComposition",
                            "diamonds": 18
                        }
                    }
                },
                "4": {
                    "name": "pier",
                    "rewards": {
                        "8": {
                            "coins": 48
                        },
                        "16": {
                            "coins": 96
                        },
                        "26": {
                            "coins": 193
                        }
                    },
                    "actions": {
                        "1": {
                            "name": "berth",
                            "diamonds": 3
                        },
                        "2": {
                            "name": "pier",
                            "diamonds": 3
                        },
                        "3": {
                            "name": "pierHouse",
                            "diamonds": 3
                        },
                        "4": {
                            "name": "canopy",
                            "diamonds": 3
                        },
                        "5": {
                            "name": "craneBoom",
                            "diamonds": 3
                        },
                        "6": {
                            "name": "craneHook",
                            "diamonds": 3
                        },
                        "7": {
                            "name": "awning",
                            "diamonds": 3
                        },
                        "8": {
                            "name": "barWalls",
                            "diamonds": 3
                        },
                        "9": {
                            "name": "barRoof",
                            "diamonds": 3
                        },
                        "10": {
                            "name": "barDoor",
                            "diamonds": 3
                        },
                        "11": {
                            "name": "barnWalls",
                            "diamonds": 4
                        },
                        "12": {
                            "name": "barnRoof",
                            "diamonds": 4
                        },
                        "13": {
                            "name": "barnDoor",
                            "diamonds": 4
                        },
                        "14": {
                            "name": "boxes",
                            "diamonds": 5
                        },
                        "15": {
                            "name": "foodBoxes",
                            "diamonds": 5
                        },
                        "16": {
                            "name": "lobsterCage",
                            "diamonds": 5
                        },
                        "17": {
                            "name": "clearSand",
                            "diamonds": 6
                        },
                        "18": {
                            "name": "populateShore",
                            "diamonds": 6
                        },
                        "19": {
                            "name": "barrelWater",
                            "diamonds": 6
                        },
                        "20": {
                            "name": "raiseShip",
                            "diamonds": 6
                        },
                        "21": {
                            "name": "newMast",
                            "diamonds": 6
                        },
                        "22": {
                            "name": "paintbulwark",
                            "diamonds": 6
                        },
                        "23": {
                            "name": "newSteering",
                            "diamonds": 7
                        },
                        "24": {
                            "name": "toeFigure",
                            "diamonds": 7
                        },
                        "25": {
                            "name": "signboard",
                            "diamonds": 7
                        },
                        "26": {
                            "name": "seagull",
                            "diamonds": 42
                        }
                    }
                }
            }
        },
		"2": {
			'name': 'mayan',
			'parts': {
				'1': {
					'name': 'workshop',
					'rewards': {
						'8': {
							'coins': 30
						},
						'16': {
							'coins': 65
						},
						'25': {
							'coins': 130
						}
					},
					'actions': {
						'1': {
							'name': 'rocks',
							'diamonds': 5
						},
						'2': {
							'name': 'pit',
							'diamonds': 5
						},
						'3': {
							'name': 'table',
							'diamonds': 5
						},
						'4': {
							'name': 'paving',
							'diamonds': 5
						},
						'5': {
							'name': 'holeStove',
							'diamonds': 5
						},
						'6': {
							'name': 'blower',
							'diamonds': 5
						},
						'7': {
							'name': 'newTable',
							'diamonds': 5
						},
						'8': {
							'name': 'arch',
							'diamonds': 5
						},
						'9': {
							'name': 'firstCanopy',
							'diamonds': 5
						},
						'10': {
							'name': 'blueStove',
							'diamonds': 5
						},
						'11': {
							'name': 'cabinet',
							'diamonds': 5
						},
						'12': {
							'name': 'secondCanopy',
							'diamonds': 5
						},
						'13': {
							'name': 'firstDecor',
							'diamonds': 5
						},
						'14': {
							'name': 'firstShelf',
							'diamonds': 5
						},
						'15': {
							'name': 'smog',
							'diamonds': 5
						},
						'16': {
							'name': 'grate',
							'diamonds': 5
						},
						'17': {
							'name': 'repairDesktop',
							'diamonds': 5
						},
						'18': {
							'name': 'secondShelf',
							'diamonds': 5
						},
						'19': {
							'name': 'secondDecor',
							'diamonds': 5
						},
						'20': {
							'name': 'barrel',
							'diamonds': 5
						},
						'21': {
							'name': 'anvil',
							'diamonds': 5
						},
						'22': {
							'name': 'axe',
							'diamonds': 5
						},
						'23': {
							'name': 'flower',
							'diamonds': 5
						},
						'24': {
							'name': 'clock',
							'diamonds': 5
						},
						'25': {
							'name': 'box',
							'diamonds': 5
						},
						'26': {
							'name': 'ladder',
							'diamonds': 5
						}
					}
				},
				'2': {
					'name': 'base',
					'rewards': {
						'8': {
							'coins': 55
						},
						'16': {
							'coins': 105
						},
						'25': {
							'coins': 215
						}
					},
					'actions': {
						'1': {
							'name': 'bottomWall',
							'diamonds': 1
						},
						'2': {
							'name': 'door',
							'diamonds': 1
						},
						'3': {
							'name': 'bottomRailing',
							'diamonds': 1
						},
						'4': {
							'name': 'topWall',
							'diamonds': 1
						},
						'5': {
							'name': 'barnWall',
							'diamonds': 1
						},
						'6': {
							'name': 'barnRoof',
							'diamonds': 1
						},
						'7': {
							'name': 'barnWindow',
							'diamonds': 1
						},
						'8': {
							'name': 'topRailing',
							'diamonds': 1
						},
						'9': {
							'name': 'stoneRoad',
							'diamonds': 1
						},
						'10': {
							'name': 'ladder',
							'diamonds': 1
						},
						'11': {
							'name': 'flooring',
							'diamonds': 1
						},
						'12': {
							'name': 'boothWall',
							'diamonds': 1
						},
						'13': {
							'name': 'boothRoof',
							'diamonds': 1
						},
						'14': {
							'name': 'boothWindow',
							'diamonds': 1
						},
						'15': {
							'name': 'lifebuoy',
							'diamonds': 1
						},
						'16': {
							'name': 'platform',
							'diamonds': 1
						},
						'17': {
							'name': 'platformRailing',
							'diamonds': 1
						},
						'18': {
							'name': 'suspensionBridge',
							'diamonds': 1
						},
						'19': {
							'name': 'stairs',
							'diamonds': 1
						},
						'20': {
							'name': 'barnFlashlight',
							'diamonds': 1
						},
						'21': {
							'name': 'plants',
							'diamonds': 1
						},
						'22': {
							'name': 'lemur',
							'diamonds': 1
						},
						'23': {
							'name': 'populate',
							'diamonds': 1
						},
						'24': {
							'name': 'bonfire',
							'diamonds': 1
						},
						'25': {
							'name': 'provisions',
							'diamonds': 1
						},
						'26': {
							'name': 'flag',
							'diamonds': 1
						}
					}
				},
				'3': {
					'name': 'chamber',
					'rewards': {
						'8': {
							'coins': 80
						},
						'16': {
							'coins': 160
						},
						'26': {
							'coins': 320
						}
					},
					'actions': {
						'1': {
							'name': 'trash',
							'diamonds': 6
						},
						'2': {
							'name': 'stairs',
							'diamonds': 6
						},
						'3': {
							'name': 'floor',
							'diamonds': 6
						},
						'4': {
							'name': 'podium',
							'diamonds': 6
						},
						'5': {
							'name': 'podiumLadderFirst',
							'diamonds': 6
						},
						'6': {
							'name': 'podiumLadderSecond',
							'diamonds': 6
						},
						'7': {
							'name': 'column',
							'diamonds': 6
						},
						'8': {
							'name': 'bottomWall',
							'diamonds': 6
						},
						'9': {
							'name': 'enter',
							'diamonds': 6
						},
						'10': {
							'name': 'thirdWall',
							'diamonds': 6
						},
						'11': {
							'name': 'roof',
							'diamonds': 6
						},
						'12': {
							'name': 'balcony',
							'diamonds': 6
						},
						'13': {
							'name': 'columns',
							'diamonds': 6
						},
						'14': {
							'name': 'border',
							'diamonds': 6
						},
						'15': {
							'name': 'visor',
							'diamonds': 6
						},
						'16': {
							'name': 'flowers',
							'diamonds': 6
						},
						'17': {
							'name': 'goldDecor',
							'diamonds': 6
						},
						'18': {
							'name': 'table',
							'diamonds': 6
						},
						'19': {
							'name': 'tableDecor',
							'diamonds': 6
						},
						'20': {
							'name': 'bottomLadder',
							'diamonds': 6
						},
						'21': {
							'name': 'palm',
							'diamonds': 6
						},
						'22': {
							'name': 'bush',
							'diamonds': 6
						},
						'23': {
							'name': 'bushWater',
							'diamonds': 6
						},
						'24': {
							'name': 'turtle',
							'diamonds': 6
						},
						'25': {
							'name': 'populate',
							'diamonds': 6
						},
						'26': {
							'name': 'chameleon',
							'diamonds': 6
						}
					}
				},
				'4': {
					'name': 'pier',
					'rewards': {
						'8': {
							'coins': 120
						},
						'16': {
							'coins': 240
						},
						'26': {
							'coins': 480
						}
					},
					'actions': {
						'1': {
							'name': 'boards',
							'diamonds': 3
						},
						'2': {
							'name': 'hole',
							'diamonds': 3
						},
						'3': {
							'name': 'rocks',
							'diamonds': 3
						},
						'4': {
							'name': 'marina',
							'diamonds': 3
						},
						'5': {
							'name': 'ladder',
							'diamonds': 3
						},
						'6': {
							'name': 'rope',
							'diamonds': 3
						},
						'7': {
							'name': 'web',
							'diamonds': 3
						},
						'8': {
							'name': 'stairs',
							'diamonds': 3
						},
						'9': {
							'name': 'parapet',
							'diamonds': 3
						},
						'10': {
							'name': 'walls',
							'diamonds': 3
						},
						'11': {
							'name': 'door',
							'diamonds': 3
						},
						'12': {
							'name': 'roof',
							'diamonds': 3
						},
						'13': {
							'name': 'window',
							'diamonds': 3
						},
						'14': {
							'name': 'table',
							'diamonds': 3
						},
						'15': {
							'name': 'bench',
							'diamonds': 3
						},
						'16': {
							'name': 'barrels',
							'diamonds': 3
						},
						'17': {
							'name': 'fence',
							'diamonds': 3
						},
						'18': {
							'name': 'lanterns',
							'diamonds': 3
						},
						'19': {
							'name': 'hook',
							'diamonds': 3
						},
						'20': {
							'name': 'seaweed',
							'diamonds': 3
						},
						'21': {
							'name': 'boat',
							'diamonds': 3
						},
						'22': {
							'name': 'smallBoat',
							'diamonds': 3
						},
						'23': {
							'name': 'net',
							'diamonds': 3
						},
						'24': {
							'name': 'rum',
							'diamonds': 3
						},
						'25': {
							'name': 'parrot',
							'diamonds': 3
						},
						'26': {
							'name': 'crab',
							'diamonds': 3
						}
					}
				}
			}
        },
        "3": {
            "name": "maori",
            "parts": {
                "1": {
                    "name": "totemStorage",
                    "rewards": {
                        "8": {
                            "coins": 30
                        },
                        "16": {
                            "coins": 65
                        },
                        "26": {
                            "coins": 130
                        }
                    },
                    "actions": {
                        "1": {
                            "name": "grass",
                            "diamonds": 8,
                            "variant": 0
                        },
                        "2": {
                            "name": "stones",
                            "diamonds": 8,
                            "variant": 0
                        },
                        "3": {
                            "name": "removeGrass",
                            "diamonds": 8,
                            "variant": 0
                        },
                        "4": {
                            "name": "snake",
                            "diamonds": 8,
                            "variant": 0
                        },
                        "5": {
                            "name": "pit",
                            "diamonds": 13,
                            "variant": 0
                        },
                        "6": {
                            "name": "fencing",
                            "diamonds": 13,
                            "variant": 0
                        },
                        "7": {
                            "name": "boulder",
                            "diamonds": 13,
                            "variant": 0
                        },
                        "8": {
                            "name": "trash",
                            "diamonds": 18,
                            "variant": 0
                        },
                        "9": {
                            "name": "totem",
                            "diamonds": 18,
                            "variant": 0
                        },
                        "10": {
                            "name": "ladder",
                            "diamonds": 18,
                            "variant": 0
                        },
                        "11": {
                            "name": "seats",
                            "diamonds": 23,
                            "variant": 0
                        },
                        "12": {
                            "name": "torches",
                            "diamonds": 23,
                            "variant": 0
                        },
                        "13": {
                            "name": "secondTrash",
                            "diamonds": 23,
                            "variant": 0
                        },
                        "14": {
                            "name": "flowers",
                            "diamonds": 27,
                            "variant": 0
                        },
                        "15": {
                            "name": "dirt",
                            "diamonds": 27,
                            "variant": 0
                        },
                        "16": {
                            "name": "rug",
                            "diamonds": 27,
                            "variant": 0
                        },
                        "17": {
                            "name": "decor",
                            "diamonds": 32,
                            "variant": 0
                        },
                        "18": {
                            "name": "track",
                            "diamonds": 32,
                            "variant": 0
                        },
                        "19": {
                            "name": "podium",
                            "diamonds": 32,
                            "variant": 0
                        },
                        "20": {
                            "name": "wall",
                            "diamonds": 37,
                            "variant": 0
                        },
                        "21": {
                            "name": "road",
                            "diamonds": 37,
                            "variant": 0
                        },
                        "22": {
                            "name": "beds",
                            "diamonds": 37,
                            "variant": 0
                        },
                        "23": {
                            "name": "ritualHeads",
                            "diamonds": 42,
                            "variant": 0
                        },
                        "24": {
                            "name": "vessels",
                            "diamonds": 42,
                            "variant": 0
                        },
                        "25": {
                            "name": "secondFlowers",
                            "diamonds": 42,
                            "variant": 0
                        },
                        "26": {
                            "name": "secondTorch",
                            "diamonds": 25,
                            "variant": 0
                        }
                    }
                },
                "2": {
                    "name": "base",
                    "rewards": {
                        "8": {
                            "coins": 55
                        },
                        "16": {
                            "coins": 105
                        },
                        "26": {
                            "coins": 215
                        }
                    },
                    "actions": {
                        "1": {
                            "name": "flowers",
                            "diamonds": 8
                        },
                        "2": {
                            "name": "pit",
                            "diamonds": 8
                        },
                        "3": {
                            "name": "rocks",
                            "diamonds": 8
                        },
                        "4": {
                            "name": "boards",
                            "diamonds": 13
                        },
                        "5": {
                            "name": "roof",
                            "diamonds": 13
                        },
                        "6": {
                            "name": "door",
                            "diamonds": 13
                        },
                        "7": {
                            "name": "signboard",
                            "diamonds": 18
                        },
                        "8": {
                            "name": "curtain",
                            "diamonds": 18
                        },
                        "9": {
                            "name": "floor",
                            "diamonds": 18
                        },
                        "10": {
                            "name": "stair",
                            "diamonds": 23
                        },
                        "11": {
                            "name": "wall",
                            "diamonds": 23
                        },
                        "12": {
                            "name": "spyglass",
                            "diamonds": 23
                        },
                        "13": {
                            "name": "road",
                            "diamonds": 27
                        },
                        "14": {
                            "name": "torches",
                            "diamonds": 27
                        },
                        "15": {
                            "name": "piles",
                            "diamonds": 27
                        },
                        "16": {
                            "name": "driedFish",
                            "diamonds": 32
                        },
                        "17": {
                            "name": "scarecrow",
                            "diamonds": 32
                        },
                        "18": {
                            "name": "dinnerTable",
                            "diamonds": 32
                        },
                        "19": {
                            "name": "bowler",
                            "diamonds": 37
                        },
                        "20": {
                            "name": "woodenTable",
                            "diamonds": 37
                        },
                        "21": {
                            "name": "sabers",
                            "diamonds": 37
                        },
                        "22": {
                            "name": "boxes",
                            "diamonds": 42
                        },
                        "23": {
                            "name": "hammock",
                            "diamonds": 42
                        },
                        "24": {
                            "name": "barrel",
                            "diamonds": 42
                        },
                        "25": {
                            "name": "vegetables",
                            "diamonds": 42
                        },
                        "26": {
                            "name": "advesting",
                            "diamonds": 56
                        }
                    }
                },
                "3": {
                    "name": "commune",
                    "rewards": {
                        "8": {
                            "coins": 80
                        },
                        "16": {
                            "coins": 160
                        },
                        "26": {
                            "coins": 320
                        }
                    },
                    "actions": {
                        "1": {
                            "name": "stones",
                            "diamonds": 11
                        },
                        "2": {
                            "name": "boards",
                            "diamonds": 11
                        },
                        "3": {
                            "name": "dirt",
                            "diamonds": 11
                        },
                        "4": {
                            "name": "weeds",
                            "diamonds": 19
                        },
                        "5": {
                            "name": "bench",
                            "diamonds": 19
                        },
                        "6": {
                            "name": "podium",
                            "diamonds": 19
                        },
                        "7": {
                            "name": "foundation",
                            "diamonds": 26
                        },
                        "8": {
                            "name": "seats",
                            "diamonds": 26
                        },
                        "9": {
                            "name": "pillars",
                            "diamonds": 26
                        },
                        "10": {
                            "name": "garbage",
                            "diamonds": 34
                        },
                        "11": {
                            "name": "wall",
                            "diamonds": 26
                        },
                        "12": {
                            "name": "roofSecond",
                            "diamonds": 34
                        },
                        "13": {
                            "name": "road",
                            "diamonds": 41
                        },
                        "14": {
                            "name": "roof",
                            "diamonds": 26
                        },
                        "15": {
                            "name": "totem",
                            "diamonds": 41
                        },
                        "16": {
                            "name": "connectHouses",
                            "diamonds": 49
                        },
                        "17": {
                            "name": "relax",
                            "diamonds": 49
                        },
                        "18": {
                            "name": "firePlace",
                            "diamonds": 49
                        },
                        "19": {
                            "name": "pit",
                            "diamonds": 56
                        },
                        "20": {
                            "name": "stocks",
                            "diamonds": 56
                        },
                        "21": {
                            "name": "herbs",
                            "diamonds": 56
                        },
                        "22": {
                            "name": "fire",
                            "diamonds": 64
                        },
                        "23": {
                            "name": "settleDown",
                            "diamonds": 64
                        },
                        "24": {
                            "name": "hangi",
                            "diamonds": 64
                        },
                        "25": {
                            "name": "fruits",
                            "diamonds": 64
                        },
                        "26": {
                            "name": "dinner",
                            "diamonds": 83
                        }
                    }
                },
                "4": {
                    "name": "pier",
                    "rewards": {
                        "8": {
                            "coins": 120
                        },
                        "16": {
                            "coins": 240
                        },
                        "26": {
                            "coins": 480
                        }
                    },
                    "actions": {
                        "1": {
                            "name": "liana",
                            "diamonds": 16
                        },
                        "2": {
                            "name": "roof",
                            "diamonds": 16
                        },
                        "3": {
                            "name": "wall",
                            "diamonds": 16
                        },
                        "4": {
                            "name": "curtain",
                            "diamonds": 27
                        },
                        "5": {
                            "name": "barrel",
                            "diamonds": 27
                        },
                        "6": {
                            "name": "fenceDown",
                            "diamonds": 27
                        },
                        "7": {
                            "name": "box",
                            "diamonds": 39
                        },
                        "8": {
                            "name": "containers",
                            "diamonds": 39
                        },
                        "9": {
                            "name": "canopy",
                            "diamonds": 39
                        },
                        "10": {
                            "name": "garbage",
                            "diamonds": 50
                        },
                        "11": {
                            "name": "turtle",
                            "diamonds": 50
                        },
                        "12": {
                            "name": "garbageSecond",
                            "diamonds": 50
                        },
                        "13": {
                            "name": "web",
                            "diamonds": 62
                        },
                        "14": {
                            "name": "ladder",
                            "diamonds": 62
                        },
                        "15": {
                            "name": "weeds",
                            "diamonds": 62
                        },
                        "16": {
                            "name": "firewood",
                            "diamonds": 74
                        },
                        "17": {
                            "name": "fenceUp",
                            "diamonds": 74
                        },
                        "18": {
                            "name": "boxSecond",
                            "diamonds": 74
                        },
                        "19": {
                            "name": "lighthouseBottom",
                            "diamonds": 85
                        },
                        "20": {
                            "name": "beam",
                            "diamonds": 85
                        },
                        "21": {
                            "name": "garbageThird",
                            "diamonds": 85
                        },
                        "22": {
                            "name": "bonfire",
                            "diamonds": 97
                        },
                        "23": {
                            "name": "graffiti",
                            "diamonds": 97
                        },
                        "24": {
                            "name": "rum",
                            "diamonds": 97
                        },
                        "25": {
                            "name": "boat",
                            "diamonds": 62
                        },
                        "26": {
                            "name": "fishingGear",
                            "diamonds": 122
                        }
                    }
                }
            }
        }
	},
	web: {
		dev: {
			vk: {
				interstitials: dev_vk_interstitials
			},
			ok: {
				interstitials: dev_vk_interstitials
			},
			yandex: {
				interstitials: dev_vk_interstitials
			}
		},
		release: {
			vk: {
				interstitials: release_vk_interstitials
			},
			ok: {
				interstitials: release_vk_interstitials
			},
			yandex: {
				interstitials: release_vk_interstitials
			}
		}
	}
}