import * as _ from 'lodash-es'
import { Fragment } from 'react'
import zebomba from '../../../tools/zebomba'
import { useImmer } from 'use-immer'

import cinemaConfigs from './configs/configs.js'
import FieldFactory from '../FieldFactory'
import CinemaTasksControls from './CinemaTasksControls'
import { useAppData } from '../../pages/Project.jsx'

export default function CinemaTasksPopup({ data }) {
	const app = useAppData()
	const [ tasks, setTasks ] = useImmer(data.tasks)

	const cinemaConfig = cinemaConfigs[app.config.name] || cinemaConfigs.cat

	const deleteTask = taskIndex => {
		setTasks(tasks => {
			const newTasks = [ ...tasks ]
			newTasks.splice(taskIndex, 1)

			return newTasks
		})
	}

	const addTask = taskID => {
		if (!_.some(tasks, task => _.keys(task)[0] === taskID)) {
			setTasks(tasks => {
				const newTasks = [ ...tasks ]
				const task = {}
	
				task[taskID] = [ 0, 0, 0 ]
	
				newTasks.push(task)

				return newTasks
			})
		}
		else {
			zebomba.message.info('Задание с таким ID уже добавлено')
		}
	}

	const updateTask = (taskIndex, taskID, elemIndex, value) => {
		setTasks(tasks => {
			tasks[taskIndex][taskID][elemIndex] = value

			return tasks
		})
	}

	const deleteAllTasks = () => {
		setTasks(() => [])
	}

	const addAllConfigTasks = () => {
		setTasks(() => _.map(cinemaConfig.tasks, (task, taskID) => ({ [taskID]: [ 0, 0, 0] })))
	}

	const saveTasks = () => {
		data.onFieldChange(tasks)

		zebomba.message.success('Поле заданий обновлено')
	}

	const tasksList = _.map(tasks, (rawTask, taskIndex) => {
		const taskID = taskIndex
		const task = rawTask
		const taskConfig = cinemaConfig.tasks[taskID]

		if (taskConfig) {
			const rewardLabel = defineRewardLabel(taskConfig.free.rewards)

			const freeRewardCount = !taskConfig.free.rewards.lottery && `x${ _.values(taskConfig.free.rewards)[0] }` || ''
			const payRewardCount = taskConfig.pay && taskConfig.pay.rewards && !taskConfig.pay.rewards.lottery ? `(x${ _.values(taskConfig.pay.rewards)[0] })` : ''

			return <div className='taskItem' key={taskID}>
				<div className="top">
					<div className="id">{ taskID }</div>
					<div className="type">Тип: { taskConfig.type === 'quiz' ? 'Опрос' : 'Реклама' }</div>
					<div className="reward">Награда: { rewardLabel } { freeRewardCount } { payRewardCount }</div>
					<div className="deleteBtn" onClick={() => deleteTask(taskIndex)}>+</div>
				</div>

				<div className="bottom">
					<FieldFactory
						data={{
							name: 'cinemaTaskProgress',
							title: 'Прогресс',
							type: 'number',
							value: task[0]
						}}
						onFieldChange={field => updateTask(taskIndex, taskID, 0, field.value)}/>

					<FieldFactory
						data={{
							name: 'cinemaTaskStatus',
							title: 'Статус',
							type: 'select',
							options: {
								0: 'Не выполнено',
								1: 'Выполнено',
								2: 'Получен приз'
							},
							value: task[1]
						}}
						onFieldChange={field => updateTask(taskIndex, taskID, 1, field.value)}></FieldFactory>

					<FieldFactory
						data={{
							name: 'cinemaTaskTimeout',
							title: `Таймаут для "барабана"`,
							type: 'datetime',
							value: task[2]
						}}
						onFieldChange={field => updateTask(taskIndex, taskID, 2, field.value)}></FieldFactory>
				</div>
			</div>
		}
		
		return <div key={taskID}>Такого задания нет в конфиге (ID: { taskID }). Нужно добавить.</div>
	})

	return (
		<Fragment>
			<div className="popupTitle">Задания Кинотеатра</div>

			<CinemaTasksControls
				tasks={cinemaConfig.tasks}
				addTask={addTask}
				deleteAllTasks={deleteAllTasks}
				addAllConfigTasks={addAllConfigTasks}
				saveTasks={saveTasks}/>

			<div className={`tasks ${ tasksList.length >= 4 ? 'wide' : '' }`}>
				{ tasksList }
			</div>
		</Fragment>
	)
}

const defineRewardLabel = rewards => {
	let rewardLabel = 'Неизвестно'

	if (_.has(rewards, 'coins') || rewards.lottery) {
		rewardLabel = 'монеты'
	}
	else if (_.has(rewards, 'show_difference_cnt')) {
		rewardLabel = 'подсказки'
	}
	else if (_.has(rewards, 'lifes')) {
		rewardLabel = 'жизни'
	}
	else if (_.has(rewards, 'lifes_infinite')) {
		rewardLabel = 'беск. жизни'
	}
	else if (_.has(rewards, 'smile')) {
		rewardLabel = 'смайл'
	}
	else if (_.has(rewards, 'decoration')) {
		rewardLabel = 'украшение'
	}
	else if (_.has(rewards, 'photo_frame')) {
		rewardLabel = 'рамка'
	}

	return rewardLabel
}