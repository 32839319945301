import FeatherIcon from "feather-icons-react/build/FeatherIcon"

export default function Button({ color = 'blue', className = '', disabled = false, wait = false, children, onClick }) {
	let buttonClassName = `button ${ color } ${ className }`

	if (disabled) {
		buttonClassName += ' disabled'
	}

	if (wait) {
		buttonClassName += ' wait'
	}

	return (
		<div className={buttonClassName} onClick={onClick}>
			{ children }

			{ wait && <FeatherIcon icon="rotate-cw" size="21"/>}
		</div>
	)
}