import FeatherIcon from "feather-icons-react/build/FeatherIcon"
import { Button, Table, Select } from "antd"
import * as _ from 'lodash-es'
import TableRewardsFilter from "./TableRewardsFilter"

export default function RewardsTable({ rewards, setRewards, addReward, removeReward, applyForAllRewards, maxLevel }) {
	const selectOptions = [
		{
			value: 0,
			label: 'Нельзя взять'
		},
		{
			value: 1,
			label: 'Можно взять'
		},
		{
			value: 2,
			label: 'Получена'
		},
		{
			value: 3,
			label: 'Нет приза'
		}
	]

	const tableColumns = [
		{
			title: 'Уровень',
			key: 'level',
			dataIndex: 'level',
			align: 'center'
		},
		{
			title: 'Нижняя награда',
			key: 'topReward',
			dataIndex: 'topReward',
			align: 'center',
			filterDropdown: ({ confirm }) => <TableRewardsFilter
				confirm={confirm}
				selectOptions={selectOptions}
				onChange={value => applyForAllRewards('topReward', value)}/>
		},
		{
			title: 'Верхняя награда',
			key: 'bottomReward',
			dataIndex: 'bottomReward',
			align: 'center',
			filterDropdown: ({ confirm }) => <TableRewardsFilter
				confirm={confirm}
				selectOptions={selectOptions}
				onChange={value => applyForAllRewards('bottomReward', value)}/>
		},
		{
			dataIndex: 'removeBtn'
		}
	]

	const data = _.map(rewards, reward => ({
		level: reward.level,
		topReward: <Select
			onChange={value => {
				setRewards(rewards => {
					reward.topReward = value

					return [ ...rewards ]
				})
			}}
			popupMatchSelectWidth={false}
			options={selectOptions}
			value={reward.topReward}
			defaultValue={reward.topReward}/>,
		bottomReward: <Select
			onChange={value => {
				setRewards(rewards => {
					reward.bottomReward = value

					return [ ...rewards ]
				})
			}}
			popupMatchSelectWidth={false}
			options={selectOptions}
			value={reward.bottomReward}
			defaultValue={reward.bottomReward}/>,
		removeBtn: <Button danger className="removeBtn" onClick={() => removeReward(reward)}><FeatherIcon size="19" icon="x"/></Button>
	}))

	return <>
		<Table
			size="small"
			dataSource={data}
			columns={tableColumns}/>

		{ data.length < maxLevel &&
			<Button type="dashed" className="addBtn" onClick={addReward}><FeatherIcon size="20" icon="plus"/>Добавить</Button> }
	</>
}