import * as _ from 'lodash-es'
import { createElement, useRef } from "react"

import { useAuth } from '../../provider/AuthProvider'
import Field from "./fields/Field"
import NumberField from "./fields/NumberField"
import TextField from "./fields/TextField"
import CheckboxField from "./fields/CheckboxField"
import SelectField from "./fields/SelectField"
import ListField from "./fields/ListField"
import ObjectField from "./fields/objectField/ObjectField"
import { useAppData } from "../pages/Project"
import { useForm } from "./AppForm"
import DateTimeField from "./fields/DateTimeField"
import DateField from "./fields/DateField"
import RawField from './fields/RawField'

import CinemaTasksField from './cinema/CinemaTasksField'
import BattlePassTasksField from './battlePass/BattlePassTasksField'
import BattlePassRewardsField from './battlePass/battlePassRewards/BattlePassRewardsField'
import DailyBonusRewardsField from './dailyBonus/DailyBonusRewardsField'
import ActionsField from './villaActions/ActionsField'
import PodarokField from './podarok/PodarokField'
import EmojiField from './emoji/EmojiField'

const fieldsComponents = {
	text: TextField,
	number: NumberField,
	checkbox: CheckboxField,
	select: SelectField,
	list: ListField,
	datetime: DateTimeField,
	date: DateField,
	raw: RawField
}

export default function FieldFactory({ data, onFieldChange }) {
	const { user } = useAuth()
	const { fields: formFields, fieldsRefs } = useForm()
	const { config: appConfig } = useAppData()

	const isRefDataSet = useRef(false)

	if (!data || !data.name) {
		throw new Error('data argument is undefined.')
	}

	// Берём данные поля из конфига или данные уже сформированы в data
	let fieldData = appConfig.fields[data.name] && { ...appConfig.fields[data.name] } || data
	fieldData = _.cloneDeep(fieldData)
	let field = null

	// Провярем, является ли текущее поле - полем-ссылкой
	const fieldRef = fieldData.ref

	if (fieldRef) {
		const refData = _.get(appConfig.refs, fieldRef)

		fieldData = _.assign(fieldData, refData)
		fieldData.ref = fieldRef

		if (refData) {
			if (!isRefDataSet.current) {
				fieldsRefs.current.add(fieldRef, data.name)
			}

			isRefDataSet.current = true
		}
		else {
			console.error(`Не указан конфиг для поля "${ data.name }" в объекте refs`)
		}
	}

	if (fieldData) {
		if (!user.hasPermission(fieldData.permissions)) {
			return
		}

		// fieldData.valueType = defineFieldValueType(fieldData)
		fieldData.name = data.name

		// Значение по умолчанию берём из data или из данных формы (данных от сервера)
		const defaultValue = _.has(fieldData, 'value') ? fieldData.value : formFields[data.name]

		if (!fieldData.isExcluded) {
			const fieldProps = {
				data: fieldData,
				defaultValue,
				onFieldChange
			}

			if (fieldsComponents[fieldData.type]) {
				const typedField = createElement(fieldsComponents[fieldData.type])

				field = createElement(Field, fieldProps, typedField)
			}
			else if (fieldData.type === 'object' || fieldData.type === 'array') {
				field = <ObjectField data={ fieldData } onFieldChange={ onFieldChange } defaultValue={ defaultValue }/>
			}
			// Кастомные поля типа заданий БП
			else {
				const customFields = {
					cinemaTasks: CinemaTasksField,
					battlePassTasks: BattlePassTasksField,
					dailyBonusRewards: DailyBonusRewardsField,
					battlePassRewards: BattlePassRewardsField,
					villaActions: ActionsField,
					podarok: PodarokField,
					emoji: EmojiField
				}

				const customField = customFields[fieldData.type] || data.customField

				if (customField) {
					const customFieldElement = createElement(customField, {
						data: fieldData,
						defaultValue
					})

					field = createElement(Field, fieldProps, customFieldElement)
				}
			}
		}

	}
	else {
		console.error(`Failed to find "${ data.name }" field in fields config.`)
	}

	return field
}

// const defineFieldValueType = fieldData => {

// }