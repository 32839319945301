import { useState, createContext, useContext, useEffect, useRef } from "react"
import * as _ from 'lodash-es'
import { Button } from "antd"
import zebomba from "../../tools/zebomba"

import { useAppData } from "../pages/Project"
import LeftPanel from "./leftPanel/LeftPanel"
import HelpButtons from "./helpButtons/HelpButtons"
import PopupFactory from "../popups/PopupFactory"
import SectionFactory from "./sections/SectionFactory"

const FormContext = createContext()

export default function AppForm({ serverData, saveFieldsHandler, resetAppHandler, isRequestInProgress }) {
	const [ popupData, setPopupData ] = useState()
	const [ fields, setFields ] = useState(null)
	const isDataChanged = useRef(false)
	const fieldsRefs = useRef(new FieldsRefs())

	const { config: appConfig } = useAppData()

	// if (!_.isEqual(serverData, fields)) {
	// 	setFields(serverData)
	// }

	useEffect(() => { setFields(serverData) }, [ serverData ])

	const onFieldChange = field => {
		isDataChanged.current = true

		setFields(prevFields => {
			const newFields = { ...prevFields }

			newFields[field.name] = field.value

			return newFields
		})

		window.onbeforeunload = e => {
			if (e) {
				e.returnValue = 'Уверены?'
			}
		
			return 'Уверены?'
		}
	}

	const setAppFormFields = fields => {
		if (fields) {
			setFields(fields)

			isDataChanged.current = true

			window.onbeforeunload = e => {
				if (e) {
					e.returnValue = 'Уверены?'
				}
			
				return 'Уверены?'
			}

			zebomba.message.success('Поля успешно обновлены')
		}
		else {
			zebomba.message.success('Что-то пошло не так...')
		}
	}

	const saveHandler = () => {
		const processedFields = {}

		for (const name in fields) {
			const value = fields[name]

			if (_.isObject(value)) {
				processedFields[name] = JSON.stringify(value)
			}
			else {
				const field = appConfig.fields[name]

				// readOnly поля не нужно отправлять снова на сервер
				if (!field?.readOnly) {
					processedFields[name] = value
				}
			}
		}

		saveFieldsHandler(processedFields)

		isDataChanged.current = false
		window.onbeforeunload = null
	}

	const updateFieldsRefs = (refFieldName, fieldValue) => {
		setFields(prevFields => {
			const newFields = { ...prevFields }

			// Утановка значения связанным полям
			const linkedFields = fieldsRefs.current.list[refFieldName]

			_.forEach(linkedFields, fieldName => {
				const fieldPath = fieldName
					.replace(/\[/g, '.')
					.replace(/\]/g, '')

				if (_.has(newFields, fieldPath)) {
					_.setWith(newFields, fieldPath, fieldValue)
				}
			})

			return newFields
		})
	}

	const setFieldDefaultValue = fieldName => {
		const fieldConfig = appConfig.fields[fieldName]

		if (fieldConfig) {
			const { type, views } = fieldConfig

			if (type === 'array') {
				setFields(prevFields => {
					const newFields = { ...prevFields }
					const arrayLength = _.keys(views).length

					newFields[fieldName] = new Array(arrayLength).fill(1)
		
					return newFields
				})
			}
		}
		else {
			console.error('Failed to find config for field:', fieldName)
		}
	}

	if (fields) {
		return (
			<FormContext.Provider value={{ fields, setAppFormFields, setPopupData, updateFieldsRefs, fieldsRefs, setFieldDefaultValue }}>
				<div className="container-fluid mainSystem project-page">
					<div className="row">
						<div className="col-12 appContent">
							<div className="leftPanelSide">
								<LeftPanel />
							</div>

							<div className="rightSide">
								{
									<HelpButtons fields={fields} setFields={setFields} resetAppHandler={resetAppHandler}/>
								}

								<div className="sections">
								{
									_.map(appConfig.sections, (section, name) => {
										section.name = name

										return <SectionFactory
											key={name}
											data={section}
											onFieldChange={onFieldChange}
											formFields={fields}
											setPopupData={setPopupData}
											/>
									})	
								}
								</div>
							</div>

							<div id="bottomPanel" className={ isRequestInProgress ? 'disabled' : '' }>
								<div className="buttons">
									<Button type="primary" className={`saveBtn button blue ${ isDataChanged.current ? 'changed' : '' }`} onClick={saveHandler}>
										Сохранить
										<div className="label">Данные изменились</div>
									</Button>
									<Button type="default" className="resetBtn button" onClick={() => window.location.reload()}>Сбросить изменения</Button>
								</div>
							</div>
						</div>
					</div>
				</div>

				{
					popupData && <PopupFactory data={ popupData } setPopupData={ setPopupData } setFormField={onFieldChange}/>
				}
			</FormContext.Provider>
		)
	}
}

class FieldsRefs {
	list = {}

	add( refField, linkedField ) {
		if (!this.list[refField]) {
			this.list[refField] = []
		}

		this.list[refField].push(linkedField)
	}
}

export function useForm() {
	return useContext(FormContext)
}