// eslint-disable-next-line import/no-anonymous-default-export
export default {
	rewards: {
		"1": {
			name: "coins",
			label: "Монеты"
		},
		"2": {
			name: "wheel_cnt",
			label: "Бесплатная фортуна"
		},
		"3": {
			name: "wheel_paid_cnt",
			label: "Платная фортуна"
		},
		"4": {
			name: "sunduk_cnt",
			label: "Бесплатная лотерея"
		},
		"5": {
			name: "sunduk_paid_cnt",
			label: "Платная лотерея"
		},
		"6": {
			name: "show_difference_cnt",
			label: "Подсказки"
		},
		"7": {
			name: "rating",
			label: "Рейтинг"
		},
		"8": {
			name: "diamonds",
			label: "Алмазы"
		},
		"9": {
			name: "lifes",
			label: "Жизни"
		},
		"10": {
			name: "lifes_infinite",
			label: "Бесконечные жизни"
		},
		"12": {
			name: "chest_smile",
			label: "Сундук смайлов"
		},
		"13": {
			name: "chest_blue",
			label: "Синий сундук"
		},
		"14": {
			name: "chest_red",
			label: "Красный сундук"
		},
		"16": {
			name: "rating_booster",
			label: "Бустер рейтинга (ID)"
		}
	},	
	popups: {
		"0": {
			name: "",
			label: "Без перехода"
		},
		"1": {
			name: "pay",
			label: "Касса"
		},
		"2": {
			name: "gameFriday",
			label: "Праздничная распродажа"
		},
		"3": {
			name: "battlePass",
			label: "Боевой пропуск"
		},
		"4": {
			name: "battlePassSale",
			label: "Распродажа Боевого пропуска"
		},
		"6": {
			name: "beginnerKits",
			label: "Набор новичка"
		},
		"7": {
			name: "dailyChallenge",
			label: "Ежедневное испытание"
		},
		"11": {
			name: "dailyQuests",
			label: "Ежедневные задания"
		},
		"12": {
			name: "selectEvent",
			label: "Выбор ивента"
		},
		"13": {
			name: "lobby",
			label: "Окно удачи"
		},
		"14": {
			name: "friends",
			label: "Друзья"
		},
		"15": {
			name: "tournament",
			label: "Окно начала боя"
		},
		"17": {
			name: "leaderboard",
			label: "Лиги"
		},
		"18": {
			name: "expressSale",
			label: "Экспресс-распродажа"
		},
		"20": {
			name: "moneybox",
			label: "Копилка"
		},
		"21": {
			name: "tasks",
			label: "Миссии"
		}
	},
	form: {
		popupImage: {
			options: [
				'hero1'
			]
		}
	}
}