import common from "./common"

export default {
	byKeys: {
		web: {
			dev: {
				vk: {
					interstitials: common.web.dev.vk.interstitials
				},
				ok: {
					interstitials: common.web.dev.vk.interstitials
				},
				yandex: {
					interstitials: common.web.dev.vk.interstitials
				}
			},
			release: {
				vk: {
					interstitials: {
						"loadingScreen": {
							"probability": 0,
							"rules": {
								"map": 1,
								"point": 2
							}
						},
						"beforeBattle": {
							"probability": 0,
							"rules": {
								"map": 2,
								"point": 2
							}
						},
						"afterBattle": {
							"probability": 100,
							"rules": {
								"map": 1,
								"point": 5,
								"no_payment_count": 0,
								"rev": 0
							}
						},
						"afterBattleRound": {
							"probability": 100,
							"roundsProbability": {
								"1": 100,
								"2": 100
							},
							"rules": {
								"map": 1,
								"point": 8,
								"no_payment_count": 0,
								"rev": 0
							}
						}
					}
				},
				ok: {
					interstitials: {
						"loadingScreen": {
							"probability": 0,
							"rules": {
								"map": 1,
								"point": 2
							}
						},
						"beforeBattle": {
							"probability": 0,
							"rules": {
								"map": 2,
								"point": 2
							}
						},
						"afterBattle": {
							"probability": 100,
							"rules": {
								"map": 1,
								"point": 5,
								"no_payment_count": 0,
								"rev": 0
							}
						},
						"afterBattleRound": {
							"probability": 100,
							"roundsProbability": {
								"1": 100,
								"2": 100
							},
							"rules": {
								"map": 1,
								"point": 8,
								"no_payment_count": 0,
								"rev": 0
							}
						}
					}
				},
				yandex: {
					interstitials: {
						"loadingScreen": {
							"probability": 0,
							"rules": {
								"map": 1,
								"point": 2
							}
						},
						"beforeBattle": {
							"probability": 0,
							"rules": {
								"map": 2,
								"point": 2
							}
						},
						"afterBattle": {
							"probability": 100,
							"rules": {
								"map": 1,
								"point": 5,
								"no_payment_count": 0,
								"rev": 0
							}
						},
						"afterBattleRound": {
							"probability": 100,
							"roundsProbability": {
								"1": 100,
								"2": 100
							},
							"rules": {
								"map": 1,
								"point": 8,
								"no_payment_count": 0,
								"rev": 0
							}
						}
					}
				}
			}
		}
	}
}