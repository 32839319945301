import { Button, Popconfirm } from "antd"
import FeatherIcon from "feather-icons-react/build/FeatherIcon"

export default function ResetFields({ resetAppHandler }) {
	return (
		<Popconfirm
			description="Точно сбросить?"
			onConfirm={resetAppHandler}
			okText="Да"
			cancelText="Нет"
		>
			<Button type="primary" className="button">
				<FeatherIcon icon="trash-2" size="19"/>

				Сбросить прогресс
			</Button>
	  </Popconfirm>
	)
}