export default {
	1: 'Sale Day',
	2: '1000 ОК',
	'2_1': '1000 ОК (второй вариант)',
	3: 'Действует акция',
	'3_1': 'Действует акция (второй вариант)',
	4: 'Чёрная пятница',
	5: 'День труда',
	6: 'Пасха',
	7: 'День музыки',
	8: 'День шоколада',
	9: 'День кошек',
	10: 'День знаний',
	11: 'Хэллоуин',
	12: 'Новый год',
	13: 'День святого Валентина',
	14: '23 февраля',
	15: '8 марта',
	16: 'День здоровья',
	17: 'День защиты детей',
	18: 'День семьи',
	19: 'День друзей',
	20: 'День отца',
	21: 'День семьи, любви и верности',
	22: 'День кино',
	23: 'День смайлика',
	24: 'День мужчин',
	25: 'День художника',
	26: 'Рождество',
	27: 'День эскимо'
}