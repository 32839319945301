import * as _ from 'lodash-es'

export default function listReducer(list, action) {
	switch (action.type) {
		case 'valueChanged': {
			const { index, value } = action.data

			let newList

			if (_.isArray(list)) {
				newList = [ ...list ]
			}
			else {
				newList = { ...list }
			}																								
	
			newList[index] = value

			return newList
		}
		case 'indexChanged': {
			const { oldIndex, newIndex } = action.data

			const valueByIndex = list[oldIndex]
			const newList = { ...list }
	
			delete newList[oldIndex]
	
			newList[newIndex] = valueByIndex

			return newList
		}
		case 'listItemAdded': {
			const { index, value } = action.data

			let newList

			if (_.isArray(list)) {
				newList = [ ...list, value ]
			}
			else {
				newList = { ...list }
				newList[index] = value
			}

			return newList
		}
		case 'itemDeletedByIndex': {
			const { index } = action.data

			let newList

			if (_.isArray(list)) {
				newList = _.filter(list, (item, ind) => ind !== index)
			}
			else {
				newList = { ...list }
	
				delete newList[index]
			}

			return newList
		}
		default: {
			throw new Error('Failed to define action type.')
		}
	}
}