import { useState } from 'react'
import BattlePassTasksModal from "./BattlePassTasksModal"

export default function BattlePassTasksField({ data, onValueChange, defaultValue }) {
	const [ isModalOpen, setIsModalOpen ] = useState(false)
	const showModal = () => { setIsModalOpen(true) }
	const handleCancel = () => { setIsModalOpen(false) }

	return <>
		<div className="button grey changeBtn" onClick={showModal}>Изменить</div>

		{ isModalOpen && <BattlePassTasksModal
			bpTasks={defaultValue || {}}
			onFieldChange={onValueChange}
			closeModal={handleCancel}
			isModalOpen={true}
			handleCancel={handleCancel}/> }
	</>
}