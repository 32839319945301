import * as _ from 'lodash-es'
import { useState, useRef } from 'react'
import battlePassConfig from './configs/configs'
import FieldFactory from '../FieldFactory'
import TaskItem from './TaskItem'
import zebomba from '../../../tools/zebomba'
import { useImmer } from 'use-immer'
import { Button, Modal } from 'antd'
import { useAppData } from '../../pages/Project'

export default function BattlePassTasksModal({ bpTasks, onFieldChange, handleCancel }) {
	const app = useAppData()
	const isMounted = useRef(false)
	const [ selectedTaskID, setSelectedTaskID ] = useState(null)
	const [ tasks, updateTasks ] = useImmer([])
	const tasksConfig = battlePassConfig[app.config.name].tasks

	const tasksOptions = _.reduce(tasksConfig, (options, task, taskID) => {
		options[taskID] = task.name

		return options
	}, {})

	if (!isMounted.current && bpTasks) {
		isMounted.current = true

		const tasks = _.reduce(bpTasks, (arr, taskDiff, taskID) => {
			if (tasksConfig[taskID]) {
				const task = new Task(taskID, tasksConfig[taskID])
				const difficulty = _.keys(taskDiff)[0]
				const values = taskDiff[difficulty]

				task.value = [ difficulty, ...values ]

				arr.push(task)
			}
			else {
				zebomba.message.error(`Не удалось найти конфиг для задания: ${ taskID }`)
			}

			return arr
		}, [])
	
		updateTasks(tasks)
	}

	const onAddBtnClick = () => {
		const taskID = selectedTaskID

		if (_.some(tasks, task => task.id === taskID)) {
			zebomba.message.info('Задание с таким ID уже добавлено')
		}
		else {
			const task = new Task(taskID, tasksConfig[taskID])

			updateTasks(tasks => { tasks.push(task) })
		}
	}

	const onSaveBtnClick = () => {
		const resultTasks = _.reduce(tasks, (result, task) => {
			result[task.id] = {
				[task.difficulty]: task.valueWithoutDiff
			}

			return result
		}, {})

		onFieldChange(resultTasks)

		zebomba.message.success('Задания Боевого пропуска изменены')

		handleCancel()
	}

	const deleteAllBtnClick = () => {
		updateTasks([])
	}

	const onAddAllBtnClick = () => {
		const tasks = _.map(tasksConfig, (config, taskID) => new Task(taskID, config))
		
		updateTasks(tasks)
	}

	let tasksItems = _.sortBy(tasks, task => +task.id)
	tasksItems = _.map(tasksItems, task => <TaskItem key={task.id} task={task} updateTasks={updateTasks}/>)

	return (
		<Modal
			width={680}
			className="battlePassTasksModal"
			destroyOnClose={true}
			transitionName=""
			title="Задания Боевого пропуска"
			open={true}
			footer=""
			style={{
				top: '5%'
			}}
			onCancel={handleCancel}
		>
			<div className="top">
				<FieldFactory
					data={{
						name: 'battlePassTaskID',
						title: 'ID задания',
						type: 'select',
						options: tasksOptions,
						hideName: true,
						anySearch: true
					}}
					onFieldChange={field => { setSelectedTaskID(field.value) }}/>

				<Button type="primary" className={`button addBtn ${ selectedTaskID ? '' : 'disabled' }`} onClick={onAddBtnClick}>Добавить</Button>
				<Button type="primary" className="button saveBtn" onClick={onSaveBtnClick}>Применить изменения</Button>
				<Button type="default" className="button deleteAllBtn" onClick={deleteAllBtnClick}>Удалить все</Button>
				<Button type="default" className="button addAllBtn" onClick={onAddAllBtnClick}>Добавить все из конфига</Button>
			</div>

			<div className="bottom tasks">{ tasks.length ? tasksItems : <div className="empty">Пусто.</div> }</div>
		</Modal>
	)
}

class Task {
	constructor( id, config ) {
		this.id = id
		this.config = config
		this.value = null
	}

	get valueWithoutDiff() {
		const value = [ ...this.value ]

		value.shift()

		return value
	}

	get difficulty() {
		return this.value[0]
	}
}