import { Modal, Alert, Button } from "antd"
import { useImmer } from "use-immer"
import EmojiSelect from "./EmojiSelect"
import EmojiTable from "./EmojiTable"
import zebomba from "../../../tools/zebomba"

export default function EmojiModal({ isModalOpen, handleCancel, smiles: initialSmiles, onChangeHandler }) {
	const [ smiles, setSmiles ] = useImmer(initialSmiles || [])

	const onSave = () => {
		onChangeHandler(smiles)

		zebomba.message.success('Смайлы успешно изменены')

		handleCancel()
	}

	return (
		<Modal
			width={680}
			className="emojiFieldModal"
			destroyOnClose={true}
			transitionName=""
			title="Доступные смайлы игрока"
			open={isModalOpen}
			footer=""
			onCancel={handleCancel}>

			<Alert type="info" message="Смайлы доступны с точки 1-4"/>

			<div style={{
				margin: '20px 0 40px 0'
			}}>
				<EmojiSelect setSmiles={setSmiles} smiles={smiles}/>

				<Button type="primary" style={{ marginTop: 10 }} onClick={onSave}>Сохранить</Button>
			</div>

			<EmojiTable setSmiles={setSmiles} smiles={smiles}/>
		</Modal>
	)
}