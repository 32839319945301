import * as _ from 'lodash-es'
import { useAuth } from '../../provider/AuthProvider'
import FormField from '../form/FormField'
import { useState } from 'react'
import AppConfig from '../config/AppConfig'
import zebomba from '../../tools/zebomba'
import { useImmer } from 'use-immer'
import Button from '../UI/Button'

export default function SetCrystals({ data }) {
	const [ isRequestInProgress, setIsRequestInProgress ] = useState(false)
	const [ formData, setFormData ] = useImmer({})

	const { user } = useAuth()

	document.title = data.title

	const isSetBtnDisabled = !(formData.appID && formData.userID && formData.giftValue)

	const onSetBtnClick = async () => {
		setIsRequestInProgress(true)

		const params = _.assign({
			id: user.id,
			access_token: user.token,
			user_id: formData.userID,
			crystals: formData.giftValue
		}, user.apps.byName[formData.appID].dataForRequest)

		const response = await fetch(AppConfig.api.setCrystals, {
			method: 'POST',
			body: new URLSearchParams(params)
		})

		const data = await response.json()
		
		setIsRequestInProgress(false)

		if (data.success === true) {
			zebomba.message.success('Кристаллы успешно начислены')
		}
		else if (data.error) {
			zebomba.message.error(data.error)
		}
		else {
			zebomba.message.error('Что-то пошло не так...')
		}
	}

	return (
		<div className="content">
			<div className="setCrystalsForm form">
				<div className="title">Начисление кристаллов</div>

				<FormField
					data={{ name: 'appID', label: 'Приложение', type: 'select', options: user.apps.allNamesList, hideIndex: true, showSearch: true }}
					onFieldChange={field => { setFormData(data => { data.appID = field.config.options[field.value] }) }} />

				<FormField
					data={{ name: 'userID', label: 'ID пользователя', type: 'text' }}
					onFieldChange={field => { setFormData(data => { data.userID = field.value }) }}
					defaultValue={formData.userID}/>

				<FormField
					data={{ name: 'giftValue', label: 'Количество', type: 'number' }}
					onFieldChange={field => { setFormData(data => { data.giftValue = field.value }) }}
					defaultValue={formData.giftValue}/>

				<Button className='setBtn' disabled={isSetBtnDisabled} onClick={onSetBtnClick} wait={isRequestInProgress}>Начислить</Button>
			</div>
		</div>
	)
}