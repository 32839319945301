export default {
	"1": {
		//Наименование
		'name': 'angry',
		//Редкость
		'rarity': 'common',
		//Смайл по умолчанию
		'default': 1,
		//Тип эмоции (звук)
		'type': 'angry'
	},
	"2": {
		'name': 'sad',
		'rarity': 'common',
		'default': 1,
		'type': 'sad'

	},
	"3": {
		'name': 'boring',
		'rarity': 'common',
		'default': 1,
		'type': 'yawn'
	},
	"4": {
		'name': 'laugh',
		'rarity': 'common',
		'default': 1,
		'type': 'joy'
	},
	"5": {
	  'name': 'winter_present',
	  'rarity': 'epic',
	  'type': 'whistle',
	  'awards_mixer': 'winter'
	},
	"6": {
	  'name': 'winter_shy',
	  'rarity': 'rare',
	  'type': 'smile-f',
	  'awards_mixer': 'winter'
	},  
	"7": {
	  'name': 'winter_deer',
	  'rarity': 'rare',
	  'type': 'smile-f',
	  'awards_mixer': 'winter'
	},
	"8": {
	  'name': 'winter_laugh',
	  'rarity': 'common',
	  'type': 'joy',
	  'awards_mixer': 'winter'
	},
	"9": {
	  'name': 'winter_snowflake',
	  'rarity': 'epic',
	  'type': 'smile-m',
	  'awards_mixer': 'winter'
	},
	"10": {
	  'name': 'winter_heart',
	  'rarity': 'common',
	  'type': 'kiss',
	  'awards_mixer': 'winter'
	},
	"11": {
	  'name': 'tiger',
	  'rarity': 'legendary',
	  'type': 'smile-f',
	  'awards_mixer': 'winter'
	},
	"12": {
	  'name': 'winter_sad',
	  'rarity': 'common',
	  'type': 'sad',
	  'awards_mixer': 'winter'
	},
	"13": {
	  'name': 'winter_magic',
	  'rarity': 'epic',
	  'type': 'smile-f',
	  'awards_mixer': 'winter'
	},
	"14": {
	  'name': 'winter_sorry',
	  'rarity': 'rare',
	  'type': 'surprise',
	  'awards_mixer': 'winter'
	},
	"15": {
	  'name': 'portal_hello',
	  'rarity': 'rare',
	  'type': 'joy'
	},
	"16": {
	  'name': 'portal_cool',
	  'rarity': 'common',
	  'type': 'smile-m'
	},
	"17": {
	  'name': 'portal_devil',
	  'rarity': 'epic',
	  'type': 'smile-m'
	},
	"18": {
	  'name': 'portal_question',
	  'rarity': 'rare',
	  'type': 'surprise'
	},
	"19": {
	  'name': 'portal_thinking',
	  'rarity': 'epic',
	  'type': 'surprise'
	},
	"20": {
	  'name': 'portal_angry',
	  'rarity': 'common',
	  'type': 'angry'
	},
	"21": {
	  'name': 'portal_bomb',
	  'rarity': 'legendary',
	  'type': 'smile-f'
	},
	"22": {
	  'name': 'portal_time',
	  'rarity': 'rare',
	  'type': 'angry'
	},
	"23": {
	  'name': 'portal_nice',
	  'rarity': 'common',
	  'type': 'joy'
	},
	"24": {
	  'name': 'portal_applause',
	  'rarity': 'epic',
	  'type': 'joy'
	},
	"25": {
	  'name': 'spring_girl',
	  'rarity': 'epic',
	  'type': 'smile-f',
	  'awards_mixer': 'spring'
	},
	"26": {
	  'name': 'spring_sunglasses',
	  'rarity': 'rare',
	  'type': 'smile-m',
	  'awards_mixer': 'spring'
	},
	"27": {
	  'name': 'spring_sun',
	  'rarity': 'common',
	  'type': 'joy',
	  'awards_mixer': 'spring'
	},
	"28": {
	  'name': 'spring_flowerLover',
	  'rarity': 'rare',
	  'type': 'smile-f',
	  'awards_mixer': 'spring'
	},
	"29": {
	  'name': 'spring_please',
	  'rarity': 'common',
	  'type': 'afraid',
	  'awards_mixer': 'spring'
	},
	"30": {
	  'name': 'spring_butterfly',
	  'rarity': 'common',
	  'type': 'smile-f',
	  'awards_mixer': 'spring'
	},
	"31": {
	  'name': 'spring_egg',
	  'rarity': 'legendary',
	  'type': 'afraid',
	  'awards_mixer': 'spring'
	},
	"32": {
	  'name': 'spring_fairy',
	  'rarity': 'epic',
	  'type': 'smile-f',
	  'awards_mixer': 'spring'
	},
	"33": {
	  'name': 'spring_flower',
	  'rarity': 'epic',
	  'type': 'surprise',
	  'awards_mixer': 'spring'
	},
	"34": {
	  'name': 'spring_tulip',
	  'rarity': 'rare',
	  'type': 'smile-m',
	  'awards_mixer': 'spring'
	},
	"35": {
	  'name': 'summer_jellyfishLaugh',
	  'rarity': 'epic',
	  'type': 'joy',
	  'awards_mixer': 'summer'
	},
	"36": {
	  'name': 'summer_starfish',
	  'rarity': 'legendary',
	  'type': 'kiss',
	  'awards_mixer': 'summer'
	},
	"37": {
	  'name': 'summer_timeHat',
	  'rarity': 'common',
	  'type': 'angry',
	  'awards_mixer': 'summer'
	},
	"38": {
	  'name': 'summer_pearl',
	  'rarity': 'rare',
	  'type': 'smile-m',
	  'awards_mixer': 'summer'
	},
	"39": {
	  'name': 'summer_coctail',
	  'rarity': 'rare',
	  'type': 'joy',
	  'awards_mixer': 'summer'
	},
	"40": {
	  'name': 'summer_underwater',
	  'rarity': 'common',
	  'type': 'whistle',
	  'awards_mixer': 'summer'
	},
	"41": {
	  'name': 'summer_kissHat',
	  'rarity': 'rare',
	  'type': 'kiss',
	  'awards_mixer': 'summer'
	},
	"42": {
	  'name': 'summer_camomile',
	  'rarity': 'common',
	  'type': 'sad',
	  'awards_mixer': 'summer'
	},
	"43": {
	  'name': 'summer_jellyfishSurprise',
	  'rarity': 'epic',
	  'type': 'surprise',
	  'awards_mixer': 'summer'
	},
	"44": {
	  'name': 'summer_lifebuoy',
	  'rarity': 'epic',
	  'type': 'angry',
	  'awards_mixer': 'summer'
	},
	"45": {
	  'name': 'cinema',
	  'rarity': 'epic',
	  'type': 'joy',
	},    	
	"46": {
		'name': 'alienHypnosis',
		'rarity': 'legendary',
		'type': 'magic_sphere',	
	},
	"47": {
		'name': 'alienThink',
		'rarity': 'legendary',
		'type': 'magic_sphere',		
	},
	"48": {
		'name': 'alienLaugh',
		'rarity': 'legendary',
		'type': 'magic_sphere',		
	},		
	"49": {
		'name': 'beginner_search',
		//'rarity': 'common',
		//'type': 'beginner_kits',		
	},
	"50": {
		'name': 'beginner_cup',
		//'rarity': 'common',
		//'type': 'beginner_kits',		
	},
	"51": {
		'name': 'beginner_candy',
		//'rarity': 'common',
		//'type': 'beginner_kits',		
	}
}