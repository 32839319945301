import * as _ from 'lodash-es'
import { useRef, useEffect, useState } from "react"

import AirDatepicker from 'air-datepicker'

export default function DateTimeField({ data = {}, onValueChange, defaultValue }) {
	const previousDate = useRef(new Date())
	const dateItem = useRef(null)
	const [ datePicker, setDatePicker ] = useState(null)

	const isMounted = useRef(false)
	const isPickerSet = useRef(false)

	let timestamp = defaultValue

	if (timestamp) {
		if (data.returnType !== 'string') {
			const timestampLength = String(timestamp).length

			if (timestampLength === 10) {
				timestamp *= 1e3
			}
			else if (timestampLength !== 13) {
				console.error(`Wrong timestamp length! Field: ${ data.name }`)
			}
		}
		else {
			timestamp = new Date(timestamp)
		}
	}

	const onDatePickerSelect = picker => {
		if (picker.date && isPickerSet.current) {
			if (data.returnType === 'string') {
				onValueChange(picker.datepicker.formatDate(picker.date, 'yyyy-MM-dd HH:mm'))
			}
			else {
				onValueChange(Math.floor(picker.date.getTime() / 1e3))
			}
		}

		isPickerSet.current = true
	}

	useEffect(() => {
		if (dateItem.current && !datePicker) {
			const todayBtn = {
				content: 'Сегодня',
				onClick: dp => {
					const date = new Date()

					dp.selectDate(date, { updateTime: true })
					dp.setViewDate(date)
				}
			}

			const pickerProps = {
				timepicker: true,
				onSelect: onDatePickerSelect,
				onChangeViewDate: onDatePickerSelect,
				buttons: [ todayBtn ]
			}

			if (timestamp > 0) {
				pickerProps.startDate = new Date(timestamp)
			}

			const picker = new AirDatepicker(dateItem.current, pickerProps)

			if (timestamp > 0) {
				picker.selectDate(new Date(timestamp), { updateTime: true })
			}
			else {
				picker.clear()
			}

			setDatePicker(picker)

			const onWindowResize = () => {
				const isMobile = window.innerWidth <= 992

				picker.update({ isMobile })
			}

			window.addEventListener('resize', onWindowResize)
			
			onWindowResize()

			return () => {
				picker.destroy()

				window.removeEventListener('resize', onWindowResize)
			}
		}
	// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (datePicker && isMounted.current) {
			if (timestamp <= 0) {
				datePicker.clear()
			}
			else {
				datePicker.selectDate(timestamp)
			}
		}

		isMounted.current = true
	// eslint-disable-next-line
	}, [ defaultValue ])

	const onInputKeyUp = e => {
		const value = e.target.value.trim()

		if (value) {
			const dateParts = value.split('.')
			const date = [ dateParts.splice(1, 1), dateParts.splice(0, 1), ...dateParts ].join('.')

			if (_.isNaN(Date.parse(date))) {
				onValueChange('0000-00-00')
			}
			else {
				datePicker.selectDate(date, { updateTime: true })
				datePicker.setViewDate(date)
			}
		}
	}

	const onInputBlur = e => {
		const value = e.target.value.trim()

		if (value) {
			const dateParts = value.split('.')
			const date = [ dateParts.splice(1, 1), dateParts.splice(0, 1), ...dateParts ].join('.')

			if (_.isNaN(Date.parse(date))) {
				datePicker.selectDate(previousDate.current, { updateTime: true })
				datePicker.setViewDate(previousDate.current)
			}
		}
		else if (value === '') {
			onValueChange(value)
		}
	}

	const onInputFocus = e => {
		const value = e.target.value.trim()

		if (value) {
			const dateParts = value.split('.')
			const date = [ dateParts.splice(1, 1), dateParts.splice(0, 1), ...dateParts ].join('.')

			if (!_.isNaN(Date.parse(date))) {
				previousDate.current = new Date(date)
			}
		}
	}

	const defaultDate = datePicker && timestamp > 0 && datePicker.formatDate(new Date(timestamp), 'dd.MM.yyyy HH:mm')

	return (
		<div className="dateInput dateTime dateTimeField">
			<input ref={dateItem} className="time" defaultValue={defaultDate} onKeyUp={onInputKeyUp} onBlur={onInputBlur} onFocus={onInputFocus}/>
		</div>
	)
}