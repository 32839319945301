import podarokConfigs from "./config/config"
import { useImmer } from "use-immer"
import { Button, Form, Select, Empty, InputNumber } from "antd"
import * as _ from 'lodash-es'
import FeatherIcon from 'feather-icons-react'
import { useEffect } from "react"
import { useAppData } from "../../pages/Project"

export default function PodarokRewards({ selectedApp, rewards: initialRewards, onChange }) {
	const app = useAppData()
	const [ rewards, setRewards ] = useImmer(initialRewards || {})

	useEffect(() => {
		onChange(rewards)
	// eslint-disable-next-line
	}, [ rewards ])

	const podarokConfig = app && podarokConfigs[app.config.name] || podarokConfigs[selectedApp]
	const rewardsOptions = _.map(podarokConfig.rewards, (reward, index) => <Select.Option key={index} value={Number(index)}>{ reward.label } <span style={{ opacity: .6 }}>{ reward.name }</span></Select.Option>)

	const rewardsItems = _.map(rewards, (value, rewardType) => {
		const reward = podarokConfig.rewards[rewardType]
		
		return (
			<div className="reward" key={rewardType}>
				<div className="label">{ reward.label }
					<div className="name">{ reward.name }</div>
				</div>
				<InputNumber
					controls={false}
					className="value"
					defaultValue={value}
					onChange={value => onRewardValueChange(rewardType, value)}/>
				<div className="deleteBtn" onClick={() => deleteReward(rewardType)}>
					<FeatherIcon icon="x" color="#e70000"/>
				</div>
			</div>
		)
	})

	const deleteReward = rewardID => {
		setRewards(rewards => {
			delete rewards[rewardID]

			return rewards
		})
	}

	const onRewardValueChange = (rewardType, value) => {
		setRewards(rewards => {
			rewards[rewardType] = value

			return rewards
		})
	}

	const addReward = rewardID => {
		setRewards(rewards => {
			rewards[rewardID] = 1

			return rewards
		})
	}

	return (
		<div className="rewardsBox">
			<b className="title">Награды</b>

			<div className="rewards">{ rewardsItems.length && rewardsItems || <Empty /> }</div>

			<Form onFinish={data => addReward(data.reward)} className="addBox">
				<Form.Item
					style={{ flex: '1 1' }}
					name="reward"
					rules={[
						{
						required: true,
						message: 'Обязательное поле',
						},
					]}>
					<Select popupMatchSelectWidth={false}>
						{ rewardsOptions }
					</Select>
				</Form.Item>

				<Button type="primary" htmlType='submit' style={{ marginLeft: 10 }}>Добавить</Button>
			</Form>
		</div>
	)
}