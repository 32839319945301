import { Modal, Button, Tabs } from 'antd'
import * as _ from 'lodash-es'
import { useImmer } from 'use-immer'
import PodarokForm from './PodarokForm'
import zebomba from '../../../tools/zebomba'

class Popups {
	constructor( popups ) {
		this.list = []

		_.forEach(popups, (popup, index) => {
			this.list.push(new Popup(index, popup))
		})
	}

	add() {
		this.list.push(new Popup(this.list.length))
	}

	removeByKey( key ) {
		this.list = _.filter(this.list, (popup, index) => index !== key)
	}

	getValue() {
		return _.map(this.list, popup => popup.data)
	}
}

class Popup {
	constructor( id, data ) {
		this.id = id
		this.data = data || []
	}
}

export default function PodarokModal({ selectedApp, isModalOpen, handleCancel, initialData, onChangeHandler }) {
	const [ popups, setPopups ] = useImmer(new Popups(initialData))

	const tabItems = _.map(popups.list, (popup, index) => ({
		label: `Окно ${ index + 1 }`,
		key: index,
		children: <PodarokForm selectedApp={selectedApp} key={popup.id} onChange={data => onFormChange(index, data)} initialData={popup.data}/>
	}))

	const onFormChange = (formIndex, data) => {
		const popup = _.find(popups.list, (popup, index) => index === formIndex)

		popup && (popup.data = data)
	}

	const addPopup = () => {
		popups.add()

		const newPopups = new Popups()
		newPopups.list = popups.list

		setPopups(newPopups)
	}

	const removePopup = key => {
		popups.removeByKey(key)

		const newPopups = new Popups()
		newPopups.list = popups.list

		setPopups(newPopups)
	}

	const onTabsEdit = (targetKey, action) => {
		if (action === 'add') {
			addPopup()
		} else {
			removePopup(targetKey)
		}
	}

	const apply = () => {
		onChangeHandler(popups.getValue())

		handleCancel()

		zebomba.message.success('Информационные окна обновлены')
	}

	return (
		<Modal
			className="podarokModal"
			width={740}
			destroyOnClose={true}
			transitionName=""
			title="Информационные окна"
			open={isModalOpen}
			footer=""
			onCancel={handleCancel}>

			<Tabs
				type="editable-card"
				items={tabItems}
				onEdit={onTabsEdit}
				addIcon={<div>Добавить окно</div>}
			/>

			<Button type="primary" onClick={apply} className='submitBtn'>Применить</Button>
		</Modal>
	)
}