import AppConfig from "./config/AppConfig"
import { useEffect, useState } from "react"
import { DeviceUUID } from "device-uuid"

export default function CheckIP({ children }) {
	const [ response, setResponse ] = useState(null)

	useEffect(() => {
		const check = async () => {
			// Необходимо для настройки доступа при мобильном интернете
			// Доступ предоставляется не по IP, а по uuid устройства
			const params = new URLSearchParams({
				device_id: new DeviceUUID().get()
			})

			const url = `${ AppConfig.api.checkUserIP }?${ params }`
			const response = await fetch(url)
			const data = await response.json()
			
			setResponse(data)
		}

		check()
	}, [])

	if (response !== null) {
		return response?.success === true && children || <h1 style={{
			textAlign: 'center',
			marginTop: '10%',
			fontSize: '21px'
		}}>{ response.error }</h1>
	}
}