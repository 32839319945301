import FieldFactory from "../FieldFactory"
import * as _ from 'lodash-es'
import { useState, useRef } from "react"
import { Alert } from "antd"
import zebomba from "../../../tools/zebomba"
import battlePassTasksLabels from './configs/labels'

export default function TaskItem({ task, updateTasks }) {
	const difficultyOptions = _.reduce(task.config.difficult, (options, item, diffID) => { options[diffID] = diffID; return options }, {})
	const initialDifficulty = (task.value && task.difficulty) || Number(_.keys(difficultyOptions)[0])
	const [ difficulty, setDifficulty ] = useState(initialDifficulty)

	const fields = useRef(task.value || [ difficulty, 0, 0, 0])

	const onTaskChange = value => {
		const prevDifficulty = fields.current[0]
		const difficulty = Number(value[0])

		fields.current = value.concat([])

		if (prevDifficulty !== difficulty) {
			// Сбросить время окончания
			fields.current[3] = 0
		}

		setDifficulty(difficulty)

		task.value = value
		_.defer(() => updateTasks(tasks => [ ...tasks ]))
	}
	
	if (!task.value) {
		onTaskChange(fields.current)
	}

	const deleteTask = () => {
		updateTasks(tasks => _.without(tasks, task))
	}

	// const getTommorrowDatetime = () => {
	// 	const now = new Date()
	// 	const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)

	// 	return Math.floor(tomorrow.getTime() / 1e3)
	// }

	const getEndTime = () => {
		if (!fields.current[3]) {
			// Время окончания - 10 утра
			const tenTodayTimestamp = new Date().setHours(10, 0, 0, 0)
			const now = Date.now()
			const daySeconds = 86400
			
			if (now > tenTodayTimestamp) {
				return tenTodayTimestamp + daySeconds * 1e3 * difficulty
			}

			return tenTodayTimestamp
		}
		
		return fields.current[3]
	}

	let description

	if (!_.has(task.config.difficult, difficulty)) {
		difficultyOptions[difficulty] = difficulty

		description = <Alert type="warning" message={`В конфиге для данного задания нет сложности ${ difficulty }`}/>
	}
	else {
		description = <>
			<div className="description" dangerouslySetInnerHTML={ generateTaskText(task, task.config.difficult[difficulty], battlePassTasksLabels) }/>
			<div className="reward">Награда: { task.config.difficult[difficulty].items }</div>
		</>
	}

	return (
		<div className={ `taskItem ${ task.config.name }` }>
			<div className="title">
				<div className="id">#{ task.id }</div>
				<div className="name">{ task.config.name }</div>
			</div>

			{ description }

			<div className="fields">
				<FieldFactory
					data={{
						name: 'battlePassTaskData',
						type: 'array',
						views: {
							0: {
								class: 'difficulty',
								title: 'Сложность',
								type: 'select',
								options: difficultyOptions,
								hideIndex: true,
								hideName: true,
								value: difficultyOptions[difficulty]
							},
							1: {
								class: 'status',
								title: 'Статус',
								type: 'select',
								hideName: true,
								options: {
									0: 'Не выполнено',
									1: 'Выполнено'
								},
								value: fields.current[1]
							},
							2: {
								class: 'progress',
								title: 'Прогресс',
								hideName: true,
								type: 'number',
								value: fields.current[2]
							},
							3: {
								class: 'endTime',
								title: 'Время окончания',
								type: 'datetime',
								hideName: true,
								value: getEndTime()
							}
						},
						value: fields.current
					}}
					onFieldChange={field => onTaskChange(field.value)}/>
			</div>

			<div className="deleteBtn" onClick={deleteTask}>+</div>
		</div>
	)
}

const generateTaskText = (task, { count, place }, labels) => {
	const isEvent = /event\d+/.test(task.config.name)
	// const { count, place } = data
	// const eventConfig = Game.gameEvents.getEventConfig(eventId) || {}
	// let icon = `<span class="$1 ${name}"></span>`
	// let html = ''

	let name = task.config.name

	if (isEvent) {
		name = task.config.name.replace(/^event([0-9]+)/, 'event')
	}

	let label = labels[name]

	switch (name) {
		case 'collect_stars':
			label = labels.collect_items
			break
		case 'collect_rating':
			label = labels.collect_cups
			break
		case 'enter_top_in_league':
			label = label.replace('{{cnt}}', place)
			break
		default: {}
	}

	const eventsNames = {
		1: 'Поиск мяча',
		2: 'Путь домой',
		3: 'Книга Историй',
		4: 'Заплыв на лодках',
		5: 'Таинственное послание',
		6: 'Колыбельная бабочек',
	}

	if (label) {
		label = label
			.replace('{{cnt}}', count)
			.replace('<<icon>>', '<div class="icon"></div>')

		if (isEvent) {
			const eventID = task.config.name.replace(/event(\d+).*/, '$1')

			label = label.replace('{{name}}', `"${ eventsNames[eventID] }"`)
		}
	}
	else {
		label = ''
		zebomba.message.error(`Не найден текст для задания: ${ name }`)
	}

	return {
		__html: label
	}
}