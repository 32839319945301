import { useState } from 'react'
import EmojiModal from './EmojiModal'

export default function EmojiField({ data, onValueChange, defaultValue }) {
	const [ isModalOpen, setIsModalOpen ] = useState(false)
	const showModal = () => { setIsModalOpen(true) }
	const handleCancel = () => { setIsModalOpen(false) }

	return (
		<>
			<div className="button grey changeBtn" onClick={showModal}>Изменить</div>

			{ isModalOpen && <EmojiModal
				smiles={defaultValue}
				onChangeHandler={onValueChange}
				closeModal={handleCancel}
				isModalOpen={true}
				handleCancel={handleCancel}/> }
		</>
	)
}