import * as _ from 'lodash-es'
import { Input, Form, Flex } from "antd"
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import { useImmer } from "use-immer"
import zebomba from 'js/tools/zebomba'

export default function DevTools({ data }) {
	const [ outJS, setOutJS ] = useImmer('')

	document.title = data.title

	const parseHTML = html => {
		const container = document.createElement('div')

		container.innerHTML = html
		
		const parseNode = (node, nestLevel = 0) => {
			return _.filter(_.map(node.childNodes, node => {
				const tabs = '\t'.repeat(nestLevel)
				const options = {}
				const textContent = node.textContent.trim()
				const tagName = node.tagName?.toLowerCase()

				if (tagName && tagName !== 'div') {
					options.tag = `'${ tagName }'`
				}

				if (textContent) {
					if (!node.childElementCount) {
						options.textContent = `'${ textContent }'`

						node.innerHTML = ''
					}
				}

				if (node instanceof Text) {
					if (!textContent) {
						return
					}

					options.tag = `${ 'fragment' }`
				}
				else {
					node.classList.remove('__vocabulary__')
		
					if (node.id) {
						options.id = `'${ node.id }'`
					}

					if (node.classList.length) {
						options.class = `'${ node.className }'`
					}

					const vocabularyPath = node.getAttribute('data-vocabulary-key')

					if (vocabularyPath) {
						options.textContent = `app.language.getText('${ vocabularyPath }')`
					}
				}
	
				const needNewline = _.size(options) > 1
				const optionsStrings = _.map(options, (value, option) => (
					`${ needNewline ? `\n${ tabs }\t` : '' }${ option }: ${ value }`
				))

				let optionsString = `{ ${ optionsStrings.join(',') }${ needNewline ? `\n${ tabs }` : '' } }`
				
				let childrenString = ''

				if (node.childNodes) {
					const childrenGroup = parseNode(node, nestLevel + 1)

					if (!_.isEmpty(childrenGroup)) {
						childrenString = `, [\n${
							childrenGroup.join(',\n')
						}\n${ tabs }]`
					}
				}

				return `${ tabs }createElement(${ optionsString }${ childrenString })`
			}))
		}

		const result = parseNode(container)[0]

		setOutJS(result)
	}

	const tryParse = html => {
		try {
			parseHTML(html)
		}
		catch(e) {
			zebomba.message.error(e)
		}
	}

	return (
		<Flex gap={25}>
			<Form.Item
				label="HTML"
				colon={false}
				style={{ maxWidth: 800, flexGrow: 1 }}
				labelCol={{ span: 24 }}
			>
				<Input.TextArea
					style={{ width: '100%', height: 400 }}
					onBlur={e => { tryParse(e.target.textContent.trim()) }}
				>
				</Input.TextArea>
			</Form.Item>

			<FeatherIcon icon="arrow-right"/>

			<Form.Item
				label="JS"
				colon={false}
				style={{ maxWidth: 800, flexGrow: 1 }}
				labelCol={{ span: 24 }}
			>
				<Input.TextArea
					onFocus={e => { e.target.select() }}
					style={{ width: '100%', height: 400 }}
					value={outJS}
				>
				</Input.TextArea>
			</Form.Item>
		</Flex>
	)
}