import React from "react"
import ReactDOM from "react-dom/client"
import { AuthProvider } from "./provider/AuthProvider"
import { Routes, Route, BrowserRouter } from "react-router-dom"

import Login from "./components/pages/Login"
import Project from "./components/pages/Project"
import Authentication from "./routes/Authentication"
import CheckIP from "./components/CheckIP"
import Pages from "./components/pages/Pages"

import './styles/main.scss'

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
	// <React.StrictMode>
	<BrowserRouter basename="/client">
		<AuthProvider>
			<CheckIP>
				<Routes>
					<Route path='/' element={<Authentication><Pages/></Authentication>} />
					<Route path='login' element={<Login/>} />
					<Route path=':pageID/*' element={<Authentication><Pages/></Authentication>} />
					<Route path='apps/:appID/:userID?' element={<Authentication><Project/></Authentication>} />
				</Routes>
			</CheckIP>
		</AuthProvider>
	</BrowserRouter>
)