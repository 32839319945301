import { Children, isValidElement, cloneElement, useEffect } from "react"
import { useForm } from "../AppForm"
import FeatherIcon from "feather-icons-react/build/FeatherIcon"
import { Popover } from "antd"
import * as _ from 'lodash-es'

export default function Field({ data, defaultValue, onFieldChange, children }) {
	const { fieldsRefs, updateFieldsRefs } = useForm()

	let itemClasses = `field ${ data.name } field_${ data.type }`
	let refInfo

	if (data.class) {
		itemClasses += ` ${ data.class }`
	}

	const updateRefInfo = () => {
		if (data.ref) {
			const refFields = _.filter(fieldsRefs.current.list[data.ref], name => name !== data.name)
	
			const popoverContent = (
				<div>
					Это поле ссылается на
					{ refFields.length > 1 && ' другие' || ' другое' }
					: <b>{ refFields.join(', ') }</b><br/>
					При изменении значения оно изменится во всех
				</div>
			)

			if (refFields.length) {
				// eslint-disable-next-line
				itemClasses += ' refField'

				// eslint-disable-next-line
				refInfo = <div className="refInfo">
					<Popover content={popoverContent}>
						<FeatherIcon icon="link-2" color="#6668cc"/>
					</Popover>
				</div>
			}
		}
	}

	useEffect(updateRefInfo, [])

	updateRefInfo()

	const onValueChange = value => {
		onFieldChange({
			name: data.name,
			config: data,
			value
		})

		// Изменить значение во всех связанных полях
		if (data.ref) {
			updateFieldsRefs(data.ref, value)
		}
	}

	const childrenProps = {
		data,
		defaultValue,
		onValueChange
	}

	const childrenWithProps = Children.map(children, child => {
		if (isValidElement(child)) {
		  return cloneElement(child, childrenProps)
		}

		return child
	})

	return (
		<div className={ itemClasses }>
			{ refInfo }

			<div className="inner">
				<div className="leftSide">
					{
						data.info && (
							<Popover content={<div className="info" style={{ whiteSpace: 'pre' }}>{ data.info }</div>}>
								<div className="title hasInfo">{ data.title }</div>
							</Popover>
						) || (
							<div className="title">{ data.title }</div>
						)
					}

					<div className="name">{ !data.hideName && (data.name && data.name.includes('missions') ? '' : data.name) }</div>
				</div>
				
				{ childrenWithProps }
			</div>
		</div>
	)
}