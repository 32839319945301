import { useAuth } from "../../provider/AuthProvider"
import * as _ from 'lodash-es'
import FeatherIcon from "feather-icons-react/build/FeatherIcon"
import zebomba from "../../tools/zebomba"

export default function SocialIDs() {
	const { user } = useAuth()

	const socials = {}
	const oneSocialID = [ 'vk', 'ok', 'mm', 'fb' ]

	_.forEach(user.apps.available, (app, appID) => {
		if (_.includes(oneSocialID, app.social)) {
			const key = `${ app.social }`
			socials[key] = app.ids
		}
		else {
			socials[appID] = app.ids
		}
	})

	let sortedSocials = _.map(socials, (ids, appName) => ({ name: appName, ids }))
	sortedSocials = _.sortBy(_.sortBy(sortedSocials, item => item.name), item => !_.includes(oneSocialID, item.name))

	if (user.ip) {
		sortedSocials.unshift({
			name: 'IP',
			ids: [ user.ip ]
		})
	}

	return (
		<div className="socialIDs">
			<div className="items">
			{
				_.map(sortedSocials, item => {
					return (
						<div key={item.name} className="item" onClick={() => {
							navigator.clipboard.writeText(item.ids.join(', '))

							zebomba.message.success('Скопировано в буфер обмена')
						}}>
							<b>{ item.name.toUpperCase() }:</b>

							<div className="ids">
								{ item.ids.join(', ') }

								<FeatherIcon icon={"clipboard"}/>
							</div>
						</div>
					)
				})
			}
			</div>
		</div>
	)
}