import { Select, Button } from 'antd'
import { useRef } from 'react'

export default function TableRewardsFilter({ confirm, selectOptions, onChange }) {
	const selectedOption = useRef(0)

	const onChangeBtnClick = () => {
		onChange(selectedOption.current)

		confirm()
	}

	return <div className="fillFilter" style={{ padding: 10 }}>
		<div className="title">Изменить все на:</div>

		<Select
			onChange={value => selectedOption.current = value}
			popupMatchSelectWidth={false}
			options={selectOptions}
			defaultValue={0}
			style={{ width: 143 }}/>

		<Button type="primary" style={{ width: '100%', marginTop: 10 }} onClick={onChangeBtnClick}>Изменить</Button>
	</div>
}