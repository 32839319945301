import FieldFactory from "../FieldFactory"
import * as _ from 'lodash-es'
import { useState } from "react"

export default function CinemaTasksControls({ tasks, addTask, deleteAllTasks, addAllConfigTasks, saveTasks }) {
	const [ selectedTask, setSelectedTask ] = useState(null)

	const tasksOptions = {}

	_.forEach(tasks, (task, taskID) => {
		tasksOptions[taskID] = `${ taskID } (${ task.type === 'quiz' ? 'опрос' : 'реклама' })`
	})

	return (
		<div className="controls">
			<div className="group">
				<FieldFactory
					data={{
						name: 'cinemaNewTasks',
						title: `Новое задание`,
						type: 'select',
						options: tasksOptions,
						hideIndex: true
					}}
					onFieldChange={field => setSelectedTask(field.value)}></FieldFactory>
				
				<div className={`button blue addBtn ${ selectedTask === null ? 'disabled' : '' }`} onClick={() => addTask(selectedTask)}>Добавить</div>
				<div className="button blue saveBtn" onClick={saveTasks}>Применить изменения</div>
			</div>

			<div className="group">
				<div className="button grey" onClick={deleteAllTasks}>Удалить все</div>
				<div className="button grey" onClick={addAllConfigTasks}>Добавить все из конфига</div>
			</div>
		</div>
	)
}