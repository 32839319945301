import FieldFactory from 'js/components/appForm/FieldFactory'
import { Card, Alert } from 'antd'
import * as _ from 'lodash-es'

function ObjectSubgroup({ data, objectValue, onFieldChange }) {
	const isObjectKeyForView = !_.isArray(data.views)

	const fieldsViews = _.map(data.views, (fieldData, mapIndex) => {
		if (!isObjectKeyForView && !_.has(fieldData, 'index')) {
			throw new Error(`Failed to find index for "${ data.name }"`)
		}

		// если views объект, то берём его ключ. Если массив - кастомный ключ index
		const index = isObjectKeyForView ? mapIndex : fieldData.index

		fieldData = { ...fieldData }

		fieldData.name = `${ data.name }[${ index }]`
		fieldData.index = index

		const fieldValue = !_.isUndefined(fieldData.value) ? fieldData.value : objectValue[index]
		if (!_.isUndefined(fieldValue)) {
			fieldData.value = fieldValue

			objectValue[index] = fieldData.value
		}

		const onItemChange = field => {
			objectValue[index] = field.value

			onFieldChange({
				name: data.name,
				value: objectValue,
				config: data
			})
		}

		return <FieldFactory key={ index } data={ fieldData } onFieldChange={ onItemChange }/>
	})

	if (data.name === 'missions' || data.name === 'missions[0]' || !data.name.includes('missions[0]')) {
		return <>{ fieldsViews }</>
	}

	return (
		<Card
			title={<>{ data.title } <span style={{ opacity: .6 }}>{ data.name }</span></>}
			style={{
				margin: '15px 0',
				borderColor: '#c5c3c3'
			}}
		>
			{ data.name === 'missions[0][19]' &&
				<Alert
					type='info'
					message="Кулдаун = время окончания + 48 часов (конфиг сервера) "
					style={{ marginBottom: 20, fontSize: 15 }}
				/> }

			{ fieldsViews }
		</Card>
	)
}

export default ObjectSubgroup