export default {
	tasks: {
        "1": {
            "name": "earn_coins",
            "difficult": {
                "1": {
                    "count": 2,
                    "items": 10
                },
                "2": {
                    "count": 3,
                    "items": 20
                },
                "3": {
                    "count": 5,
                    "items": 30
                }
            }
        },
        "2": {
            "name": "earn_rating",
            "difficult": {
                "1": {
                    "count": 17,
                    "items": 10
                },
                "2": {
                    "count": 25,
                    "items": 20
                },
                "3": {
                    "count": 38,
                    "items": 30
                }
            }
        },
        "3": {
            "name": "win_games",
            "difficult": {
                "2": {
                    "count": 1,
                    "items": 20
                },
                "3": {
                    "count": 2,
                    "items": 30
                }
            }
        },
        "4": {
            "name": "use_fortune_or_lottery",
            "difficult": {
                "3": {
                    "count": 1,
                    "items": 30
                }
            }
        },
        "5": {
            "name": "use_boosters",
            "difficult": {
                "1": {
                    "count": 20,
                    "items": 10
                },
                "2": {
                    "count": 30,
                    "items": 20
                },
                "3": {
                    "count": 45,
                    "items": 30
                }
            }
        },
        "6": {
            "name": "collect_words",
            "difficult": {
                "1": {
                    "count": 50,
                    "items": 10
                },
                "2": {
                    "count": 100,
                    "items": 20
                },
                "3": {
                    "count": 200,
                    "items": 30
                }
            }
        },
        "7": {
            "name": "collect_words_5_letters_long",
            "difficult": {
                "1": {
                    "count": 20,
                    "items": 10
                },
                "2": {
                    "count": 50,
                    "items": 20
                },
                "3": {
                    "count": 100,
                    "items": 30
                }
            }
        },
        "8": {
            "name": "use_hints",
            "difficult": {
                "1": {
                    "count": 1,
                    "items": 10
                },
                "2": {
                    "count": 2,
                    "items": 20
                },
                "3": {
                    "count": 3,
                    "items": 30
                }
            }
        },
        "9": {
            "name": "get_daily_bonus",
            "difficult": {
                "3": {
                    "count": 1,
                    "items": 30
                }
            }
        },
        "10": {
            "name": "complete_daily_task",
            "difficult": {
                "3": {
                    "count": 1,
                    "items": 30
                }
            }
        },
        "11": {
            "name": "play_bonus_level",
            "difficult": {
                "3": {
                    "count": 1,
                    "items": 30
                }
            }
        },
        "12": {
            "name": "become_best_players_league",
            "difficult": {
                "1": {
                    "count": 1,
                    "items": 10,
                    "place": 50
                },
                "2": {
                    "count": 2,
                    "items": 20,
                    "place": 10
                },
                "3": {
                    "count": 1,
                    "items": 30,
                    "place": 3
                }
            }
        },
        "13": {
            "name": "join_group",
            "difficult": {
                "1": {
                    "count": 1,
                    "items": 10
                }
            }
        },
        "14": {
            "name": "add_game_to_left_menu",
            "difficult": {
                "1": {
                    "count": 1,
                    "items": 10
                }
            }
        },
        "15": {
            "name": "open_chest",
            "difficult": {
                "1": {
                    "count": 1,
                    "items": 10
                },
                "2": {
                    "count": 2,
                    "items": 20
                },
                "3": {
                    "count": 3,
                    "items": 30
                }
            }
        },
        "16": {
            "name": "repost",
            "difficult": {
                "1": {
                    "count": 1,
                    "items": 10
                },
                "2": {
                    "count": 2,
                    "items": 20
                },
                "3": {
                    "count": 3,
                    "items": 30
                }
            }
        },
        "17": {
            "name": "collect_words_3_letters_long",
            "difficult": {
                "1": {
                    "count": 20,
                    "items": 10
                },
                "2": {
                    "count": 40,
                    "items": 20
                },
                "3": {
                    "count": 60,
                    "items": 30
                }
            }
        },
        "18": {
            "name": "win_level",
            "difficult": {
                "1": {
                    "count": 3,
                    "items": 10
                },
                "2": {
                    "count": 5,
                    "items": 20
                },
                "3": {
                    "count": 7,
                    "items": 30
                }
            }
        },
        "19": {
            "name": "fill_moneybox",
            "difficult": {
                "1": {
                    "count": 10,
                    "items": 10
                }
            }
        },
        "20": {
            "name": "get_puzzles",
            "difficult": {
                "1": {
                    "count": 8,
                    "items": 10
                },
                "2": {
                    "count": 12,
                    "items": 20
                },
                "3": {
                    "count": 18,
                    "items": 30
                }
            }
        },
        "21": {
            "name": "gift",
            "difficult": {
                "1": {
                    "count": 1,
                    "items": 10
                }
            }
        }
    }
}