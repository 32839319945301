import { useForm } from "../AppForm"
import PopupTypes from "../../popups/PopupTypes"

export default function CinemaTasksField({ data, onValueChange, defaultValue }) {
	const { setPopupData } = useForm()

	const popupData = {
		type: PopupTypes.cinemaTasks,
		fieldName: data.name,
		tasks: defaultValue || [],
		onFieldChange: value => onValueChange(value)
	}

	return <div className="button grey changeBtn" onClick={() => setPopupData(popupData)}>Изменить</div>
}