import find from './find'
import villa from './villa'
import cat from './cat'
import alice from './alice'
import words from './words'

export default {
	find,
	alice,
	cat,
	trip: find,
	sweet: find,
	villa,
	house: villa,
	indi: find,
	pirate: villa,
    words: words,
    wg: []
}