import { Modal, Input, Alert } from "antd"
import * as _ from 'lodash-es'

export default function DetailsModal({ snapshot, closeModal }) {
	return (
		<Modal
			className="snapshotsModal"
			width={532}
			destroyOnClose={true}
			transitionName=""
			title={ `Данные для "${ snapshot.label }"` }
			open={true}
			onCancel={closeModal}
			footer="">

			<Alert message="Изменение данных полей не влияет на набор" type="info" style={{
				margin: '14px 0'
			}}/>

			<div className="fields">
				{ _.map(snapshot.fields, (field, fieldName) => {
					let fieldValue = field

					try {
						fieldValue = JSON.stringify(field)
					}
					catch {}

					return (
						<div className="field" style={{
							marginBottom: 10
						}}>
							<b className="name">{ fieldName }</b>
							<Input value={fieldValue}/>
						</div>
					)
				})
				}
			</div>
		</Modal>
	)
}