export default {
	tasks: {
		"1": {
			"name": "collect_stars",
			"difficult": {
				"1": {
					"count": 3,
					"items": 10
				},
				"2": {
					"count": 6,
					"items": 20
				},
				"3": {
					"count": 9,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"2": {
			"name": "collect_rating",
			"difficult": {
				"1": {
					"count": 25,
					"items": 10
				},
				"2": {
					"count": 50,
					"items": 20
				},
				"3": {
					"count": 75,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"4": {
			"name": "invite_friends",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "friends"
		},
		"5": {
			"name": "use_fortune_or_lottery",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "lobby"
		},
		"6": {
			"name": "find_tasks_items",
			"difficult": {
				"1": {
					"count": 30,
					"items": 10
				},
				"2": {
					"count": 60,
					"items": 20
				},
				"3": {
					"count": 90,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"8": {
			"name": "find_all_differences_on_image",
			"difficult": {
				"1": {
					"count": 15,
					"items": 10
				},
				"2": {
					"count": 30,
					"items": 20
				},
				"3": {
					"count": 45,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"9": {
			"name": "use_hint_or_timer",
			"difficult": {
				"1": {
					"count": 2,
					"items": 10
				},
				"2": {
					"count": 4,
					"items": 20
				},
				"3": {
					"count": 6,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"10": {
			"name": "get_daily_bonus_some_times_in_a_row",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			}
		},
		"11": {
			"name": "complete_daily_task",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "dailyQuests"
		},
		"13": {
			"name": "enter_top_in_league",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10,
					"place": 50
				},
				"2": {
					"count": 1,
					"items": 20,
					"place": 10
				},
				"3": {
					"count": 1,
					"items": 30,
					"place": 3
				}
			},
			"popup": "leaderboard"
		},
		"16": {
			"name": "repost_achievements",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			}
		},
		"17": {
			"name": "use_smilies",
			"difficult": {
				"1": {
					"count": 3,
					"items": 10
				},
				"2": {
					"count": 6,
					"items": 20
				},
				"3": {
					"count": 9,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"18": {
			"name": "find_puzzles",
			"difficult": {
				"1": {
					"count": 12,
					"items": 10
				},
				"2": {
					"count": 24,
					"items": 20
				},
				"3": {
					"count": 36,
					"items": 30
				}
			},
			"popup": "dailyQuests"
		},
		"19": {
			"name": "open_chests",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"21": {
			"name": "event1_win_games",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "selectEvent"
		},
		"22": {
			"name": "event1_complete",
			"difficult": {
				"2": {
					"count": 1,
					"items": 20
				},
				"3": {
					"count": 2,
					"items": 30
				}
			},
			"popup": "selectEvent"
		},
		"25": {
			"name": "event3_win_games",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "selectEvent"
		},
		"26": {
			"name": "event3_find_diamonds",
			"difficult": {
				"1": {
					"count": 5,
					"items": 10
				},
				"2": {
					"count": 10,
					"items": 20
				},
				"3": {
					"count": 15,
					"items": 30
				}
			},
			"popup": "selectEvent"
		},
		"27": {
			"name": "event3_recover_objects",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "selectEvent"
		},
		"28": {
			"name": "event4_win_games",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "selectEvent"
		},
		"29": {
			"name": "event4_get_resources",
			"difficult": {
				"1": {
					"count": 20,
					"items": 10
				},
				"2": {
					"count": 40,
					"items": 20
				},
				"3": {
					"count": 60,
					"items": 30
				}
			},
			"popup": "selectEvent"
		},
		"30": {
			"name": "event4_complete",
			"difficult": {
				"3": {
					"count": 1,
					"items": 30
				}
			},
			"popup": "selectEvent"
		},
		"36": {
			"name": "win_games",
			"difficult": {
				"1": {
					"count": 5,
					"items": 10
				},
				"2": {
					"count": 10,
					"items": 20
				},
				"3": {
					"count": 15,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"38": {
			"name": "open_magic_flask",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"39": {
			"name": "fill_moneybox_coins",
			"difficult": {
				"1": {
					"count": 25,
					"items": 10
				}
			},
			"popup": "tournament"
		},
		"40": {
			"name": "open_star_chest",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"42": {
			"name": "send_friends_notifications",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "friends"
		},
		"43": {
			"name": "play_games_tournament",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"44": {
			"name": "play_games_winner",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				},
				"2": {
					"count": 2,
					"items": 20
				},
				"3": {
					"count": 3,
					"items": 30
				}
			},
			"popup": "tournament"
		},
		"45": {
			"name": "gift",
			"difficult": {
				"1": {
					"count": 1,
					"items": 10
				}
			}
		}
	}
}