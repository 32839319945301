import { useState } from "react"
import { IMaskInput } from "react-imask"
import { Button } from "antd"
import FieldFactory from "../../appForm/FieldFactory"

export default function AddBox({ itemsType, itemsReturnType, defaultIndex, onListAdd, lockIndex, options }) {
	const [ data, setData ] = useState({
		index: '',
		value: ''
	})

	const onInputChange = (type, value) => {
		const newData = {
			index: Number(data.index),
			value: data.value
		}

		newData[type] = itemsReturnType === 'number' ? Number(value) : value

		setData(newData)
	}

	// const itemMaskValue = itemsReturnType === 'number' ? Number : String

	return (
		<div className="item addBox">
			<IMaskInput className="index" disabled={lockIndex} mask={Number} onChange={e => onInputChange('index', e.target.value)} defaultValue={defaultIndex}></IMaskInput>

			<FieldFactory
				data={{
					name: 'newItemValue',
					type: itemsType,
					options,
					value: data.value
				}}

				onFieldChange={field => onInputChange('value', field.value)}
			/>

			<Button type="default" className="addBtn" onClick={() => onListAdd(data)}>Добавить</Button>
		</div>
	)
}