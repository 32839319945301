import PodarokField from "../appForm/podarok/PodarokField"
import { Form, Select, Button, Checkbox, Input } from "antd"
import { useAuth } from "../../provider/AuthProvider"
import * as _ from 'lodash-es'
import { useImmer } from "use-immer"
import AppConfig from "../config/AppConfig"
import zebomba from "../../tools/zebomba"
import DateTimeField from "../appForm/fields/DateTimeField"
import { default as podarokConfig } from "../appForm/podarok/config/config"

export default function InfoPopups({ data }) {
	const [ fields, setFields ] = useImmer({
		appName: '',
		usersIDs: '',
		podarok: '',
		toAllUsers: false,
		adID: '',
		startDate: '',
		endDate: '',
	})
	const [ isRequestInProgress, setIsRequestInProgress ] = useImmer(false)
	const { user } = useAuth()
	const availableApps = _.keys(podarokConfig)
	const appsOptions = _.filter(_.map(user.apps.list, app => {
		if (_.includes(availableApps, app.name)) {
			return <Select.Option key={app.fullName} value={app.fullName}>{ app.fullName }</Select.Option>
		}
	}))

	const isApplyBtnEnabled = fields.appName
		&& (fields.toAllUsers || (!fields.toAllUsers && fields.usersIDs))
		&& fields.podarok
		&& (fields.toAllUsers ? fields.startDate && fields.endDate && fields.startDate < fields.endDate : true)

	document.title = data.title

	const apply = async () => {
		if (!isRequestInProgress) {
			setIsRequestInProgress(true)

			const params = {
				access_token: user.token,
				id: user.id,
				app_name: fields.appName,
				gifts: JSON.stringify(fields.podarok)
			}

			if (fields.startDate) {
				params.start_date = fields.startDate
			}

			if (fields.endDate) {
				params.end_date = fields.endDate
			}

			if (!fields.toAllUsers) {
				params.ids = fields.usersIDs
			}
			else {
				params.to_all_users = 1
			}

			if (fields.adID) {
				params.ad_id = fields.adID
			}

			const response = await fetch(AppConfig.api.setGiftsToUser, {
				method: 'POST',
				body: new URLSearchParams(params)
			})

			const data = await response.json()

			setIsRequestInProgress(false)

			if (data.success === true) {
				zebomba.message.success('Данные успешно начислены')
			}
			else if (data.error) {
				zebomba.message.error(data.error)
			}
			else {
				zebomba.message.error('Что-то пошло не так...')
			}
		}
	}

	return (
		<div className="form">
			<div className="title">{ data.title } — сообщения игроку<br/>или начисление подарка</div>

			<Form layout='vertical'>
				<Form.Item
					name="appName"
					label="Приложение"
					rules={[
						{
							required: true,
							message: 'Обязательное поле',
						}
					]}
					>
					<Select
						onChange={appName => setFields(fields => { fields.appName = appName })}
						showSearch
						filterOption={(input, option) => _.startsWith(option.children, input)}
					>
						{appsOptions}
					</Select>
				</Form.Item>
				
				<Form.Item
					name="adID"
					label="ad_id"
					>
					<Input onChange={e => setFields(fields => { fields.adID = e.target.value } )}/>
				</Form.Item>
				
				<Form.Item
					name="toAllUsers"
					>
					<Checkbox onChange={e => setFields(fields => { fields.toAllUsers = e.target.checked } )}>Всем игрокам</Checkbox>
				</Form.Item>

				{
					fields.toAllUsers && (
						<div className="datesGroup">
							<Form.Item
								name="startDate"
								label="Начало рассылки"
								>
								<div className="field_date">
									<DateTimeField onValueChange={value => setFields(fields => { fields.startDate = value })}/>
								</div>
							</Form.Item>
							
							<Form.Item
								name="startDate"
								label="Окончание рассылки"
								>
								<div className="field_date">
									<DateTimeField onValueChange={value => setFields(fields => { fields.endDate = value })}/>
								</div>
							</Form.Item>
						</div>
					)
				}

				{ !fields.toAllUsers &&
				<Form.Item
					name="usersIDs"
					label="ID пользователей"
					rules={[
						{
							required: true,
							message: 'Обязательное поле',
						}
					]}
					>
					<Select
						onChange={value => setFields(fields => { fields.usersIDs = value && value.join(',') })}
						open={false}
						tokenSeparators={[',']}
						mode="tags"
						// onChange={list => setFormData(data => { data.usersIDs = list.join(',') })}
					/>
				</Form.Item>
				}

				{ fields.appName && (
					<Form.Item label="Информационные окна">
						<PodarokField selectedApp={fields.appName.split('_')[0]} onValueChange={value => setFields(fields => { fields.podarok = value })} defaultValue={[]}/>
					</Form.Item>
					)
				}

				<Button type="primary" className="applyBtn" disabled={!isApplyBtnEnabled} onClick={apply} loading={isRequestInProgress}>Начислить</Button>
			</Form>
		</div>
	)
}