import { useState, useEffect, useRef } from "react"
import { Button } from "antd"
import * as _ from 'lodash-es'
import ObjectSubgroup from "./ObjectSubgroup"

const notAcceptableValues = [ 'NULL', 'null', '"null"', '"NULL"', '[[]]' ]

// Поле, представляющее массив или объект
export default function ObjectField({ data, onFieldChange, defaultValue }) {
	const isMounted = useRef(false)
	const [ objectValue, setObjectValue ] = useState(defaultValue)

	useEffect(() => {
		if (isMounted.current) {
			onFieldChange({
				name: data.name,
				value: objectValue,
				config: data
			})
		}

		isMounted.current = true
	// eslint-disable-next-line
	}, [ objectValue ])

	useEffect(() => {
		let initialFieldValue = !_.includes(notAcceptableValues, defaultValue) ? defaultValue : ''

		if (!_.isEqual(objectValue, initialFieldValue)) {
			setObjectValue(initialFieldValue)
		}
	// eslint-disable-next-line
	}, [ defaultValue ])

	// todo expeptions
	if (!_.isEmpty(objectValue)) {
		try {
			return <ObjectSubgroup
				data={data}
				objectValue={objectValue}
				onFieldChange={onFieldChange}
				/>
		}
		catch(e) {
			console.error(e)
		}
	}

	if (!data.index) {
		return <>
			<div className="noData">
				{ `${ data.name }: ` } Нет данных. Они должны быть получены в течение игры.

				{ (() => {
					if (data.type === 'array') {
						const arrayLength = _.size(data.views)
						const fillValue = new Array(arrayLength).fill(0)
			
						return (
							<Button
								type="primary"
								onClick={() => {
									onFieldChange({
										name: data.name,
										value: fillValue,
										config: data
									})
								}}
								style={{
									marginTop: 10
								}}
							>
								Заполнить данные
							</Button>
						)
					}
				})() }
			</div>
		</>
	}
}