import * as _ from 'lodash-es'
import { Select, Button, Input, Tag, Form } from "antd"
import { useImmer } from "use-immer"
import appsConfigs from '../../../configs/apps/config'
import zebomba from '../../../tools/zebomba'

const DEFAULT_TEXT = `
Пришло время подвести итоги акции в игре "[app_name]" ([app_link]) и поприветствовать счастливых обладателей 100 ОК!

Генератор случайных чисел OK.RU выбрал победителей, которые успели воспользоваться выгодным предложением и приобрели ОКи во время проведения акции.

Победителями акции стали:
`.trim()

const TARGET_APPS = [
	'FIND',
	'CAT',
	'TRIP',
	'WORDS',
	'WG',
	'VILLA',
	'ALICE',
	'SWEET',
	'HOUSE'
]

export default function LinksGenerator({ data }) {
	const [ selectedApp, setSelectedApp ] = useImmer(TARGET_APPS[0])
	const [ resultText, setResultText ] = useImmer(DEFAULT_TEXT)
	const [ usersIDs, setUsersIDs ] = useImmer([])

	document.title = data.title

	return (
		<div className="center">
			<div className="copyForm form" style={{ maWidth: 700, marginTop: -30 }}>
				<div className="title">Генератор текста для ОК</div>

				<Form.Item label={<b>Приложение</b>} colon={false} labelCol={{ span: 24 }} style={{ marginBottom: 25 }}>
					<Select
						defaultValue={selectedApp}
						onChange={value => setSelectedApp(value)}
						options={_.map(TARGET_APPS, name => {
							return {
								label: name,
								value: name
							}
						})}
					>
					</Select>
				</Form.Item>

				<Form.Item label={<b>ID профилей</b>} colon={false} labelCol={{ span: 24 }} style={{ marginBottom: 25 }}>
					<div style={{ marginTop: -10, marginBottom: 10 }}>Разделитель: запятая или пробел. Можно вставить все ID одной строкой.</div>

					<Select
						mode="tags"
						tokenSeparators={[ ', ', ',', ' ' ]}
						allowClear
						onChange={ids => { setUsersIDs(ids) }}
					>
					</Select>
				</Form.Item>

				<Form.Item label={<b>Текст</b>} colon={false} labelCol={{ span: 24 }}>
					<Input.TextArea
						defaultValue={resultText}
						autoSize
						onKeyUp={e => { setResultText(e.target.value) }}
					>
					</Input.TextArea>
				</Form.Item>

				<div className="tags" style={{ fontSize: 14, marginTop: 25 }}>
					<div style={{ marginBottom: 10 }}>В тексте можно указывать теги:</div>

					<div className="tag" style={{ marginBottom: 10 }}>
						<Tag color="geekblue">[app_name]</Tag>
						—
						наименование проекта
					</div>
					<div className="tag">
						<Tag color="geekblue">[app_link]</Tag>
						—
						ссылка на игру
					</div>
				</div>

				<Button
					type="primary"
					onClick={() => generateText(usersIDs, resultText, selectedApp)}
					style={{
						marginTop: 30,
						padding: '22px 0'
					}}
				>
					Генерировать
				</Button>
			</div>
		</div>
	)
}

function generateText( usersIDs, resultText, selectedApp ) {
	const appData = appsConfigs[selectedApp.toLowerCase()]

	if (usersIDs.length) {
		const links = usersIDs
			.map(id => `https://ok.ru/profile/${ id }`)
			.join('\n')

		resultText += `\n\n${ links }`
	}

	if (_.isEmpty(appData)) {
		return zebomba.message.error(`Нет конфига для приложения: ${ selectedApp }`)
	}
	
	const okLink = _.get(appData, 'links.web.release.ok')

	if (!okLink) {
		return zebomba.message.error(`Нет ссылки на релиз ОК для приложения: ${ selectedApp }`)
	}

	resultText = resultText
		.replace(/\[app_name\]/g, appData.title)
		.replace(/\[app_link\]/g, okLink)

	const href = `data:text/json;charset=utf-8,${ encodeURIComponent(resultText) }`

	const anchorElem = document.createElement('a')
	anchorElem.setAttribute("href", href)
	anchorElem.setAttribute("download", 'profiles_links.txt')
	anchorElem.click()
}