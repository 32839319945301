import { createElement } from "react"

import Section from "./Section"
import BattlePassSection from "./BattlePassSection"
import LeaderboardWinnersSection from "./LeaderboardWinnersSection"

export default function SectionFactory({ data, onFieldChange, formFields, setPopupData }) {
	const customSections = {
		battle_pass: BattlePassSection,
		leaderboard_data: LeaderboardWinnersSection
	}

	const sectionProps = { data, onFieldChange, formFields, setPopupData }
	const section = createElement(customSections[data.name] || Section, sectionProps)

	return section
}