import * as _ from 'lodash-es'
import { useState } from 'react'
import BotsModal from './BotsModal'
import { Button } from 'antd'

export default function BotsField({ data, onValueChange, defaultValue }) {
	const [ isModalOpen, setIsModalOpen ] = useState(false)
	const showModal = () => { setIsModalOpen(true) }
	const handleCancel = () => { setIsModalOpen(false) }

	let bots

	try {
		bots = JSON.parse(data.value)
	}
	catch {
		return <>Что-то не так с полем "bots".</>
	}

	return (
		<>
			<Button
				className="editBtn"
				style={{ fontSize: 15 }}
				onClick={showModal}
			>
				Изменить
			</Button>

			{ isModalOpen && (
				<BotsModal
					onChange={bots => {
						const newBotsData = _.reduce(bots, (result, bot) => {
							result[bot.id] = bot.rating

							return result
						}, {})

						onValueChange(JSON.stringify(newBotsData))
					}}
					bots={bots}
					handleCancel={handleCancel}
				/>
			)}
		</>
	)
}