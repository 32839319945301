export default {
	web: {
		dev: {
			vk: {
				interstitials: {
					"loadingScreen": {
						"probability": 100,
						"rules": {
							"map": 1,
							"point": 2
						}
					},
					"beforeBattle": {
						"probability": 100,
						"rules": {
							"map": 2,
							"point": 2
						}
					},
					"afterBattle": {
						"probability": 100,
						"rules": {
							"map": 1,
							"point": 5,
							"no_payment_count": 0,
							"rev": 0
						}
					},
					"afterBattleRound": {
						"probability": 100,
						"roundsProbability": {
							"1": 100,
							"2": 100
						},
						"rules": {
							"map": 1,
							"point": 8,
							"no_payment_count": 0,
							"rev": 0
						}
					}
				}
			}
		},
		release: {
			vk: {
				interstitials: {
					"loadingScreen": {
						"probability": 0,
						"rules": {
							"map": 1,
							"point": 2
						}
					},
					"beforeBattle": {
						"probability": 0,
						"rules": {
							"map": 2,
							"point": 2
						}
					},
					"afterBattle": {
						"probability": 100,
						"rules": {
							"map": 1,
							"point": 5,
							"no_payment_count": 0,
							"rev": 0
						}
					},
					"afterBattleRound": {
						"probability": 100,
						"roundsProbability": {
							"1": 100,
							"2": 100
						},
						"rules": {
							"map": 2,
							"point": 7,
							"no_payment_count": 0,
							"rev": 0
						}
					}
				}
			}
		}
	}
}