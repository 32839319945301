import Pages from "./Pages"
import { Transition } from "react-transition-group"
import Apps from "./Apps"
import { useRef, useEffect } from "react"
import { useLocation } from "react-router"

export default function NavbarPopup({ type, setPopupData }) {
	const didMount = useRef(false)

	const location = useLocation()
	
	useEffect(() => {
		if (didMount.current) {
			setPopupData(null)
		}

		didMount.current = true
	}, [ location, setPopupData ])

	let popupContent

	if (type === 'pages') {
		popupContent = <Pages/>
	}
	else if (type === 'apps') {
		popupContent = <Apps/>
	}

	return (
		<Transition in={true} timeout={0} appear={true}>
			{
				state => (
					<div id="navbarPopup" className={ state === 'entered' ? 'anim' : '' }>
						{ popupContent }
			
						<div className="closeBtn" onClick={() => setPopupData(null)}></div>
					</div>
				)
			}
		</Transition>
	)
}