import { useImmer } from 'use-immer'
import * as _ from 'lodash-es'
import FormField from '../form/FormField'
import { useAuth } from '../../provider/AuthProvider'
import zebomba from '../../tools/zebomba'
import AppConfig from '../config/AppConfig'
import { useRef, useState, createElement } from 'react'
import LoadingSpinner from '../LoadingSpinner'
import { Table, Button, Popover  } from 'antd'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'

export default function CoinsStats({ data }) {
	const historyTableData = useRef(null)
	const { user } = useAuth()
	const [ isRequestInProgress, setIsRequestInProgress ] = useState(false)

	const [ fields, updateFields ] = useImmer({
		userID: '',
		appID: '',
		fromDate: '',
		toDate: ''
	})

	document.title = data.title

	const MAX_DAYS_RANGE = 10
	const datesDiff = (new Date(fields.toDate) - new Date(fields.fromDate)) / 1e3 / 60 / 60 / 24
	const isDateRangeValid = datesDiff <= MAX_DAYS_RANGE && datesDiff >= 0
	const isDataSet = fields.userID && fields.appID && isDateRangeValid

	const onShowBtnClick = async () => {
		const setInProgress = _.delay(() => setIsRequestInProgress(true), 500)

		const requestData = _.assign({
			id: user.id,
			access_token: user.token,
			user_id: fields.userID,
			start_date: fields.fromDate,
			end_date: fields.toDate
		}, user.apps.byName[fields.appID].dataForRequest)

		const response = await fetch(`${ AppConfig.api.getCoinsHistoryByDate }?${ new URLSearchParams(requestData) }`)

		const data = await response.json()

		clearTimeout(setInProgress)

		historyTableData.current = null

		if (_.isArray(data.coins_history)) {
			const coinsHistory = _.flatten(data.coins_history)

			if (!coinsHistory.length) {
				zebomba.message.success('История пуста')
			}
			else {
				let rows = _.map(coinsHistory, item => {
					const icoProps = {
						icon: 'minus-circle',
						color: '#ccc',
						label: 'Баланс не изменился'
					}

					if (item.user_balans_posle - item.user_balans_do > 0) {
						icoProps.color = '#45b103'
						icoProps.icon = 'arrow-up-circle'
						icoProps.label = 'Баланс увеличился'
					}
					else if (item.user_balans_posle - item.user_balans_do < 0) {
						icoProps.color = '#e54545'
						icoProps.icon = 'arrow-down-circle'
						icoProps.label = 'Баланс уменьшился'
					}

					const stateIco = createElement(FeatherIcon, icoProps)

					return {
						time: item.time,
						comment: item.comment,
						balans: item.balans,
						user_balans_do: item.user_balans_do,
						user_balans_posle: item.user_balans_posle,
						state: <Popover content={icoProps.label}>{ stateIco }</Popover>
					}
				})

				historyTableData.current = rows
			}
		}
		else if (data.error_key) {
			zebomba.message.error(data.error)
		}
		else {
			zebomba.message.error('Что-то пошло не так...')
		}
		
		setIsRequestInProgress(false)

		updateFields(fields => ({ ...fields }))
	}

	let historyTable

	if (historyTableData.current) {
		const columns = [
			{
				title: 'Время операции',
				key: 'time',
				dataIndex: 'time',
				sorter: (a, b) => Date.parse(a.time) - Date.parse(b.time),
				defaultSortOrder: 'descend'
			},
			{
				title: 'Комментарий',
				key: 'comment',
				dataIndex: 'comment',
				className: 'comment'
			},
			{
				title: 'Сумма операции',
				key: 'balans',
				dataIndex: 'balans',
				align: 'center',
				sorter: (a, b) => a.balans - b.balans
			},
			{
				title: 'Баланс до',
				key: 'user_balans_do',
				dataIndex: 'user_balans_do',
				align: 'center',
				sorter: (a, b) => a.user_balans_do - b.user_balans_do
			},
			{
				title: 'Баланс после',
				key: 'user_balans_posle',
				dataIndex: 'user_balans_posle',
				align: 'center',
				sorter: (a, b) => a.user_balans_posle - b.user_balans_posle
			},
			{
				key: 'state',
				dataIndex: 'state',
				align: 'center'
			}
		]

		historyTable = <Table
			bordered
			size="small"
			dataSource={historyTableData.current}
			columns={columns}
			pagination={{ pageSize: 20 }}
		/>
	}

	return (
		<div className="formTable">
			<div className={`coinsStats form ${ historyTable ? 'wide' : '' }`}>
				<div className="title">Расход монет</div>

				<FormField
					data={{ name: 'userID', label: 'ID пользователя', type: 'text' }}
					defaultValue={fields.userID}
					onFieldChange={field => updateFields(fields => { fields.userID = field.value })} />

				<FormField
					data={{ name: 'appID', label: 'Приложение', type: 'select', options: user.apps.allNamesList, hideIndex: true, showSearch: true }}
					onFieldChange={field => { updateFields(fields => { fields.appID = field.config.options[field.value] } ) }}/>

				<div className="fieldsGroup">
					<div className="title">Период (максимум 10 дней)</div>

					<div className="group">
						<FormField
							data={{ name: 'fromDate', label: 'От', type: 'date' }}
							defaultValue={fields.fromDate}
							onFieldChange={field => updateFields(fields => { fields.fromDate = field.value })} />

						<FormField
							data={{ name: 'toDate', label: 'До', type: 'date' }}
							defaultValue={fields.toDate}
							onFieldChange={field => updateFields(fields => { fields.toDate = field.value })} />
					</div>

					<Button
						type="primary"
						className='showBtn'
						disabled={!isDataSet}
						loading={isRequestInProgress}
						onClick={onShowBtnClick}>Показать</Button>

					{ isRequestInProgress && !historyTable && <LoadingSpinner/> }
				</div>
			</div>

			{ historyTable }
		</div>
	)
}