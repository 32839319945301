import { createElement } from "react"

import TextField from "../appForm/fields/TextField"
import SelectField from "../appForm/fields/SelectField"
import NumberField from "../appForm/fields/NumberField"
import DateField from "../appForm/fields/DateField"
import TextAreaField from "../appForm/fields/TextAreaField"

export default function FormField({ data, onFieldChange, defaultValue, onInput }) {
	const fieldsClasses = {
		text: TextField,
		select: SelectField,
		number: NumberField,
		date: DateField,
		textarea: TextAreaField
	}

	const field = createElement(fieldsClasses[data.type], {
		data,
		onValueChange: value => onFieldChange({
			name: data.name,
			value,
			config: data
		}),
		defaultValue,
		onInput
	})

	return (
		<div className={ `formFieldItem field_${ data.type } ${ data.name }` }>
			{ data.label && <label className="fieldLabel">{ data.label }</label> }
			{ field }
		</div>
	)
}