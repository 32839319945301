import FeatherIcon from 'feather-icons-react'
import Files from 'react-files'
import zebomba from '../../../tools/zebomba'
import * as _ from 'lodash-es'
import { Button } from 'antd'
import { useAppData } from '../../pages/Project'

export default function ImportFields({ fields, setFields }) {
	const { setServerData } = useAppData()

	const onFilesChange = files => {
		const file = files && files[0]

		if (file) {
			const reader = new FileReader()
		
			reader.readAsText(file)
		
			reader.onload = () => {
				const notFoundFields = []
				const appData = JSON.parse(reader.result)

				for (const fieldName in appData.fields) {
					if (!_.has(fields, fieldName)) {
						notFoundFields.push(fieldName)

						delete appData.fields[fieldName]
					}
				}

				setServerData(appData.fields)

				// old variant:
				// setFields(prevFields => {
				// 	const newFields = { ...prevFields }

				// 	for (const fieldName in appData.fields) {
				// 		const fieldValue = appData.fields[fieldName]

				// 		if (_.isArray(fieldValue)) {
				// 			newFields[fieldName] = [ ...fieldValue ]
				// 		}
				// 		else if (_.isObject(fieldValue)) {
				// 			newFields[fieldName] = { ...fieldValue }
				// 		}
				// 		else {
				// 			newFields[fieldName] = fieldValue
				// 		}
				// 	}

				// 	return newFields
				// })

				let message = 'Данные успешно импортированы.'

				if (notFoundFields.length) {
					message += `\n\nНе найденные поля: ${ notFoundFields.join(', ') }`
				}

				zebomba.message.success(message)
			}
		
			reader.onerror = () => {
				zebomba.message.error('Что-то пошло не так...')

				console.error(reader.error)
			}
		}
	}

	return (
		<Files accepts={[ '.json' ]} onChange={onFilesChange}>
			<Button type="primary" className="button importBtn">
				<FeatherIcon icon="log-out" size="18"></FeatherIcon>
				Импорт данных
			</Button>
		</Files>
	)
}