import { InputNumber } from "antd"

export default function NumberField({ data, onValueChange, defaultValue }) {
	const onChange = value => {
		onValueChange(Number(value))
	}

	return <InputNumber
		disabled={!!data.readOnly}
		onChange={onChange}
		value={defaultValue}
		controls={false}
		max={Number(data.max) || Infinity}
		min={Number(data.min) || Infinity}
		/>
}