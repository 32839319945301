import FeatherIcon from 'feather-icons-react'
import { useAppData } from '../../pages/Project'
import { Button } from 'antd'

export default function ExportFields({ fields }) {
	const appData = useAppData()

	const onExportBtnClick = () => {
		const data = {
			appID: appData.config.appID,
			fields
		}

		const href = `data:text/json;charset=utf-8,${ encodeURIComponent(JSON.stringify(data, null, 4)) }`

		const anchorElem = document.createElement('a')
		anchorElem.setAttribute("href", href)
		anchorElem.setAttribute("download", `${ data.appID }.json`)
		anchorElem.click()
	}

	return (
		<Button type="primary" className="button exportBtn" onClick={onExportBtnClick}>
			<FeatherIcon icon="log-out" size="18"/>
			Экспорт данных
		</Button>
	)
}