import * as _ from 'lodash-es'
import FeatherIcon from 'feather-icons-react'
import { Popover } from 'antd';

export default function FeaturesPointsList({ app }) {
	if (app.features) {
		const featuresMap = _.map(app.features, (feature, featureName) => { feature.name = featureName; return feature }),
			sortedFeatures = _.sortBy(featuresMap, feature => feature.available_point.map * 10 + feature.available_point.point)

		const features = _.map(sortedFeatures, (feature, index) => {
			return <div key={index} className="feature">
				{ feature.pointMarker && (
					<Popover content={<div>Показывается иконка<br/>над точкой локации</div>}>
						<div className="flag">
							<FeatherIcon icon="flag" size="18"/>
						</div>
					</Popover>
				)
				}

				<div className="name">{ feature.name }</div>

				<div className="line">
					<div className="title">{ getFeatureTitle(feature.name) }</div>
					<div className="point">{ feature.available_point.map }-{ feature.available_point.point }</div>
				</div>
			</div>
		})

		return <div key={app.name} className={ `app ${ app.name }` }>
			<div className="appName">{ app.name }</div>

			<div className="features">{ features }</div>
		</div>
	}
}

const getFeatureTitle = feature => {
	switch (feature) {
		case 'battlePass':
			return 'Боевой пропуск'
		case 'mysteryReward':
			return 'Таинственная награда'
		case 'suggestHomeScreenShortcut':
			return 'Ярлык на рабочий стол'
		case 'expressSale':
			return 'Экспресс-распродажа (персональная акция)'
		case 'leftMenu':
			return 'Левое меню'
		case 'groupSubscription':
			return 'Подписка на группу'
		case 'messageSubscription':
			return 'Подписка на сообщения'
		case 'smiles':
			return 'Смайлы'
		case 'dailyBonus':
			return 'Ежедневная награда'
		case 'decorations':
			return 'Украшения'
		case 'storyEvent':
			return 'Ивент Книга историй'
		case 'rating_booster_ad':
			return 'Бустер рейтинга за рекламу'
		case 'advertising':
			return 'Реклама (кинотеатр)'
		case 'friends':
			return 'Друзья'
		case 'rating':
			return 'Рейтинг'
		case 'wheel':
			return 'Колесо фортуны'
		case 'moneybox':
			return 'Копилка'
		case 'lottery':
			return 'Лотерея'
		case 'dailyQuests':
			return 'Ежедневное задание'
		case 'frames':
			return 'Рамки'
		case 'portal':
			return 'Портал'
		case 'magicSphere':
			return 'Волшебная сфера'
		case 'presentOffer':
			return 'Большой подарок'
		case 'presentOfferPremium':
			return 'Премиум подарок'
		case 'awardsMixer':
			return 'Миксер наград'
		case 'magicFlask':
			return 'Волшебная колба'
		case 'cassette':
			return 'Шкатулки'
		case 'cataclysm':
			return 'Катаклизм'
		case 'fastPurchase':
			return 'Быстрая покупка'
		case 'specialGift':
			return 'Особый подарок новичкам'
		case 'longTimeNoVisitGift':
			return 'Особый подарок долго не играющим'
		case 'gameFriday':
			return 'Праздничная акция'
		case 'beginnerKits':
			return 'Набор новичка'
		case 'disablingAds':
			return 'Отключение рекламы'
		case 'marathonPrize':
			return 'Марафон призов'
		case 'starChest':
			return 'Звёздный сундук'
		case 'tasks':
			return 'Миссии (квесты)'
		default: {
			throw new Error('Failed to define feature title.')
		}
	}
}