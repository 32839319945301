import { useState, useEffect, useRef } from "react"
import * as _ from 'lodash-es'
import { Select as AntSelect } from "antd"

export default function SelectField({ data, onValueChange, defaultValue }) {
	const onChange = (selectedOption, value) => {
		if (!_.isNaN(Number(selectedOption))) {
			selectedOption = Number(selectedOption)
		}

		onValueChange(selectedOption)
	}

	return (
		<Select
			data={data}
			showSearch={(data.anySearch || data.showSearch)}
			anySearch={data.anySearch}
			filterOption={data.filterOption}
			options={ data.options }
			hideIndex={ data.hideIndex }
			defaultValue={ defaultValue }
			onChange={ onChange }/>
	)
}

function Select({ options, hideIndex = false, defaultValue, onChange, showSearch = false, anySearch = false, data }) {
	const [ selectedOption, setSelectedOption ] = useState(defaultValue)
	const isMounted = useRef(false)

	// При изменении значения select'а извне
	useEffect(() => {
		setSelectedOption(defaultValue)
	// eslint-disable-next-line
	}, [ defaultValue ])

	useEffect(() => {
		if (isMounted.current && !_.isUndefined(selectedOption)) {
			onChange(selectedOption, options[selectedOption])
		}

		isMounted.current = true
	// eslint-disable-next-line
	}, [ selectedOption ])

	// const selectOptions = _.map(options, (item, index) => ({ value: index, label: `${ !hideIndex ? `${ index } ` : ''}${item}` })),
	// 	defaultOption = _.find(selectOptions, item => item.value === selectedOption)?.label
	const selectOptions = _.map(options, (item, index) => <AntSelect.Option key={index} value={index}>{ `${ !hideIndex ? `${ index } ` : ''}${item}` }</AntSelect.Option>),
        defaultOption = _.find(options, (item, index) => _.isUndefined(selectedOption) && index === '' || Number(index) === selectedOption || String(index) === selectedOption),
		defaultSelectValue = !selectedOption && !_.isNumber(selectedOption) ? '' : `${ !hideIndex ? selectedOption : '' } ${ defaultOption }`,
		styles = {}

	if (data.width) {
		styles.width = Number(data.width)
	}

	return (
		<AntSelect
			style={styles}
			showSearch={showSearch}
			filterOption={(input, option) => _[anySearch ? 'includes' : 'startsWith'](option.children, input)}
			defaultValue={defaultSelectValue}
			value={defaultSelectValue.trim()}
			popupMatchSelectWidth={false}
			onChange={selectedIndex => setSelectedOption(selectedIndex)}>

			{ selectOptions }
		</AntSelect>
	)
}