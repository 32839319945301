import * as _ from 'lodash-es'
import FieldFactory from '../FieldFactory'
import RawField from '../fields/RawField'
import PreviewConfigModal from './PreviewConfig'
import { useImmer } from 'use-immer'
import { Button, Flex } from 'antd'

export default function Section({ data, onFieldChange, formFields }) {
	const [ previewConfig, setPreviewConfig ] = useImmer(false)

	const fields = _.map(data.fields, fieldName => (
		<FieldFactory
			key={ fieldName }
			data={{ name: fieldName }}
			onFieldChange={ onFieldChange }
			formFields={ formFields }
		/>
	))

	return (
		<div id={ `section_${ data.name }` } className="section">
			<div className="content">
				<Flex justify="space-between">
					<div className="header">
						<div className="top">
							<div className="titleExtended">{ data.title }</div>
							<div className="name">{ data.showName ? data.name : '' }</div>
						</div>

						{
							// Показывать кнопку только если данное поле есть в formFields
							data.showName && _.has(formFields, data.name) && (
								<RawField
									data={data}
									onValueChange={value => {
										const field = {
											name: data.name,
											value
										}

										onFieldChange(field)
									}}
									defaultValue={formFields[data.name]}
								/>
							)
						}
					</div>

					{ data.config && (
						<Button type="primary" onClick={() => setPreviewConfig(true)}>Конфиг</Button>
					)}
				</Flex>

				<div className="fields">
					{ fields }
				</div>
			</div>

			{ previewConfig && (
				<PreviewConfigModal
					isOpened={Boolean(previewConfig)}
					configName={data.config}
					sectionTitle={data.title}
					handleCancel={() => setPreviewConfig(false)}
				/>
			)}
		</div>
	)
}