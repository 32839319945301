import find from './find.js'
import sweet from './sweet.js'
import villa from './villa.js'
import words from './words.js'
import wg from './wg.js'
import alice from './alice.js'
import house from './house.js'
import pirate from './pirate.js'

export default {
	find,
	sweet,
	indi: alice,
	villa,
	words,
	wg,
	alice,
	house,
	pirate
}