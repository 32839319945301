export default {
	tasks: {
		//Идентификатор задания
		1: {
			//Тип задания
			'type': 'ad_base',
			'timeout_seconds': 10800,
			'free': {
				'rewards': {
					'lottery': {
						300: {
							'coins': 1
						},
						600: {
							'coins': 2
						},
						900: {
							'coins': 3
						},
						950: {
							'coins': 4
						},
						1000: {
							'coins': 5
						},
						1001: {
							'coins': 10
						},
						1002: {
							'coins': 25
						}
					},
				},
				//Количество просмотров необходимое для выполнения задания
				'count': 10
			},
			'pay': {
				'rewards': {
					'lottery': {
						500: {
							'coins': 1
						},
						850: {
							'coins': 2
						},
						1000: {
							'coins': 3
						},
						1001: {
							'coins': 4
						},
						1002: {
							'coins': 5
						},
						1003: {
							'coins': 10
						},
						1004: {
							'coins': 25
						}
					},
				},
				//Количество просмотров необходимое для выполнения задания
				'count': 10
			},
		},
		2: {
			'type': 'ad',
			'free': {
				'rewards': {
					'coins': 10
				},
				'count': 3
			},
			'pay': {
				'rewards': {
					'coins': 10
				},
				'count': 5
			}
		},
		3: {
			'type': 'ad',
			'free': {
				'rewards': {
					'wheel_cnt': 1
				},
				'count': 3
			},
			'pay': {
				'rewards': {
					'wheel_cnt': 1
				},
				'count': 5
			}
		},
		4: {
			'type': 'ad',
			'free': {
				'rewards': {
					'show_difference_cnt': 2
				},
				'count': 5
			}
		},
		5: {
			'type': 'ad',
			'free': {
				'rewards': {
					'sunduk_cnt': 2
				},
				'count': 5
			}
		},
		6: {
			'type': 'ad',
			'free': {
				'rewards': {
					'show_difference_cnt': 1
				},
				'count': 3
			},
			'pay': {
				'rewards': {
					'show_difference_cnt': 1
				},
				'count': 5
			}
		}
	}
}