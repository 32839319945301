import { useAuth } from "../../provider/AuthProvider"
import FormField from "../form/FormField"
import { createElement, Fragment } from "react"
import { useImmer } from "use-immer"
import * as _ from 'lodash-es'
import AppConfig from "../config/AppConfig"
import zebomba from "../../tools/zebomba"
import { useNavigate } from "react-router-dom"
import { Button } from "antd"

export default function CopyPage({ data }) {
	const navigate = useNavigate()
	const { user } = useAuth()
	const availableApps = user.apps.available
	const [ fieldsData, setFieldsData ] = useImmer({copyMode: 'id'})
	const [ isRequestInProgress, setIsRequestInProgress ] = useImmer(false)

	const copyModeOptions = [
		'id',
		'параметрам'
	]

	document.title = data.title

	const onFieldChange = field => {
		setFieldsData(prevData => {
			const newData = _.assign({}, prevData)

			if (field.config.type === 'select') {
				newData[field.name] = field.config.options[field.value]
			}
			else {
				newData[field.name] = String(field.value).trim()
			}

			return newData
		})
	}

	const tryCopy = async () => {
		const setInProgress = _.delay(() => setIsRequestInProgress(true), 300)

		const [ appName, appPlatform, appVersion, appSocial ] = fieldsData.appID.split('_')

		let url,
			options

		if (fieldsData.copyMode === 'id') {
			url = AppConfig.api.copyUser
			options = {
				method: 'POST',
				body: new URLSearchParams({
					access_token: user.token,
					id: user.id,
					app_name: appName,
					platform: appPlatform,
					version: appVersion,
					soc_name: appSocial,
					from_user_id: fieldsData.fromUserID,
					to_user_id: fieldsData.toUserID
				})
			}
		}

		else {
			url = AppConfig.api.copyUserByParams
			options = {
				method: 'POST',
				body: new URLSearchParams({
					access_token: user.token,
					id: user.id,
					app_name: appName,
					platform: appPlatform,
					version: appVersion,
					soc_name: appSocial,
					params: JSON.stringify({point: Number(fieldsData.point), map: Number(fieldsData.map)}),
					to_user_id: fieldsData.toUserID
				})
			}
		}

		const response = await fetch(url, options)

		const data = await response.json()
		
		clearTimeout(setInProgress)
		setIsRequestInProgress(false)

		if (data.success) {
			let appURL = `/apps/${ fieldsData.appID }/${ fieldsData.toUserID }`

			navigate(appURL)
		}
		else if (data.error_key) {
			zebomba.message.error(data.error)
		}
	}

	let sourceField,
		toOptions

	if (fieldsData.appID) {
		const fromUserIdFieldProps = {
			data: {
				name: 'fromUserID',
				label: 'Откуда копировать (ID пользователя)',
				type: 'text'
			},
			onFieldChange: field => { onFieldChange(field) },
			defaultValue: fieldsData.fromUserID
		}

		const fromParamsMapFieldProps = {
			data: {
				name: 'map',
				label: 'Карта',
				type: 'text'
			},
			onFieldChange: field => { onFieldChange(field) },
			defaultValue: fieldsData.map
		}

		const fromParamsPointFieldProps = {
			data: {
				name: 'point',
				label: 'Точка',
				type: 'text'
			},
			onFieldChange: field => { onFieldChange(field) },
			defaultValue: fieldsData.point
		}

		if (availableApps[fieldsData.appID]) {
			toOptions = availableApps[fieldsData.appID].ids

			if (!fieldsData.toUserID) {
				setFieldsData(fieldsData => {
					fieldsData.toUserID = toOptions[0]
				})
			}

			if (fieldsData.copyMode === 'id') {
				sourceField = createElement(FormField, fromUserIdFieldProps)
			}

			else {
				sourceField = createElement(Fragment, {},
					createElement(FormField, fromParamsMapFieldProps),
					createElement(FormField, fromParamsPointFieldProps)
				)
			}
		}
		else {
			sourceField = <div>
				Отсутствует твой ID для этого приложения
			</div>
		}
	}

	return (
		<div className="copyForm form">
			<div className="title">Копирование данных</div>

			<FormField
				data={{ name: 'appID', label: 'Приложение', type: 'select', options: user.apps.allNamesList, hideIndex: true, showSearch: true }}
				onFieldChange={onFieldChange} />

			{ toOptions && <FormField
				data={{ name: 'copyMode', label: 'Копировать по:', type: 'select', options: copyModeOptions, hideIndex: true, showSearch: false }}
				onFieldChange={onFieldChange}
				defaultValue={0}/>
			}

			{ sourceField }

			{ toOptions && <FormField
				data={{ name: 'toUserID', label: 'Куда копировать (ID пользователя)', type: 'select', options: toOptions, hideIndex: true }}
				onFieldChange={field => onFieldChange(field)}
				defaultValue={0}
				/>
			}

			<Button
				type="primary"
				className="button copyBtn"
				onClick={tryCopy}
				loading={isRequestInProgress}
				disabled={!(fieldsData.appID && 
					(fieldsData.copyMode === 'id' && fieldsData.fromUserID
					|| fieldsData.copyMode === 'параметрам' && fieldsData.map && fieldsData.point)
					&& fieldsData.toUserID)
				}>
				Копировать
			</Button>
		</div>
	)
}