import { useState } from "react"
import PodarokModal from "./PodarokModal"
import * as _ from 'lodash-es'
import { Popover, Button } from "antd"

export default function PodarokField({ data, onValueChange, defaultValue, selectedApp }) {
	const [ isModalOpen, setIsModalOpen ] = useState(false)
	const showModal = () => { setIsModalOpen(true) }
	const handleCancel = () => { setIsModalOpen(false) }

	if (!_.isArray(defaultValue) || _.some(defaultValue, item => !_.isArray(item))) {
		const msg = <div>
			Данное поле используется для разных данных, не только для инфо окон.<br/>
			Для отображения информационных окон нужен формат массива с подмассивами.
		</div>

		return (
			<div>
				<Popover content={msg}><div className="podarokFieldWarn">Неверный формат для инфо окон.</div></Popover>

				<Button type="primary" style={{ marginTop: 15 }} onClick={() => onValueChange([])}>Установить формат для инфо окон</Button>
			</div>
		)
	}

	return (
		<>
			<Button
				style={{ fontSize: 15, marginTop: 20 }}
				onClick={showModal}
			>
				Открыть редактор
			</Button>

			{ isModalOpen && <PodarokModal
				selectedApp={selectedApp}
				initialData={defaultValue}
				onChangeHandler={onValueChange}
				closeModal={handleCancel}
				isModalOpen={true}
				handleCancel={handleCancel}/> }
		</>
	)
}