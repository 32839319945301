import { notification } from 'antd'
import * as _ from 'lodash-es'

class Zebomba {
	message = new Message()
}

class Message {
	constructor() {
		this.success = this._show.bind(this, 'success')
		this.error = this._show.bind(this, 'error')
		this.info = this._show.bind(this, 'info')
		this.warning = this._show.bind(this, 'warning')
	}

	_show( messageType, ...args ) {
		const message = _.map(args, arg => _.isObject(arg) ? JSON.stringify(arg) : arg)
			.join(' ')

		notification[messageType]({
			message,
			placement: 'bottomRight',
		})
	}
}

export default new Zebomba()

// todo
// export const sendRequest = async (request, data) => {
// 	const requestData = _.assign({
// 		id: user.id,
// 		access_token: user.token
// 	}, data)

// 	const response = await fetch(`${ AppConfig.api.getCoinsHistoryByDate }?${ new URLSearchParams(requestData) }`)
// }