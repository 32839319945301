import { useAppData } from "../../pages/Project"
import * as _ from 'lodash-es'
import { Scrollbars } from 'react-custom-scrollbars-2'
import FeatherIcon from "feather-icons-react/build/FeatherIcon"
import zebomba from "../../../tools/zebomba"
import FeaturesPointsBtn from "./FeaturesPointsBtn"

export default function LeftPanel() {
	const { config: appConfig } = useAppData()

	const [ , platform, version, social ] = appConfig.appID.split('_')
	const formattedAppID = appConfig.appID
		.split('_')
		.join(' ')

	const scrollToSection = sectionName => {
		try {
			const sectionItem = document.querySelector(`#section_${ sectionName }`)
			
			if (sectionItem) {
				const sectionBCR = sectionItem.getBoundingClientRect()

				window.scrollBy(sectionBCR.x, sectionBCR.y)
			}
		}
		catch (e) {
			console.error(e)
		}
	}

	let appLink

	if (platform === 'web') {
		appLink = appConfig.links.web[version][social]
	}

	return (
		<div className="leftPanel">
			<div id="selectedAppIfno" className="mainTitle">
				<div style={{
					textTransform: 'uppercase',
					fontSize: formattedAppID.length >= 20 ? 17 : 'inherit'
				}}>{ formattedAppID }</div>

				<div className="id" onClick={() => {
					navigator.clipboard.writeText(appConfig.socialUserID)
					
					zebomba.message.success('Скопировано в буфер обмена')
				}}>Твой ID: { appConfig.socialUserID } <FeatherIcon icon={"clipboard"}/></div>
			</div>

			{ appLink && <a href={appLink} target="_blank" rel="noreferrer" className="button darkGrey openSocialBtn">Открыть в соц. сети</a> }

			{ appConfig.features && <FeaturesPointsBtn app={appConfig}/> }

			<div className="sectionsMenu asideMenu">
				<Scrollbars style={{ width: 200, height: 520 }}>

				{
					_.map(appConfig.sections, section => {
						return <div key={ section.title } className="item" onClick={() => scrollToSection(section.name)}>{ section.title }</div>
					})
				}
				</Scrollbars>
			</div>
		</div>
	)
}