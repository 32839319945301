import * as _ from 'lodash-es'
import { useParams, Link } from "react-router-dom"
import { createContext, useContext, useState, useEffect, useRef } from "react"
import zebomba from '../../tools/zebomba'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/locale/ru_RU'

import config from "../../configs/apps/config"
import worldData from '../../configs/worldData/worldData'
import common from '../../configs/apps/common.json'
import AppForm from "../appForm/AppForm"
import { Navbar } from "../navbar/Navbar"
import { useAuth } from "../../provider/AuthProvider"
import AppConfig from "../config/AppConfig"
import LoadingSpinner from "../LoadingSpinner"

const AppDataContext = createContext()

export default function Project() {
	const [ serverData, setServerData ] = useState(null)
	const snapshots = useRef([])
	const allUserData = useRef({})
	const [ isRequestInProgress, setIsRequestInProgress ] = useState(false)

	const { appID, userID: socialUserID } = useParams()

	const { user, logout } = useAuth()
	const app = user.apps.byName[appID]
	const appConfig = { ...config[app.name] }

	// Объединение с общими fields
	const commonFields = _.cloneDeep(common.fields)
	const appFields = _.cloneDeep(appConfig.fields)
	appConfig.fields = _.assign(commonFields, appFields)

	appConfig.appID = appID
	appConfig.socialUserID = null

	document.title = appID

	appConfig.socialUserID = socialUserID

	const pathWithoutName = appID
		.replace(`${ app.name }_`, '')
		.split('_')
		.join('.')

	const worldDataByKeys = _.get(worldData && worldData[app.name]?.byKeys, pathWithoutName) || {}
	const appWorldData = _.assign(worldData && worldData[app.name], worldDataByKeys)

	const saveFieldsHandler = async (fields) => {
		setIsRequestInProgress(true)

		const response = await fetch(AppConfig.api.updateUser, {
			method: 'POST',
			body: new URLSearchParams({
				access_token: user.token,
				id: user.id,
				app_name: app.name,
				user_id: appConfig.socialUserID,
				user_fields: JSON.stringify(fields),
				platform: app.platform,
				version: app.version,
				soc_name: app.social
			})
		})

		setIsRequestInProgress(false)

		const data = await response.json()

		// eslint-disable-next-line eqeqeq
		if (data.success === true) {
			zebomba.message.success('Данные успешно сохранены')
		}
		else {
			zebomba.message.error('Что-то пошло не так...')
		}
	}

	const resetAppHandler = async () => {
		setIsRequestInProgress(true)

		const response = await fetch(AppConfig.api.resetUser, {
			method: 'POST',
			body: new URLSearchParams({
				access_token: user.token,
				id: user.id,
				app_name: app.name,
				user_id: appConfig.socialUserID,
				platform: app.platform,
				version: app.version,
				soc_name: app.social
			})
		})

		setIsRequestInProgress(false)

		const data = await response.json()

		// eslint-disable-next-line eqeqeq
		if (data.success === true) {
			window.location.reload()
		}
		else if (data.error) {
			zebomba.message.error(data.error)
		}
		else {
			zebomba.message.error('Что-то пошло не так...')
		}
	}

	useEffect(() => {
		if (socialUserID) {
			setServerData(null)

			const getAppInfo = async () => {
				const params = new URLSearchParams({
					app_name: app.name,
					platform: app.platform,
					version: app.version,
					soc_name: app.social,
					access_token: user.token,
					id: user.id
				})
			
				const response = await fetch(`${ AppConfig.api.getAppInfo }?${ params }`, {
					method: 'GET'
				})
			
				const data = JSON.parse(await response.text())

				if (data.error_key && data.error_key.trim()) {
					if (data.error === 'Некорректный Access Token') {
						zebomba.message.error((
							<div>
								Некорректный Access Token.
								<div>Вероятно, был вход в нескольких местах.</div>
							</div>
						))

						logout()
					}
					else {
						zebomba.message.error(data.error)
					}
				}
				else {
					if (data.users.snapshots) {
						snapshots.current = data.users.snapshots
					}

					const allFieldsData = data.users[appConfig.socialUserID]

					allUserData.current = allFieldsData

					if (allFieldsData) {
						const serverData = {}
					
						// Получить только нужные поля - поля из конфига
						for (const fieldName in appConfig.fields) {
							const fieldConfig = appConfig.fields[fieldName]

							if (fieldConfig.isExcluded) {
								continue
							}

							if (fieldName in allFieldsData) {
								const field = serverData[fieldName] = allFieldsData[fieldName]

								if (_.isString(field) && field && !field.isExcluded) {
									try {
										serverData[fieldName] = JSON.parse(field)
									}
									catch {}
								}
							}
						}

						setServerData(serverData)
					}
					else {
						zebomba.message.error(`В ответе запроса get_app_info нет данных для ID: ${ appConfig.socialUserID } в поле users.`)
					}
				}
			}

			getAppInfo()
		}
	// eslint-disable-next-line
	}, [ appID, appConfig.socialUserID ])


	let availableIDs

	if (!socialUserID) {
		const serverData = _.get(user.apps.byKeys, appConfig.appID.replace(/_/g, '.'))
		const ids = serverData.ids.split(',')
		
		availableIDs = (
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="projectPageAvailableIDs">
							Доступные ID:<br/>
							{
							_.map(ids, id => <Link className="link" key={id} to={`/apps/${ appConfig.appID }/${ id }`}>{ id }</Link>)
							}
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<ConfigProvider locale={ruRU}>
			<AppDataContext.Provider value={{ config: appConfig, appWorldData, snapshots: snapshots.current, allUserData: allUserData.current, setServerData }}>
				<Navbar/>

				{ availableIDs }

				{
					socialUserID && (
						serverData ? <AppForm
							serverData={ serverData }
							saveFieldsHandler={ saveFieldsHandler }
							resetAppHandler={ resetAppHandler }
							isRequestInProgress={ isRequestInProgress }/> : <LoadingSpinner className="projectLoading"/>
					)
				}
			</AppDataContext.Provider>
		</ConfigProvider>
	)
}

export function useAppData() {
	return useContext(AppDataContext)
}