import { useForm } from "../AppForm"
import PopupTypes from "../../popups/PopupTypes"
import { useAppData } from "../../pages/Project"

export default function FeaturesPointsBtn({ app }) {
	const { setPopupData } = useForm()
	const { config } = useAppData()

	const popupData = {
		type: PopupTypes.featuresPoints,
		features: config.features,
		app
	}

	return (
		<div className="button darkGrey" onClick={() => { setPopupData(popupData) }}>Точки фичей</div>
	)
}