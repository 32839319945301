import { Button, Popconfirm } from "antd"
import { useState } from "react"
import DetailsModal from "./DetailsModal"

export default function Snapshot({ snapshot, onRemove, onApply }) {
	const [ isModalOpen, setIsModalOpen ] = useState(false)
	const showModal = () => { setIsModalOpen(true) }
	const handleCancel = () => { setIsModalOpen(false) }

	return (
		<div className="snapshot">
			<b className="label">{ snapshot.label }</b>

			<div className="buttons">
				<Button type="default" onClick={showModal} className="btn detailsBtn">Данные</Button>
				<Button type="primary" className="btn applyBtn" onClick={onApply}>Применить</Button>

				<Popconfirm
					description="Точно удалить?"
					onConfirm={onRemove}
					okText="Да"
					cancelText="Нет"
				>
					<Button danger type="default" className="btn removeBtn">Удалить</Button>
				</Popconfirm>
			</div>

			{ isModalOpen && <DetailsModal snapshot={snapshot} closeModal={handleCancel}/> }
		</div>
	)
}