import { useState } from "react"
import RawEditorModal from "./rawField/RawEditorModal"
import { Button } from "antd"

export default function RawField({ data, onValueChange, defaultValue }) {
	const [ isModalOpen, setIsModalOpen ] = useState(false)
	const showModal = () => { setIsModalOpen(true) }
	const handleCancel = () => { setIsModalOpen(false) }

	const fieldData = JSON.stringify(defaultValue, null, 4)

	return <>
		<Button
			className="editBtn"
			style={{ fontSize: 15 }}
			onClick={showModal}
		>
			Изменить
		</Button>

		{ isModalOpen && <RawEditorModal
			title={data.name}
			onSave={value => onValueChange(value)}
			isModalOpen={isModalOpen}
			handleCancel={handleCancel}
			object={fieldData}/> }
	</>
}