import { createElement } from "react"
import * as _ from 'lodash-es'
import { Input } from "antd"

export default function TextField({ data, onValueChange, defaultValue }) {
	const inputProps = {
		className: "input text",
		onChange: e => onValueChange(e.target.value.trim()),
		value: '',
		disabled: !!data.readOnly
	}

	if (!_.isUndefined(defaultValue)) {
		inputProps.value = String(defaultValue)
	}

	return createElement(Input, inputProps)
}